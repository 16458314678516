import { ACTIONS } from '../Actions/middleMenuActions';

const initialState = {
  tab: 0,
  element: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.CHANGE_TAB:
      return { tab: action.payload.tab, element: action.payload.element };
    default:
      return state;
  }
};
