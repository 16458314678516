const styles = theme => ({
  organizationRoot: {
    flexGrow: 1,
    width: 980,
    margin: '0 auto',
    marginBottom: '50px',
  },
  [theme.breakpoints.only('xs')]: {
    organizationRoot: {
      width: 320,
    },
    tableContainer: {
      width: 320,
    },
  },
  [theme.breakpoints.only('sm')]: {
    organizationRoot: {
      width: 704,
    },
    tableContainer: {
      width: 704,
    },
  },
  [theme.breakpoints.only('md')]: {
    organizationRoot: {
      width: 780,
    },
    tableContainer: {
      width: 780,
    },
  },
  [theme.breakpoints.only('lg')]: {
    organizationRoot: {
      width: 980,
    },
    tableContainer: {
      width: 980,
    },
  },
  tableContainer: {},
  paper: {
    padding: theme.spacing.unit * 2,
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
});

export default styles;
