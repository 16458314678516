import colors from '../../Theme/Colors';
/**
 * Custom styles applied onlty to this component. (Avoiding css conflicts)
 */
export const styles = theme => ({
  instructions: {
    '@media only screen and (min-width: 1024px)': {
      width: '60%',
    },
    '@media only screen and (min-width: 768px) and (max-width: 1023px)': {
      width: '50%',
    },
    '@media only screen and (max-width: 767px)': {
      width: '80%',
    },
    marginBottom: '2em',
    display: 'inline-block',
  },

  instructionsLong: {
    '@media only screen and (min-width: 1024px)': {
      width: '70%',
    },
    '@media only screen and (min-width: 768px) and (max-width: 1023px)': {
      width: '60%',
    },
    '@media only screen and (max-width: 767px)': {
      width: '90%',
    },
    marginBottom: '2em',
    display: 'inline-block',
  },
  textStyles: {
    fontFamily: 'GothamSSm-Medium',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: colors.greyish,
  },
  textJustification: {
    textAlign: 'center',
  },
  inputCentering: {
    '& input::placeholder': {
      padding: 0,
    },
    '& input': {
      padding: 0,
    },
  },
  buttonClassInactive: {
    marginTop: '155px',
    height: '40px',
    width: '100%',
    padding: '8px 10px',
    borderRadius: '22px',
    backgroundColor: 'transparent',
    color: colors.amethyst,
    border: `solid 2px ${colors.greyish}`,
    fontFamily: 'GothamSSm-Medium',
    fontSize: '16px',
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'center',
    display: 'block',
  },
  matchButtonClassInactive: {
    border: `solid 2px ${colors.greyish}`,
    maxWidth: '220px',
  },
  buttonClass: {
    marginTop: '155px',
    height: '40px',
    padding: '8px 10px',
    width: '100%',
    borderRadius: '22px',
    backgroundColor: 'transparent',
    color: colors.amethyst,
    border: `solid 2px ${colors.amethyst}`,
    fontFamily: 'GothamSSm-Medium',
    fontSize: '16px',
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'center',
    display: 'block',
  },
  buttonClass160Width: {
    maxWidth: '160px',
  },
  flexedButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  evaneglusWings: {
    marginTop: '7em',
    height: '54px',
    width: '86px',
  },
  searchSuperAdmin: {
    marginTop: '2em',
  },
  firstNameSpecial: {
    '& > div > input[name="firstName"]': {
      backgroundPosition: '0% 45%',
    },
  },
  datePickerBorder: {
    borderBottom: `solid 2px ${colors.evaPrimaryWhite}`,
  },
  errorMessageFormat: {
    fontFamily: 'GothamSSm-Book',
    color: colors.pastelRed,
    fontSize: '16px',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    margin: '.25em',
  },
  spanTextDisplay: {
    textTransform: 'capitalize',
    fontSize: '16px',
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'center',
  },
  progress: {
    margin: theme.spacing.unit * 2,
    textAlign: 'center',
  },
  topMargin: {
    marginTop: '40px',
    '& input::placeholder': {
      padding: '0px !important',
    },
    '& input': {
      padding: '0px !important',
    },
  },
  fstMarginTop: {
    marginTop: '20px',
    '& input::placeholder': {
      textIndent: '.9em',
      '@media only screen and (max-width: 425px)': {
        textIndent: '.9em',
      },
    },
    '& input': {
      textIndent: '.9em',
      '@media only screen and (max-width: 425px)': {
        textIndent: '.9em',
      },
    },
  },
  sndMarginTop: {
    marginTop: '17px',
  },
  thrdMarginTop: {
    marginTop: '25px',
  },
  frthMarginTop: {
    '& input::placeholder': {
      textIndent: '1em',
      '@media only screen and (max-width: 425px)': {
        textIndent: '1em',
      },
    },
    '& input': {
      textIndent: '1em',
      '@media only screen and (max-width: 425px)': {
        textIndent: '1em',
      },
    },
    marginTop: '17px',
  },
  calendarCustomStyle: {
    '& .mbsc-cal-day-date.mbsc-cal-cell-txt': {
      fontSize: '12px',
    },
    '& .mbsc-cal-month': {
      fontSize: '12px',
      lineHeight: '3.222223em',
    },
    '& .mbsc-cal-year': {
      fontSize: '12px',
      lineHeight: '3.222223em',
    },
    '& .mbsc-cal-cell-i.mbsc-cal-cell-txt': {
      fontSize: '12px',
    },
  },
  matchMargin: {
    marginTop: '20px',
  },
  textAboveResultsMargin: {
    marginTop: '40px',
  },
  buttonWidthClass: {
    width: '100%',
    maxWidth: '220px',
  },
});

const memberProfile = {
  fontSize: '16px',
  fontFamily: 'GothamSSm-Book',
  color: '#4a4a4a',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  margin: '0 auto',
  maxWidth: '100%',
};

export const button = {
  marginTop: '37px',
  fontSize: '16px',
  color: '#9a66bf',
  fontFamily: 'GothamSSm-Bold',
  background: 'none',
  border: 'none',
  padding: 0,
  cursor: 'pointer',
  outline: 'inherit',
  width: '100%',
  textTransform: 'capitalize',
};

export const MemberFoundStyle = {
  paragraph: {
    fontSize: '16px',
    color: '#a9a9a9',
    textAlign: 'center',
    fontFamily: 'GothamSSm-Medium',
    fontWeight: '500',
  },
  memberProfile,
  button,
  img: {
    borderRadius: '50%',
    width: '100px',
    height: '100px',
  },
  row: {
    textAlign: 'center',
  },
  noMatch: {
    fontSize: '16px',
    marginTop: '37px',
    textAlign: 'center',
    fontFamily: 'GothamSSm-Book',
    color: '#9a66bf',
    background: 'none',
    border: 'none',
    padding: 0,
    cursor: 'pointer',
    outline: 'inherit',
    width: '100%',
  },
};

export const checkBox = {
  root: {
    color: '#e3e3e3',
    '&$checked': {
      color: '#9a66bf',
    },
    position: 'absolute',
    marginLeft: '-7%',
    borderRadius: '5px',
  },
  checked: {},
};
