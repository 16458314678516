/* eslint-disable */
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import MainMenu from '../../Components/MainMenu';
import TopDetail from '../../Components/TopDetail';
import LoadingScreen from '../../Components/LoadingScreen';
import SettingsOrg from '../../Components/SettingsOrg';
import getParishes from '../../Actions/parishActions';
import { enableTopDrawer, selectElementFromTable } from '../../Actions/topDrawerActions';
import { getFullOrganization } from '../../Actions/organizationActions';

// TODO: remove
const fakeComponent = () => '';

class OrgContainer extends React.Component {
  state = { Component: fakeComponent, loading: true };

  componentDidMount() {

    const { organizationType } = this.props;
    this.props.enableTopDrawer(true);
    // TODO: use this end-point
    this.props.getFullOrganization(this.props.match.params.orgId)
      .then(async org => {
        this.props.selectElementFromTable(org);
        this.setState({ loading: false });
        this.renderComponent();
      })
      .catch(err => {
        console.error(err);
        // If it fails, allow the rendering of the menu items.
        this.setState({ loading: false });
      });
  }

  renderComponent = () => {
    this.setState({ Component: SettingsOrg });
  };

  render() {
    const { statusTopDrawer } = this.props;
    const { Component } = this.state;
    if (this.state.loading) {
      return <LoadingScreen/>;
    }
    return (
      <MainMenu
        value={0}
        content={
          <div>
            {statusTopDrawer && (
              <TransitionGroup>
                <CSSTransition timeout={500} classNames="cross-fade">
                  <TopDetail renderComponent={this.renderComponent}/>
                </CSSTransition>
              </TransitionGroup>
            )}
            <Component/>
          </div>
        }
      />
    );
  }
}

OrgContainer.propTypes = {
  statusTopDrawer: PropTypes.func.isRequired,
};

const mS = state => ({
  statusTopDrawer: state.topDrawerReducer.statusTopDrawer,
  // org: state.organizationReducer.organizations,
  organizationType: state.organizationReducer.organizationType,
});

const mD = {
  enableTopDrawer,
  getParishes,
  selectElementFromTable,
  getFullOrganization,
};

export default withRouter(connect(mS, mD)(OrgContainer));
