import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import getParishes from '../../Actions/parishActions';
import { enableTopDrawer } from '../../Actions/topDrawerActions';
import { openInEva } from '../../Actions/organizationActions';
import { updateOrgRoleMembers } from '../../Actions/membersActionsOrg';
import Colors from '../../Theme/Colors';
import ViewInEvangelusButton from '../ViewInEvangelusButton';
import './index.sass';

const TopDetailContainer = props => <Typography component="div">{props.children}</Typography>;
TopDetailContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

const styles = theme => ({
  root1: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    '& bigger-number': {
      fontSize: '14px',
    },
  },
  [theme.breakpoints.only('sm')]: {
    idContainer: {
      position: 'absolute',
      top: 39,
      right: 100,
    },
  },
  wrapper: {},
  tab: {
    color: theme.palette.background.paper,
  },
  tabsIndicator: {
    background: 'transparent',
  },
  tabsRoot: {
    textTransform: 'initial',
    fontWeight: theme.typography.fontWeightRegular,
    fontFamily: 'GothamSSm-Book',
    color: '#a9a9a9',

    '&:focus': {
      color: '#9a66bf',
    },
  },
  tabSelected: { color: '#9a66bf' },
  orgName: {
    fontFamily: 'GothamSSm-Medium',
    color: Colors.graytext,
    fontSize: '20px',
    margin: 0,
    paddingRight: '20px',
    lineHeight: '30px',
    '& span': {
      marginRight: 20,
    },
  },
});

class TopDetailTabs extends React.Component {
  state = {
    loading: false,
  };

  componentDidMount() {
    this.props.renderComponent();
    this.done = true;

    if (this.props.match.params.orgId) {
      this.props.getParishes(this.props.match.params.orgId);
    }
  }

  componentDidUpdate() {
    const { selectedOrg } = this.props;
    this.props.renderComponent();
    if (selectedOrg.publicId && !this.done) {
      this.done = true;
      this.props.getParishes(selectedOrg.publicId);
    }
  }

  handleClose = () => {
    this.props.enableTopDrawer(false);
    this.props.renderComponent();
    this.props.history.push('/organizations');
  };

  render() {
    const {
      classes,
      parisCounter,
      address,
      organizationType,
      currentMember,
      selectedOrg,
    } = this.props;

    const { loading } = this.state;

    const showViewInEvaButton =
      currentMember &&
      currentMember.roles &&
      currentMember.roles.indexOf('ROLE_SUPER_ADMIN') >= 0 &&
      selectedOrg &&
      selectedOrg.publicId;

    return (
      <div className={classes.root1}>
        <Grid container spacing={0} id="top-detail" className="menu-top-detail menu-icon-bar">
          <Grid item xs={12} className="detail">
            <TopDetailContainer>
              <Grid container spacing={0} className="global-detail-container">
                <Grid item xs={12} sm={10}>
                  <div className="arrow-left-icon ">
                    <button onClick={() => this.handleClose()} />
                  </div>
                  <div className={classes.orgName}>
                    <span>{address.ssoMasterName}</span>
                    {showViewInEvaButton && (
                      <ViewInEvangelusButton
                        id={this.props.selectedOrg.id}
                        orgId={this.props.selectedOrg.publicId}
                        memberId={this.props.currentMember.public_id}
                      />
                    )}
                  </div>
                  <div className="org-description-top">
                    {address.referenceAddress ||
                    address.reference_address}{' '}
                    {address.stateText || address.stateText}{' '}
                    {address.cityText || address.cityText}{' '}
                    {address.postalCode || address.postalCode}
                  </div>
                  <div className="org-count">
                    <span>{parisCounter.numberOfItems}</span>&nbsp;
                    {`Organization${parisCounter.numberOfItems !== 1 ? 's' : ''}`} Associated
                    | <span>{selectedOrg.membersCount}</span> {`Member${selectedOrg.membersCount === 1 ? '' : 's'}`}
                  </div>
                </Grid>
                <Grid item xs={12} sm={2}>
                  <div className="org-description">{organizationType.name}</div>
                </Grid>
              </Grid>
            </TopDetailContainer>
          </Grid>
        </Grid>
      </div>
    );
  }
}

TopDetailTabs.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  selectedOrg: PropTypes.instanceOf(Object).isRequired,
  getParishes: PropTypes.instanceOf(Object).isRequired,
  renderComponent: PropTypes.instanceOf(Object).isRequired,
  enableTopDrawer: PropTypes.instanceOf(Object).isRequired,
  parisCounter: PropTypes.instanceOf(Object).isRequired,
  history: PropTypes.isRequired,
  address: PropTypes.instanceOf(Object).isRequired,
  organizationType: PropTypes.instanceOf(Object).isRequired,
  currentMember: PropTypes.instanceOf(Object).isRequired,
  match: PropTypes.instanceOf(Object).isRequired,
  openInEva: PropTypes.instanceOf(Object).isRequired,
  updateOrgRoleMembers: PropTypes.instanceOf(Object).isRequired,
};

const mS = state => ({
  selectedOrg: state.organizationReducer.selectedOrg,
  parisCounter: state.parishReducer.paginator,
  address: state.topDrawerReducer.selectedElement,
  organizationType: state.organizationReducer.organizationType,
  currentMember: state.userProfileReducer.member,
});

const mD = {
  getParishes,
  enableTopDrawer,
  openInEva,
  updateOrgRoleMembers,
};

export default withRouter(withStyles(styles)(connect(mS, mD)(TopDetailTabs)));
