import { ACTIONS } from '../Actions/organizationActions';
// fromGroup = true (action from a group) and fromGroup = false (action from the compose button)
const initialState = {
  admins: [],
  bulletinSend: false,
  bulletinSendAt: {
    day: null,
    time: null,
  },
  city_text: '',
  city: '',
  country: '',
  description: '',
  discoverOrganizationsSuggestions: [],
  discoverOrganizationSuggestionValue: '',
  discoverOrganizationSuggestionValueId: '',
  isDeleteArchivedMessageActivated: false,
  isBilingualSpanish: false,
  isSyncParish: false,
  keyword: '',
  loading: false,
  linkToMyParishApp: false,
  mainPictureUrl: '',
  name: '',
  members: [],
  mpaOrganizationSuggestionId: null,
  parentOrganizationName: '',
  parentOrganizationSsoId: null,
  parentOrganizationEvaId: null,
  // organizationParent: '',
  // organizationsSuggestions: [],
  // organizationSuggestionValue: '',
  // organizationSuggestionValueId: null,
  organizationType: { name: '', public_id: '' },
  organizationTypes: ['type'],
  pictureData: '',
  phoneNumber: '',
  postalCode: '',
  liteMode: false,
  addressPublicId: '',
  secondStreetAddress: '',
  selectedOrg: {},
  shortName: '',
  tollFreeNumber: '',
  state: '',
  streetAddress: '',
  term: '',
  timeZone: '',
  timeZoneList: {},
  websiteUrl: '',
  windowParams: {
    show: false,
    message: '',
    type: '',
    titleMessage: '',
  },
  status: 'active',
  viewOrgInEVAUrl: '',
};
let mpaOrganizationSuggestionId = null;
const organizationReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.CLEAR_DISCOVER_SINGLE_SUGGESTION:
      return {
        ...state,
        discoverOrganizationSuggestionValueId: '',
        discoverOrganizationSuggestionValue: '',
      };
    case ACTIONS.CLEAR_DISCOVER_SUGGESTIONS:
      return {
        ...state,
        discoverOrganizationsSuggestions: initialState.discoverOrganizationsSuggestions,
      };
    case ACTIONS.REFRESH_ORG_KEYWORD:
      return {
        ...state,
        keyword: action.payload.keyword,
      };
    case ACTIONS.CLEAR_ORGANIZATION_FORM:
      return {
        ...initialState
      };
    case ACTIONS.CLEAR_SELECTED_ORG:
      return { ...state, selectedOrg: {} };
    case ACTIONS.ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case ACTIONS.GET:
      return {
        ...state,
        ...action.payload,
      };
    case ACTIONS.GET_ORG_TYPES:
      return {
        ...state,
        organizationTypes: action.payload,
      };
    case ACTIONS.HYDRATE_INPUTS_DISCOVER_ORGANIZATIONS: {
      const { selectedOrg } = action.payload;
      let parentOrganizationName = '';
      let parentOrganizationEvaId = null;
      let parentOrganizationSsoId = null;

      mpaOrganizationSuggestionId = selectedOrg && selectedOrg.organizationApp
        && selectedOrg.organizationApp.mpaId ?
        selectedOrg.organizationApp.mpaId : null;

      if (selectedOrg.organizationParent && selectedOrg.organizationParent.id) {
        parentOrganizationName = selectedOrg.organizationParent.name;
        parentOrganizationSsoId = selectedOrg.organizationParent.id;

        if (
          selectedOrg.organizationParent.organizationApp &&
          selectedOrg.organizationParent.organizationApp.evaId
        ) {
          parentOrganizationEvaId = selectedOrg.organizationParent.organizationApp.evaId;
        }
      }

      return {
        ...state,
        streetAddress: selectedOrg.address ? selectedOrg.address : {},
        secondStreetAddress: selectedOrg.secondAddress ? selectedOrg.secondAddress : '',
        postalCode: selectedOrg.zipCode ? selectedOrg.zipCode : '',
        state: selectedOrg.stateCode ? selectedOrg.stateCode : '',
        city: selectedOrg.city ? selectedOrg.city : '',
        country: selectedOrg.country ? selectedOrg.country : 'United States',
        discoverOrganizationSuggestionValueId: selectedOrg.ssoOrganizationId,
        discoverOrganizationSuggestionValue: selectedOrg.name ? selectedOrg.name : '',
        mpaOrganizationSuggestionId,
        parentOrganizationName,
        parentOrganizationSsoId,
        parentOrganizationEvaId,
      };
    }
    case ACTIONS.DEHYDRATE_INPUTS_DISCOVER_ORGANIZATIONS:
      return {
        ...state,
        streetAddress: initialState.streetAddress,
        secondStreetAddress: initialState.secondStreetAddress,
        postalCode: initialState.postalCode,
        state: initialState.state,
        city: initialState.city,
        country: initialState.country,
        discoverOrganizationSuggestionValueId: initialState.discoverOrganizationSuggestionValueId,
        discoverOrganizationsSuggestions: initialState.discoverOrganizationsSuggestions,
        parentOrganizationName: initialState.parentOrganizationName,
        parentOrganizationSsoId: initialState.parentOrganizationSsoId,
        parentOrganizationEvaId: initialState.parentOrganizationEvaId,
        mpaOrganizationSuggestionId: initialState.mpaOrganizationSuggestionId,
      };
    case ACTIONS.MAYBE_UPDATE_DISCOVER_ORGANIZATIONS:
      return {
        ...state,
        discoverOrganizationsSuggestions: action.payload,
      };
    // case ACTIONS.MAYBE_UPDATE_ORGANIZATIONS:
    //   return {
    //     ...state,
    //     organizationsSuggestions: action.payload,
    //   };
    case ACTIONS.SEARCH_DISCOVER_ORGANIZATIONS:
      return {
        ...state,
        discoverOrganizationsSuggestions: action.payload,
      };
    case ACTIONS.SET_DISCOVER_ORGANIZATIONS:
      return {
        ...state,
        discoverOrganizationsSuggestions: action.payload.suggestions,
      };
    case ACTIONS.CLEAR_DISCOVER_ORGANIZATIONS:
      return {
        ...state,
        discoverOrganizationsSuggestions: initialState.discoverOrganizationsSuggestions,
      };
    case ACTIONS.SEARCH_DISCOVER_UPDATE_VALUE:
      return {
        ...state,
        discoverOrganizationSuggestionValue: action.payload,
      };
    case ACTIONS.SEARCH_DISCOVER_UPDATE_VALUE_ID: {
      if (action.payload.organizationApp && action.payload.organizationApp.mpaId) {
        mpaOrganizationSuggestionId = action.payload.organizationApp.mpaId;
      }
      return {
        ...state,
        discoverOrganizationSuggestionValueId: action.payload,
        mpaOrganizationSuggestionId,
      };
    }
    // case ACTIONS.SEARCH_ORGANIZATION_UPDATE_VALUE:
    //   return {
    //     ...state,
    //     organizationSuggestionValue: action.payload,
    //   };
    // case ACTIONS.SEARCH_ORGANIZATION_UPDATE_VALUE_ID:
    //   return {
    //     ...state,
    //     organizationSuggestionValueId: action.payload,
    //   };
    // case ACTIONS.SEARCH_PARENT_ORG_SUGGESTIONS:
    //   return {
    //     ...state,
    //     organizationsSuggestions: action.payload.dioceses,
    //   };
    // case ACTIONS.SET_PARENT_ORG_SUGGESTIONS:
    //   return {
    //     ...state,
    //     organizationsSuggestions: action.payload.suggestions,
    //   };
    // case ACTIONS.CLEAR_PARENT_ORG_SUGGESTIONS:
    //   return {
    //     ...state,
    //     organizationsSuggestions: initialState.organizationsSuggestions,
    //   };
    case ACTIONS.SELECTED_ORG:
      return {
        ...state,
        ...action.payload,
      };
    case ACTIONS.STATUS_WINDOW_MESSAGE:
      return {
        ...state,
        windowParams: action.payload,
      };
    case ACTIONS.UPDATE:
      return { ...state, ...action.payload };
    case ACTIONS.UPDATE_DATA:
      return {
        ...state,
        ...action.payload,
      };
    case ACTIONS.UPDATE_TYPE:
      return {
        ...state,
        organizationType: action.payload,
      };
    case ACTIONS.UPLOAD_PICTURE:
      return {
        ...state,
        pictureData: action.payload,
      };
    case ACTIONS.UPLOAD_UPD_PICTURE:
      return {
        ...state,
        pictureData: action.payload,
      };
    case ACTIONS.UPDATE_IS_DELETE:
      return {
        ...state,
        isDeleteArchivedMessageActivated: action.payload,
      };
    case ACTIONS.UPDATE_LITE_MODE:
      return {
        ...state,
        lite: action.payload,
      };
    case ACTIONS.UPDATE_LINK_MY_PARISH_APP:
      return {
        ...state,
        selectedOrg: {
          ...state.selectedOrg,
          linkToMyParishApp: action.payload,
        },
      };
    case ACTIONS.UPDATE_ORG_CUSTOM_URL:
      return {
        ...state,
        selectedOrg: {
          ...state.selectedOrg,
          customUrl: action.payload,
        },
      };

    case ACTIONS.OPEN_IN_EVANGELUS:
      return {
        ...state,
        viewOrgInEVAUrl: action.payload.viewOrgInEVAUrl,
      };
    default:
      return state;
  }
};

export default organizationReducer;

/* eslint-disable eol-last */
