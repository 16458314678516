export const ACTIONS = {
  ENABLE: 'rightDrawer/enable',
  DISABLE: 'rightDrawer/disable',
};

export function enableRightDrawer(value) {
  return dispatch => {
    dispatch({ type: ACTIONS.ENABLE, ...value });
    if (window.innerWidth >= 1024) {
      document.querySelector('.right-drawer.drawer-standard > .drawer-md-up').style.display =
        'block';
    }
  };
}

export const disableRightDrawer = () => ({ type: ACTIONS.DISABLE });
