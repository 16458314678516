import AXIOS from '../Config/AxiosConfig';

const User = {
  getProfile(token) {
    const headers = {
      Authorization: `Bearer ${token}`,
      'Diocesan-Application': 'admin',
    };
    return AXIOS.get('/user-profile', { headers });
  },
  getMember(token) {
    return AXIOS.get('/member/profile-information', {
      headers: {
        Authorization: `Bearer ${token}`,
        'Diocesan-Application': 'admin',
      },
    });
  },
};

export default User;
