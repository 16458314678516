export default () => ({
  root: {
    flexGrow: 1,
    backgroundColor: '#fff',
    width: '100%',
    boder: 'none',
    padding: 'unset',
    marginTop: '30px',
    marginBottom: '30px',
    '& input::placeholder': {
      padding: '0 !important',
    },
  },
});
