/* eslint-disable */
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { getTimeZone } from '../../Actions/organizationActions';
import MainMenu from '../../Components/MainMenu';
import TopDetail from '../../Components/TopDetail';
import Organizations from '../../Components/Organizations';
import { enableTopDrawer } from '../../Actions/topDrawerActions';

class OrgContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = { Component: Organizations };
    this.props.enableTopDrawer(false);
  }

  componentDidMount() {
    this.renderComponent();
    this.props.getTimeZone();
  }

  renderComponent = Component => {
    let showRender = Organizations;
    this.setState({ Component: showRender });
  };

  render() {
    const { statusTopDrawer } = this.props;
    const { Component } = this.state;
    return (
      <MainMenu
        value={0}
        content={
          <div>
            {statusTopDrawer && (
              <TransitionGroup>
                <CSSTransition timeout={500} classNames="cross-fade">
                  <TopDetail renderComponent={this.renderComponent}/>
                </CSSTransition>
              </TransitionGroup>
            )}
            <Component/>
          </div>
        }
      />
    );
  }
}

OrgContainer.propTypes = {
  statusTopDrawer: PropTypes.bool.isRequired,
};

const mS = state => ({
  statusTopDrawer: state.topDrawerReducer.statusTopDrawer,
});

const mD = {
  enableTopDrawer,
  getTimeZone,
};

export default withRouter(
  connect(
    mS,
    mD,
  )(OrgContainer),
);
