import React from 'react';
// import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import InputPropType from '../../PropTypes/InputPropType';
import styles from './CustomInputStyles';

const InputStandard = React.forwardRef(
  (
    {
      name,
      type,
      placeholder,
      value,
      onChange,
      icon,
      // focused,
      maxLength,
      onBlur,
      readOnly,
      checkVisible = false,
      classes,
    },
    ref,
  ) => {
    const iconClass = icon ? `input-eva-${icon}-icon` : '';
    return (
      <div className={classes.root}>
        <div className={iconClass} />
        <input
          ref={ref}
          name={name}
          type={type}
          placeholder={placeholder}
          value={value}
          onChange={event => !readOnly && onChange(event, name)}
          onBlur={onBlur}
          className={`${classes.input} input-eva-border-bottom input-eva`}
          maxLength={maxLength}
          autoComplete="off"
          readOnly={readOnly}
        />
        <div
          className={`custom-input-sa ${!checkVisible ? 'custom-input-sa-check-not-visible' : ''}`}
        />
      </div>
    );
  },
);

InputStandard.propTypes = InputPropType;

export default withStyles(styles)(InputStandard);
