import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import SimpleTabs from './SimpleTabs';
import ActionButton from '../ActionButton';
import { getUserProfile } from '../../Actions/userActions';
import { enableRightDrawer } from '../../Actions/rightDrawerActions';
import { logout } from '../../Actions/loginActions';
import { getTimeZone, openInEva } from '../../Actions/organizationActions';
import switchIcon from '../../Theme/Icons/switch-icon.svg';
import MainMenuPropType from '../../PropTypes/MainMenuPropType';
import SideMenu from '../SideMenu';
import { checkSsoLogin } from '../../Actions/authHttpActions';
import './index.sass';
import images from '../../Theme/Images';
import MainLoading from '../../Components/MainLoading';
import { mainStatusLoading } from '../../Actions/loadingMainActions';
import { activateCustomTitle } from '../../Actions/headerBarActions';
import AdminEvaSwitchButton from './AdminEvaSwitchButton';
import { HeaderBar, DefaulTitlebar } from '../HeaderBar';

const styles = {
  actionButtons: {
    top: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    '&>.switch-admin': {
      marginTop: '0',
    },
  },
};

/* eslint-disable */
class EvaAppBar extends Component {
  state = {
    selectedOrganization: '',
    isChecked: false,
    visibility: '',
    hello: 'hello',
    showMenu: false,
    switchLoading: false,
  };

  componentWillMount() {
    this.props.getUserProfile();
    this.props.getTimeZone();
    this.props.activateCustomTitle(
      DefaulTitlebar
    );
    window.addEventListener('message', this.handleMenuClose);
    this.props.mainStatusLoading(false);
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.handleMenuClose);
  }

  handleMenuClose = event => {
    const { data } = event;

    if (data && data.close) {
      this.setState({ showMenu: false });
    }
  };
  handleMenuToggle = event => {
    this.setState({ showMenu: event.target.checked });
  };

  handleGoToAdminAction = async event => {
    event.preventDefault();
    this.setState({ switchLoading: true });
    this.props.openInEva(this.props.selectedOrg);
    this.setState({ switchLoading: false });
  };

  renderAdminApp = () => {
    const {
      userProfile: {
        member: { profile_picture_url: profilePicture, first_name: firstName, last_name: lastName },
      },
      classes,
      location,
    } = this.props;

    const isVisibleAdminEvaSwitchButton = this.props.userProfile
      && this.props.userProfile.member
      && this.props.userProfile.member.roles
      && this.props.userProfile.member.roles.indexOf('ROLE_SUPER_ADMIN') >= 0;

    return (
      <div className="eva-app-bar">
        <div className="logo-container" style={{ display: 'none' }}>
          <input
            type="checkbox"
            id="drawer-toggle"
            name="drawer-toggle"
            checked={this.state.showMenu}
            onChange={this.handleMenuToggle}
          />
          <label htmlFor="drawer-toggle" id="drawer-toggle-label" />
          <header className="header-evangelus">EVANGELUS</header>
          <div className={`action-buttons-container-header ${classes.actionButtons}`}>
            <p className="logo-admin-mobile">ADMIN</p>
            {isVisibleAdminEvaSwitchButton ? <AdminEvaSwitchButton
              handleAction={this.handleGoToAdminAction}
              icon={switchIcon}
              isLoading={this.state.switchLoading}
            /> : <div>&nbsp;</div>}
            <img
              src={profilePicture ? profilePicture : images.User}
              alt={`${firstName} ${lastName}`}
              className="admin-photo"
            />
          </div>
        </div>
        <HeaderBar
          handleMenuToggle={this.handleMenuToggle}
          SwitchButton={isVisibleAdminEvaSwitchButton ?
            <AdminEvaSwitchButton
              handleAction={this.handleGoToAdminAction}
              icon={switchIcon}
              isLoading={this.state.switchLoading}
            /> : undefined}
          profilePicture={profilePicture}
          firstName={firstName}
          lastName={lastName}
          images={images}
          showMenu={this.state.showMenu}
        />
        <div className="tabs-wrapper">
          <SimpleTabs value={this.props.value} content={this.props.content} />
        </div>
        {!!location
          && location.pathname !== '/super-admins' &&
          <ActionButton />
        }
        <SideMenu
          active={this.state.showMenu}
          authToken={localStorage.getItem('sso_token')}
          env="admin"
        />
      </div>
    );
  };

  render() {
    const { isLoading } = this.props;
    return isLoading ? <MainLoading /> : this.renderAdminApp();
  }
}

EvaAppBar.propTypes = MainMenuPropType;

const mS = state => ({
  userProfile: state.userProfileReducer,
  selectedOrg: state.organizationReducer.selectedOrg,
  isLoading: state.mainLoadingReducer.isLoading,
  roles: state.loginReducer.roles,
});

const mD = {
  getUserProfile,
  logout,
  enableRightDrawer,
  getTimeZone,
  checkSsoLogin,
  mainStatusLoading,
  openInEva,
  activateCustomTitle,
};

export default withRouter(withStyles(styles)(connect(
  mS,
  mD,
)(EvaAppBar)));
/* eslint-enable */
