import React from 'react';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import EvaButton from '../EvaButton';
import evangelusLogo from '../../Theme/Icons/evangelusLogo.svg';
import { registerButton, promotionText, registerText, button } from './style';

const AdminPromotion = ({ history }) => (
  <Grid container justify="center" direction="column" alignItems="center">
    <Grid item xs={6} md={2} style={{ marginTop: '110px' }}>
      <img src={evangelusLogo} alt="Evangelus Logo" />
    </Grid>
    <Grid item xs={8} md={4} style={{ marginTop: '60px' }}>
      <p style={promotionText}>
        You have been promoted to <br /> Org admin of:
      </p>
    </Grid>
    <Grid item xs={2} md={2} style={{ marginTop: '30px' }}>
      <img src="" alt="" />
      {/* <p>{props.orgName}</p> */}
    </Grid>
    <Grid item xs={8} md={4} style={{ marginTop: '30px' }}>
      <p style={registerText}>
        If you have an Envangelus account,login below.
        <br />
        Otherwise please register for access.
      </p>
    </Grid>
    <Grid item xs={10} md={4} style={{ marginTop: '60px' }}>
      <EvaButton onClick={() => history.push('/login')}>
        <span style={button}>Login</span>
      </EvaButton>
    </Grid>
    <Grid item xs={2} style={{ marginTop: '20px' }}>
      <button onClick={() => console.log('Handle Click')} style={registerButton}>
        Register
      </button>
    </Grid>
  </Grid>
);

AdminPromotion.propTypes = {
  history: PropTypes.isRequired,
};

export default AdminPromotion;
