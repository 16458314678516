import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import { debounce } from 'lodash';
import CircularProgress from '@material-ui/core/CircularProgress';
import purple from '@material-ui/core/colors/purple';
import Button from '@material-ui/core/Button';
import { ENV } from '../../Config/env';
import searchIcon from '../../Theme/Icons/search.png';
import errorIcon from '../../Theme/Icons/error.svg';
import checkmarkIcon from '../../Theme/Icons/check-green.svg';
import snackBarStatus from '../../Actions/snackbarActions';
import SimpleSelect from '../SimpleSelect';
import Input from '../Input';
import ButtonStandard from '../Button';
import OrgInviteAdmin from '../../Containers/OrgInviteAdmin';
import OrgAdminList from './OrgAdminList';
import MobiscrollSelect from '../MobiscrollSelect';
import SearchOrganization from './SearchOrganization';
// import SearchParentOrganization from './SearchParentOrganization';
import addPictureOrgIcon from '../../Theme/Icons/add-picture-org.svg';
import colors from '../../Theme/Colors';
import SettingsOrgPropType from '../../PropTypes/SettingsOrgPropType';
import { enableRightDrawer } from '../../Actions/rightDrawerActions';
import {
  clearOrganizationForm,
  clearSelectedOrganization,
  getOrganizationTypes,
  linkOrgToMyParishAppOnServer,
  linkOrgToCaminoOnServer,
  resendBulletin,
  statusWindowsMessage,
  updateData,
  updateIsDeleteArchived,
  updateLiteMode,
  updateOrganization,
  updateSuspendedUntil,
  updateType,
  verifyKeyword,
  verifyKeywordForOrg,
} from '../../Actions/organizationActions';
import { clearSeleted, getOrgAdmins, updateMembersList, updateOrgRoleMembers, } from '../../Actions/membersActionsOrg';
import GenericMessage from '../GenericMessage';
import greyArrow from '../../Theme/Icons/dropdown-arrow-grey.svg';
import './index.sass';
import Helpers from '../../Helpers';
import { activateCustomTitle } from '../../Actions/headerBarActions';
import { OrganizationBar } from '../HeaderBar';
import EditPictureOrg from '../EditPictureOrg';

const styles = theme => ({
  colorBar: {},
  colorChecked: {},
  keyword: {
    display: 'flex',
    height: '48px',
    width: '100%',
    borderBottom: '2px solid #f3f3f3',
    alignItems: 'center',
    background: 'transparent !important',
    '&.error': {
      borderColor: '#ff7284',
    },
    '&>input': {
      flexGrow: '1',
      height: '100%',
      border: 'none',
      background: 'transparent',
      fontFamily: 'GothamSSm-Medium',
      fontSize: '16px',
      color: '#4a4a4a',
      outline: 'none',
      textAlign: 'none',
      padding: '0',
      margin: '0',
    },
    '&>.keyword-icon': {
      height: '20px',
      width: '20px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      '&>img': {
        heigth: '100%',
      },
    },
  },
  iOSSwitchBase: {
    '&$iOSChecked': {
      color: theme.palette.common.white,
      '& + $iOSBar': {
        backgroundColor: '#9a66bf',
      },
    },
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.sharp,
    }),
  },
  iOSChecked: {
    transform: 'translateX(19px)',
    '& + $iOSBar': {
      opacity: 1,
      border: 'none',
    },
  },
  iOSBar: {
    borderRadius: 20,
    width: 52,
    height: 32,
    marginTop: -15.5,
    marginLeft: -24,
    backgroundColor: '#a9a9a9',
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  iOSIcon: {
    width: 26,
    height: 26,
    marginTop: 0,
    marginLeft: 0,
    boxShadow: 'none',
    color: 'white',
  },
  iOSIconChecked: {
    boxShadow: '#000',
    backgroundColor: '#fff',
  },
  searchOrganization: {
    '&>.custom-select-container': {
      borderColor: colors.whiteTwo,
      '&>.right-icon>img': {
        height: '42%',
      },
    },
  },
  loaderMessage: {
    fontFamily: 'GothamSSm-Medium',
    fontSize: '16px',
    color: '#4a4a4a',
    outline: 'none',
    textAlign: 'center',
  },
  mobiscrollSelect: {
    '& .custom-select-container': {
      borderColor: colors.whiteTwo,
    },
    '& .mbsc-custom-select-input': {
      height: '45px',
    },
    '& .right-icon': {
      height: '45px',
      '&>img': {
        height: '16%',
      },
    },
  },
  inputPhoneSetLimit: {
    '@media only screen and (min-width: 320px) and (max-width: 414px)': {
      '& .react-phone-number-input': {
        width: '100%',
        marginLeft: '0px',
      },
    },
  },
});
const CustomInput = ({ onClick, value, autocomplete, placeholderText, disabled }) => (
  <input
    type="text"
    placeholder={placeholderText}
    disabled={disabled}
    autoComplete={autocomplete}
    className="custom-input"
    onClick={onClick}
    value={value}
  />
);

CustomInput.propTypes = {
  onClick: PropTypes.isRequired,
  value: PropTypes.isRequired,
};

class SettingsOrg extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hiddenPlaceholder: '',
      keywordStatus: 'none',
      suspendedUntil: moment(),
      isDeleteArchived: false,
      isAutoArchived: false,
      daysToDeleteArchivedMessages: 0,
      daysToArchiveMessages: 0,
      comment: null,
      savingChanges: false,
      isMemberPreferenceAvailable: false,
      list:
        localStorage.getItem('timeZoneList') !== null ? localStorage.getItem('timeZoneList') : [],
    };
    this.customUrlInputRef = React.createRef();
  }

  componentWillMount() {
    const { selectedOrg } = this.props;
    this.props.clearSeleted();
    const id = selectedOrg.publicId ? selectedOrg.publicId : selectedOrg.public_id;
    if (id !== undefined) {
      this.props.getOrgAdmins(id);
    }
    this.setState({
      isDeleteArchived: this.props.deleteArchived,
      isAutoArchived: this.props.autoArchived,
      daysToDeleteArchivedMessages: this.props.daysToDeleteArchivedMessages,
      daysToArchiveMessages: this.props.daysToArchiveMessages,
      comment: this.props.comment,
    });
    this.props.activateCustomTitle(
      OrganizationBar,
    );
  }

  componentDidMount() {
    this.props.getOrganizationTypes();
  }

  componentWillUnmount() {
    this.props.clearSelectedOrganization();
    this.props.clearOrganizationForm();
  }

  parseToValue = organizationTypes =>
    organizationTypes.map(organizationType => ({
      value: organizationType.public_id,
      label: organizationType.name,
    }));
  handleSelectChange = e => {
    const { value } = e.target;
    const selectedType = this.props.organizationTypes.find(x => x.public_id === e.target.value);
    this.props.updateType({ name: selectedType.name, public_id: value });
  };

  handleChangeCustomTime = (event, inst) => this.props.updateData({ timeZone: inst.getVal() });

  handleStatusChange = (event, inst) => this.props.updateData({ status: inst.getVal() });

  handlePicturePickerClose = () => {
    this.props.enableRightDrawer({
      payload: {
        close: false,
        enable: false,
        element: <div />,
        title: '',
      },
    });
  }

  addPictureOrg = e => {
    e.preventDefault();
    this.props.enableRightDrawer({
      payload: {
        close: true,
        enable: true,
        element: <EditPictureOrg onClose={() => this.handlePicturePickerClose()} />,
        title: 'Select a profile picture',
      },
    });
  };
  // TODO: BASE
  addAdminOrg = e => {
    e.preventDefault();
    this.props.clearSeleted();
    this.props.enableRightDrawer({
      payload: {
        enable: true,
        close: true,
        element: <OrgInviteAdmin />,
        title: 'Invite an Org Admin',
      },
    });
  };

  validateKeyword = keyword => {
    // A-Z, a-z, 0-9 and hypens (-)
    // max length 10 min length 3
    const validPattern = /^([a-z]|[0-9]|-){3,10}$/gi;
    const invalidHyphenPattern = /-{2,}/g;
    const invalidHyphenEndingPattern = /.*-$/g;
    const returnValue = { pass: false, reason: '' };
    if (!keyword) {
      returnValue.reason = 'Organization keyword is required';
    } else if (keyword.length < 3) {
      returnValue.reason = 'Organization keyword must be at least 3 caracters long';
    } else if (keyword.length > 10) {
      returnValue.reason = 'Organization keyword must not be more than 10 characters long';
    } else if (!validPattern.test(keyword)) {
      returnValue.reason = 'Organization keyword can only use numbers, letters and hypens';
    } else if (invalidHyphenPattern.test(keyword)) {
      returnValue.reason = 'Organization keyword must not have two or more hyphens together';
    } else if (invalidHyphenEndingPattern.test(keyword)) {
      returnValue.reason = 'Organization keyword must not end with a hyphen';
    } else {
      returnValue.pass = true;
      returnValue.reason = 'All checks passed';
    }
    return returnValue;
  };

  handleKeywordChange = e => {
    const keyword = e.target.value;

    this.setState({ keywordStatus: 'loading' });
    this.handleChange(e, 'keyword');
    this.verifyKeywordAvailability(keyword);
  };

  verifyKeywordAvailability = debounce(keyword => {
    const validation = this.validateKeyword(keyword);
    if (!validation.pass) {
      this.setState({ keywordStatus: 'error' });
      this.props.snackBarStatus({
        payload: {
          enable: true,
          title: validation.reason,
          type: 'error',
        },
      });
    } else {
      this.props
        .verifyKeywordForOrg(keyword, this.props.selectedOrg.publicId)
        // this is because how the endpoint work
        .then(res => {
          if (res.response && res.response.status === 400) {
            this.setState({ keywordStatus: 'error' });
          } else {
            this.setState({ keywordStatus: 'success' });
          }
        });
    }
  }, 1500);

  selectKeywordIcon = () => {
    switch (this.state.keywordStatus) {
      case 'success':
        return checkmarkIcon;
      case 'error':
        return errorIcon;
      default:
        return searchIcon;
    }
  };

  copyUrl = () => {
    Helpers.copyToClipboard(this.customUrlInputRef.current);
  };

  handleChange = (e, attr) => {
    const payload = {};
    payload[`${attr}`] = e.target.value;
    if (e.target.name === 'keyword') {
      payload[`${attr}`] = e.target.value
        ? e.target.value.toString().toLowerCase()
        : e.target.value;
    }
    this.props.updateData(payload);
  };

  handleChangeDate = date => {
    this.setState({
      suspendedUntil: date,
    });
    const payload = {
      suspended_until: date.format('YYYY-MM-DD'),
    };
    this.props.updateSuspendedUntil({ payload });
  };

  // handleAutoDeleteArchiveMessageSend = event => {
  //   this.setState({ isDeleteArchived: !this.state.isDeleteArchived });
  //   this.props.updateIsDeleteArchived(event.target.checked);
  // };

  handleLiteModeChange = event => this.props.updateLiteMode(event.target.checked);

  handleLinkMyParishAppChange = event => {
    const { selectedOrg } = this.props;
    this.props.updateData({ linkToMyParishApp: event.target.checked });
    this.props.linkOrgToMyParishAppOnServer({
      orgPublicId: selectedOrg.publicId,
      linkToMyParishApp: event.target.checked,
    });
  };
  handleResendBulletin = () => {
    const { selectedOrg } = this.props;
    this.props.resendBulletin(selectedOrg.publicId)
  }

  handleLinkCaminoChange = event => {
    const { selectedOrg } = this.props;
    this.props.updateData({ linkToCamino: event.target.checked });
    if (event.target.checked) {
      this.props.updateData({ disableWelcomeEmail: true, disableWelcomeText: true });
    }
    this.props.linkOrgToCaminoOnServer({
      orgPublicId: selectedOrg.publicId,
      linkToCamino: event.target.checked,
    });
  };

  handleMemberPreferenceAvailableChange = (event) => {
    console.log({ isMemberPreferenceAvailable: event.target.checked })
    this.props.updateData({ isMemberPreferenceAvailable: event.target.checked });
  }

  handleDisableWelcomeEmails = event => this.props.updateData({ disableWelcomeEmail: event.target.checked });

  handleDisableWelcomeText = event => this.props.updateData({ disableWelcomeText: event.target.checked });

  handleBulletinSendChange = event => {
    let data = { bulletinSend: event.target.checked };
    if (!event.target.checked) {
      data = {
        ...data,
        bulletinSendAt: {
          day: null,
          time: null,
        },
      }
    }
    this.props.updateData(data);
  }

  handleBilingualSpanishChange = (event) => this.props.updateData({ isBilingualSpanish: event.target.checked });

  handleIsSyncParishChange = (event) => this.props.updateData({ isSyncParish: event.target.checked });

  handleBulletinSendDayChange = data => {
    this.props.updateData({
      bulletinSendAt: {
        ...this.props.orgForm.bulletinSendAt,
        day: data.valueText.toLowerCase(), // TODO: fix issue with text instead of value in event
      }
    });
  }

  handleBulletinSendTimeChange = time => {
    this.props.updateData({
      bulletinSendAt: {
        ...this.props.orgForm.bulletinSendAt,
        time: time.format('HH:mm')
      }
    });
  }

  validateWebSiteUrl = url => {
    if (url) {
      const websiteUrlLower = url.toLowerCase();
      if (
        websiteUrlLower.substring(0, 7) === 'http://' ||
        websiteUrlLower.substring(0, 8) === 'https://'
      ) {
        return true;
      }
    }
    return false;
  };

  handleSubmit = e => {
    const {
      selectedOrg,
      orgForm,
      pictureData,
      discoverOrganizationSuggestionValue,
      discoverOrganizationSuggestionValueId,
    } = this.props;
    const {
      name,
      shortName,
      organizationType,
      websiteUrl,
      keyword,
      tollFreeNumber,
      timeZone,
      lite,
    } = orgForm;
    e.preventDefault();
    const validateWebSiteUrl = this.validateWebSiteUrl(websiteUrl);
    const keywordValidation = this.validateKeyword(keyword);

    const dataToUpdateOrg = {
      ...orgForm,
      isDeleteArchivedMessageActivated: this.state.isDeleteArchived,
      isAutoArchiveMessage: this.state.isAutoArchived,
      daysToDeleteArchivedMessages: this.state.daysToDeleteArchivedMessages,
      daysToArchiveMessages: this.state.daysToArchiveMessages,
      comment: this.state.comment,
      mainPictureUrl: pictureData.img,
    }

    if (!organizationType) {
      this.props.snackBarStatus({
        payload: {
          enable: true,
          title: 'Organization Type is Required',
          type: 'error',
        },
      });
    } else if (websiteUrl && websiteUrl !== '' && !validateWebSiteUrl) {
      this.props.snackBarStatus({
        payload: {
          enable: true,
          title: 'The website url must be http:// or https://',
          type: 'error',
        },
      });
    } else if (
      !discoverOrganizationSuggestionValueId ||
      discoverOrganizationSuggestionValueId === ''
    ) {
      this.props.snackBarStatus({
        payload: {
          enable: true,
          title: 'Organization is Required',
          type: 'error',
        },
      });
    } else if (!discoverOrganizationSuggestionValue || discoverOrganizationSuggestionValue === '') {
      this.props.snackBarStatus({
        payload: {
          enable: true,
          title: 'Parish name is required',
          type: 'error',
        },
      });
    } else if (!shortName) {
      this.props.snackBarStatus({
        payload: {
          enable: true,
          title: 'You must provide an organization short name',
          type: 'error',
        },
      });
    } else if (!name) {
      this.props.snackBarStatus({
        payload: {
          enable: true,
          title: 'You must provide an organization name',
          type: 'error',
        },
      });
    } else if (!keywordValidation.pass) {
      this.props.snackBarStatus({
        payload: {
          enable: true,
          title: keywordValidation.reason,
          type: 'error',
        },
      });
    } else if (!timeZone) {
      this.props.snackBarStatus({
        payload: {
          enable: true,
          title: 'Provide a timezone for the org.',
          type: 'error',
        },
      });
    } else if (!tollFreeNumber && !lite) {
      this.props.snackBarStatus({
        payload: {
          enable: true,
          title: 'Send/Receive SMS is required.',
          type: 'error',
        },
      });
    } else {
      const { mainPictureUrl } = orgForm;
      orgForm.mainPictureUrl = pictureData ? pictureData.img : null;
      this.setState({
        savingChanges: true,
      });

      this.props
        .updateOrganization(dataToUpdateOrg, selectedOrg.publicId)
        .then(res => {
          if (res.status === 200) {
            this.props.snackBarStatus({
              payload: {
                enable: true,
                title: 'Success: Organization Updated',
                type: 'success',
              },
            });
            orgForm.mainPictureUrl = pictureData ? pictureData.img : mainPictureUrl;
          } else {
            const {
              code,
              message,
            } = res;
            if (code === 404 || code === 400 || code === 403 || code === 401) {
              this.props.snackBarStatus({
                payload: {
                  enable: true,
                  title: message,
                  type: 'error',
                },
              });
            } else {
              this.props.snackBarStatus({
                payload: {
                  enable: true,
                  title: 'Server responded with an error.',
                  type: 'error',
                },
              });
            }
          }
        })
        .catch(() => {
          // console.log('err ', err.response);
        })
        .finally(() =>
          this.setState({
            savingChanges: false,
          }),
        );
    }
  };

  close = () => {
    this.props.statusWindowsMessage({ show: false });
    // this.props.clearValuesMessage();
  };

  checkPermisions = () => {
    const { roles } = this.props;
    return roles.includes('ROLE_SUPER_ADMIN');
  };

  handleAllClick = () => {
  };

  renderProgress = () => {
    const { classes } = this.props;
    return (
      <Grid
        container
        alignItems="center"
        className="settings-org"
        id="settings-org"
        justify="center"
      >
        <Grid item xs={8}>
          <p className={classes.loaderMessage}>
            Updating organization, this process may take a few seconds.
          </p>
        </Grid>
        <Grid item xs={5} className="void-box" />
        <Grid item xs={2} style={{ textAlign: 'center' }}>
          <CircularProgress
            className={classes.progress}
            style={{ color: purple[500] }}
            thickness={7}
          />
        </Grid>
        <Grid item xs={5} className="void-box" />
      </Grid>
    );
  };

  renderTimeZoneList = () => {
    const { list } = this.state;

    return JSON.parse(list).map(zone => (
      <option value={zone.name}>{`(${zone.GMT}) - ${zone.name}`}</option>
    ));
  };

  render() {
    const {
      hiddenPlaceholder,
      suspendedUntil,
      isDeleteArchived,
      isAutoArchived,
      daysToDeleteArchivedMessages,
      daysToArchiveMessages,
      list,
      savingChanges,
      comment,
    } = this.state;
    const {
      organizationTypes,
      organizationType,
      classes,
      orgForm,
      windowParams,
      pictureData,
      roles,
      lite,
      disableWelcomeText,
      disableWelcomeEmail,
    } = this.props;

    if (savingChanges) {
      return this.renderProgress();
    }

    const daysOfTheWeek = [
      { text: 'Monday', value: 'monday' },
      { text: 'Tuesday', value: 'tuesday' },
      { text: 'Wednesday', value: 'wednesday' },
      { text: 'Thursday', value: 'thursday' },
      { text: 'Friday', value: 'friday' },
      { text: 'Saturday', value: 'saturday' },
      { text: 'Sunday', value: 'sunday' }
    ];

    const customURL = orgForm.keyword ? `${ENV.SHORTENER_URL}${orgForm.keyword}` : '';
    const bulletinSendAtTime = orgForm.bulletinSendAt.time ? moment(orgForm.bulletinSendAt.time, 'hh:mm') : null

    return (
      <section className="settings-org" id="settings-org">
        <Grid container spacing={0}>
          {windowParams.show && (
            <div className="message-container">
              <GenericMessage
                hideMessage={this.close}
                type={windowParams.type}
                title={windowParams.titleMessage}
                message={windowParams.message}
              />
            </div>
          )}
          <Grid item xs={12}>
            <h3 className="title-org">Settings</h3>
          </Grid>
          <Grid item xs={12}>
            <div className="profile-image">
              <button className="button-photo-edit" onClick={this.addPictureOrg}>
                {pictureData || (orgForm && orgForm.selectedOrg && orgForm.selectedOrg.mainPictureUrl !== '' && orgForm.selectedOrg.mainPictureUrl !== null && orgForm.selectedOrg.mainPictureUrl !== undefined) ? (
                    <div className="photo-edit-profile-member">
                      <img src={pictureData.img || orgForm.selectedOrg.mainPictureUrl} alt="profile" />
                    </div>
                  ) :
                  (
                    <div className="photo-edit-profile-member">
                      <img
                        src={orgForm.mainPictureUrl ? orgForm.mainPictureUrl : addPictureOrgIcon}
                        alt="..."
                      />
                    </div>
                  )}
                <div className="input-edit-member-name">
                  <p className="text-member-name">
                    Click or tap <br />
                    to add a photo
                  </p>
                </div>
              </button>
            </div>
          </Grid>
          <Grid item xs={12} className="search-org-input">
            <InputLabel
              className={`placeholder-select ${
                organizationTypes === '' && hiddenPlaceholder !== '' ? hiddenPlaceholder : ''
              }`}
            >
              {organizationType ? organizationType.name : 'Org Type'}
            </InputLabel>
            <SimpleSelect
              value={organizationType}
              options={this.parseToValue(organizationTypes)}
              handleChange={this.handleSelectChange}
              name="Type"
              inputProps={{
                name: 'organizationType',
                id: 'type-org',
              }}
              className="select select-desk-org-creation"
            />
          </Grid>
          <Grid container spacing={0}>
            <Grid item xs={12} className={classes.searchOrganization}>
              <SearchOrganization />
            </Grid>
            <Grid item xs={12}>
              <Input
                type="text"
                name="shortName"
                placeholder="Short name"
                value={orgForm.shortName}
                maxLength={20}
                className="normal-input-create-org"
                onChange={this.handleChange}
                autoComplete="off"
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Input
              type="text"
              name="name"
              placeholder="Name"
              value={orgForm.name}
              className="normal-input-create-org"
              onChange={this.handleChange}
            />
          </Grid>
          <Grid item xs={12} md={6} className="input-search-org two-input-settings">
            <div className={`${classes.keyword} ${this.state.keywordStatus}`}>
              <input
                type="text"
                name="keyword"
                maxLength={10}
                placeholder="Keyword"
                value={orgForm.keyword}
                onChange={this.handleKeywordChange}
                readOnly={!roles.includes('ROLE_SUPER_ADMIN')}
              />
              <div className="keyword-icon">
                <img src={this.selectKeywordIcon()} alt={this.state.keywordStatus} />
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={6} className="right-org-settings short-url-input two-input-settings">
            <Grid container spacing={0} alignItems="center">
              <Grid xs={9} className="url-content">
                {this.state.keywordStatus === 'loading' ? (
                    <div style={{ paddingTop: '15px', textAlign: 'center' }}>
                      <CircularProgress style={{ color: purple[500], width: 30, height: 30 }} />
                    </div>
                  ) :
                  (
                    <Input
                      ref={this.customUrlInputRef}
                      name="short_url"
                      type="text"
                      value={customURL}
                      readOnly
                    />
                  )}
              </Grid>
              <Grid xs={3}>
                <Button className="title-url" onClick={this.copyUrl}>
                  Copy URL
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Input
              readOnly
              type="text"
              name="streetAddress"
              placeholder="Address 1"
              value={orgForm.streetAddress}
              className="normal-input-create-org"
              onChange={this.handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              readOnly
              type="text"
              name="secondStreetAddress"
              placeholder="Address 2"
              value={orgForm.secondStreetAddress}
              className="normal-input-create-org"
              onChange={this.handleChange}
            />
          </Grid>
          <Grid item xs={12} md={6} className="two-input-settings">
            <Input
              readOnly
              type="text"
              name="city"
              placeholder="City"
              value={orgForm.city}
              className="normal-input-create-org"
              onChange={this.handleChange}
            />
          </Grid>
          <Grid item xs={12} md={6} className="right-org-settings two-input-settings">
            <Input
              readOnly
              type="text"
              name="state"
              placeholder="State"
              value={orgForm.state}
              className="normal-input-create-org"
              onChange={this.handleChange}
            />
          </Grid>
          <Grid item xs={12} md={6} className="two-input-settings">
            <Input
              readOnly
              type="text"
              name="postalCode"
              placeholder="Postal Code"
              value={orgForm.postalCode}
              className="normal-input-create-org"
              onChange={this.handleChange}
              maxLength={16}
            />
          </Grid>
          <Grid item xs={12} md={6} className="right-org-settings two-input-settings">
            <Input
              readOnly
              type="text"
              name="country"
              placeholder="Country"
              value={orgForm.country}
              className="normal-input-create-org"
              onChange={this.handleChange}
              autoComplete="off"
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            className={`two-input-settings search-org-input organization-activity ${classes.mobiscrollSelect}`}
          >
            <MobiscrollSelect
              responsive={{
                xsmall: {
                  display: 'bottom',
                },
                large: {
                  display: 'bubble',
                  touchUi: false,
                },
              }}
              iconLeft="none"
              iconRight={greyArrow}
              value={orgForm.timeZone}
              name="customTimeSelect"
              onSet={this.handleChangeCustomTime}
              placeholder="Select Time Zone"
            >
              {list.length > 0 && this.renderTimeZoneList()}
            </MobiscrollSelect>
            {/* <div id="dropdown-mobis" className="dropdown-mobis dropdown-confirm">
            <i className="icon-dropdown" />
          </div> */}
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            className={`${classes.inputPhoneSetLimit} right-org-settings two-input-settings`}
          >
            {this.checkPermisions() ? (
                <PhoneInput
                  type="text"
                  name="phoneNumber"
                  placeholder="Phone Number"
                  value={orgForm.phoneNumber}
                  onChange={e => this.handleChange({ target: { value: e } }, 'phoneNumber')}
                  country="US"
                  autoComplete="off"
                  limitMaxLength
                />
              ) :
              (
                <PhoneInput
                  type="text"
                  name="phoneNumber"
                  placeholder="Phone Number"
                  value={orgForm.phoneNumber}
                  onChange={e => this.handleChange({ target: { value: e } }, 'phoneNumber')}
                  country="US"
                  autoComplete="off"
                  readOnly
                  limitMaxLength
                />
              )}
          </Grid>
          <Grid item xs={12}>
            <Input
              type="text"
              autoComplete="off"
              name="websiteUrl"
              placeholder="Website Address"
              value={orgForm.websiteUrl}
              className="normal-input-create-org"
              onChange={this.handleChange}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            className={`two-input-settings search-org-input organization-activity ${classes.mobiscrollSelect}`}
          >
            <MobiscrollSelect
              responsive={{
                xsmall: {
                  display: 'bottom',
                },
                large: {
                  display: 'bubble',
                  touchUi: false,
                },
              }}
              iconLeft="none"
              iconRight={greyArrow}
              value={orgForm.status}
              name="customTimeSelect"
              onSet={this.handleStatusChange}
              placeholder="Organization activity"
            >
              <option value="active">Active</option>
              <option value="suspended">Suspended</option>
              <option value="inactive">Inactive</option>
            </MobiscrollSelect>
          </Grid>
          <Grid item xs={12} md={6} className="right-org-settings two-input-settings suspended-until">
            {orgForm.status === 'suspended' && (
              <DatePicker
                customInput={<CustomInput autocomplete="suspended" onClick={this.handleAllClick} />}
                calendarClassName="custom-calendar normal-input-create-org"
                selected={suspendedUntil}
                onChange={this.handleChangeDate}
                dateFormat="YYYY-MM-DD"
              />
            )}
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            className="switch-settings-label two-input-settings label-send-receive"
          >
            Send/Receive SMS
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            className={`${classes.inputPhoneSetLimit} right-org-settings two-input-settings receive-sms`}
          >
            {this.checkPermisions() ? (
                <PhoneInput
                  type="text"
                  name="tollFreeNumber"
                  placeholder="SMS number"
                  value={orgForm.tollFreeNumber}
                  onChange={e => this.handleChange({ target: { value: e } }, 'tollFreeNumber')}
                  country="US"
                  autoComplete="off"
                  limitMaxLength
                />
              ) :
              (
                <PhoneInput
                  type="text"
                  name="tollFreeNumber"
                  placeholder="SMS number"
                  value={orgForm.tollFreeNumber}
                  onChange={e => this.handleChange({ target: { value: e } }, 'tollFreeNumber')}
                  country="US"
                  autoComplete="off"
                  readOnly
                  limitMaxLength
                />
              )}
          </Grid>
        </Grid>

        <section id="switchers-list">
          <div className="switcher-row">
            <div className="switch-label">
              Evangelus Publishing Edition
            </div>
            <div className="switch-ctrl">
              <Switch
                checked={lite}
                classes={{
                  switchBase: classes.iOSSwitchBase,
                  bar: classes.iOSBar,
                  icon: classes.iOSIcon,
                  iconChecked: classes.iOSIconChecked,
                  checked: classes.iOSChecked,
                }}
                onChange={event => this.handleLiteModeChange(event)}
                name="lite"
              />
            </div>
          </div>

          <div className="switcher-row">
            <div className="switch-label">
              Connect with myParish App
            </div>
            <div className="switch-ctrl">
              <Switch
                checked={orgForm.linkToMyParishApp}
                classes={{
                  switchBase: classes.iOSSwitchBase,
                  bar: classes.iOSBar,
                  icon: classes.iOSIcon,
                  iconChecked: classes.iOSIconChecked,
                  checked: classes.iOSChecked,
                }}
                onChange={event => this.handleLinkMyParishAppChange(event)}
                name="linkToMyParish"
              />
            </div>
          </div>

          <div className="switcher-row">
            <div className="switch-label">
              Connect with Camino
            </div>
            <div className="switch-ctrl">
              <Switch
                checked={orgForm.linkToCamino}
                classes={{
                  switchBase: classes.iOSSwitchBase,
                  bar: classes.iOSBar,
                  icon: classes.iOSIcon,
                  iconChecked: classes.iOSIconChecked,
                  checked: classes.iOSChecked,
                }}
                onChange={event => this.handleLinkCaminoChange(event)}
                name="linkToMyParish"
              />
            </div>
          </div>

          <div className="switcher-row">
            <div className="switch-label">
              Disable all Welcome emails
            </div>
            <div className="switch-ctrl">
              <Switch
                classes={{
                  switchBase: classes.iOSSwitchBase,
                  bar: classes.iOSBar,
                  icon: classes.iOSIcon,
                  iconChecked: classes.iOSIconChecked,
                  checked: classes.iOSChecked,
                }}
                checked={disableWelcomeEmail}
                onChange={event => this.handleDisableWelcomeEmails(event)}
                name="disableWelcomeEmail"
              />
            </div>
          </div>

          <div className="switcher-row">
            <div className="switch-label">
              Auto-send weekly bulletin
            </div>
            <div className="switch-ctrl">
              <Switch
                checked={orgForm.bulletinSend}
                classes={{
                  switchBase: classes.iOSSwitchBase,
                  bar: classes.iOSBar,
                  icon: classes.iOSIcon,
                  iconChecked: classes.iOSIconChecked,
                  checked: classes.iOSChecked,
                }}
                onChange={event => this.handleBulletinSendChange(event)}
                name="bulletinSend"
              />
            </div>
          </div>

          <div className="switcher-row">
            <div className="switch-label">
              Disable all Welcome text
            </div>
            <div className="switch-ctrl">
              <Switch
                classes={{
                  switchBase: classes.iOSSwitchBase,
                  bar: classes.iOSBar,
                  icon: classes.iOSIcon,
                  iconChecked: classes.iOSIconChecked,
                  checked: classes.iOSChecked,
                }}
                checked={disableWelcomeText}
                onChange={event => this.handleDisableWelcomeText(event)}
                name="disableWelcomeText"
              />
            </div>
          </div>

          <div className={`switcher-row row-bulletin-send-at ${!orgForm.bulletinSend ? 'disabled-row' : ''}`}>
            <div className={`bulletin-send-at-day ${classes.mobiscrollSelect}`}>
              <MobiscrollSelect
                responsive={{
                  xsmall: {
                    display: 'bottom',
                  },
                  large: {
                    display: 'bubble',
                    touchUi: false,
                  },
                }}
                disabled={!orgForm.bulletinSend}
                iconLeft="none"
                iconRight={greyArrow}
                dataKeyField="value"
                value={orgForm.bulletinSendAt.day}
                onSet={this.handleBulletinSendDayChange}
                name="bulletinSendAt.day"
                placeholder="Day"
                data={daysOfTheWeek}
              />
            </div>

            <DatePicker
              disabled={!orgForm.bulletinSend}
              customInput={
                <CustomInput placeholderText="Time" autocomplete="bulletinSendAtTime"
                             disabled={!orgForm.bulletinSend} />}
              className="custom-timepicker"
              calendarClassName="custom-timepicker-calendar"
              selected={bulletinSendAtTime}
              onChange={this.handleBulletinSendTimeChange}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={30}
              timeCaption="Time"
              dateFormat="HH:mm"
              timeFormat="HH:mm"
            />

          </div>

          <div className="switcher-row">
            <div className="switch-label">Bi-lingual Spanish</div>
            <div className="switch-ctrl">
              <Switch
                checked={orgForm.isBilingualSpanish}
                classes={{
                  switchBase: classes.iOSSwitchBase,
                  bar: classes.iOSBar,
                  icon: classes.iOSIcon,
                  iconChecked: classes.iOSIconChecked,
                  checked: classes.iOSChecked,
                }}
                onChange={event => this.handleBilingualSpanishChange(event)}
                name="isBilingualSpanish"
              />
            </div>
          </div>

          <div className="switcher-row">
            <div className="switch-label">
              Auto-archive messages
            </div>
            <div className="days-wrapper">
              <Input
                type="number"
                value={daysToArchiveMessages > 999 ? 0 : daysToArchiveMessages}
                className="normal-input-create-org"
                onChange={(e) => this.setState({ daysToArchiveMessages: e.target.value })}
                maxLength={3}
              />
            </div>
            <div className="switch-ctrl">
              <Switch
                classes={{
                  switchBase: classes.iOSSwitchBase,
                  bar: classes.iOSBar,
                  icon: classes.iOSIcon,
                  iconChecked: classes.iOSIconChecked,
                  checked: classes.iOSChecked,
                }}
                checked={isAutoArchived}
                // onChange={event => this.handleAutoDeleteArchiveMessageSend(event)}
                onChange={() => this.setState({ isAutoArchived: !this.state.isAutoArchived })}
                name="autoDeleteArchivedMessages"
              />
            </div>

          </div>

          <div className="switcher-row">
            <div className="switch-label">
              Auto-delete archived messages
            </div>
            <div className="days-wrapper">
              <Input
                type="number"
                value={daysToDeleteArchivedMessages > 999 ? 0 : daysToDeleteArchivedMessages}
                className="normal-input-create-org"
                onChange={(e) => this.setState({ daysToDeleteArchivedMessages: e.target.value })}
                maxLength={3}
              />
            </div>
            <div className="switch-ctrl">
              <Switch
                classes={{
                  switchBase: classes.iOSSwitchBase,
                  bar: classes.iOSBar,
                  icon: classes.iOSIcon,
                  iconChecked: classes.iOSIconChecked,
                  checked: classes.iOSChecked,
                }}
                checked={isDeleteArchived}
                // onChange={event => this.handleAutoDeleteArchiveMessageSend(event)}
                onChange={() => this.setState({ isDeleteArchived: !this.state.isDeleteArchived })}
                name="autoDeleteArchivedMessages"
              />
            </div>
          </div>

          <div className="switcher-row">
            <div className="switch-label">Group Member Preference</div>
            <div className="switch-ctrl">
              <Switch
                checked={orgForm.isMemberPreferenceAvailable}
                classes={{
                  switchBase: classes.iOSSwitchBase,
                  bar: classes.iOSBar,
                  icon: classes.iOSIcon,
                  iconChecked: classes.iOSIconChecked,
                  checked: classes.iOSChecked,
                }}
                onChange={this.handleMemberPreferenceAvailableChange}
                name="isMemberPreferenceAvailable"
              />
            </div>
          </div>

          <div className="switcher-row" />

          <div>
            <div className="switch-label">
              Comment
            </div>
            <textarea
              rows="4"
              name="comment"
              onChange={(e) => this.setState({ comment: e.target.value })}
              value={comment}
              maxLength="250" />
          </div>

          <div className="switcher-row">
            <Button className="button-eva" type="submit" disabled={!orgForm.bulletinSend}
                    onClick={this.handleResendBulletin}>
              Resend Bulletin
            </Button>
          </div>

          {organizationType.name === 'Diocese' && (
            <div className="switcher-row">
              <div className="switch-label">Diocese Sync of Parishes</div>
              <div className="switch-ctrl">
                <Switch
                  checked={orgForm.isSyncParish}
                  classes={{
                    switchBase: classes.iOSSwitchBase,
                    bar: classes.iOSBar,
                    icon: classes.iOSIcon,
                    iconChecked: classes.iOSIconChecked,
                    checked: classes.iOSChecked,
                  }}
                  onChange={event => this.handleIsSyncParishChange(event)}
                  name="isSyncParish"
                />
              </div>
            </div>
          )}
        </section>

        <Grid item xs={8} />
        {(organizationType === 'Parish' || organizationType.name === 'Parish') && (
          <Grid container spacing={0} className="sect-parent-org">
            <Grid item xs={12}>
              <h3 className="title-org parent-org">Parent Organization</h3>
            </Grid>
            <Grid item xs={12}>
              <Input
                readOnly
                type="text"
                name="parentOrg"
                placeholder="Parent organization"
                value={orgForm.parentOrganizationName}
                className="normal-input-create-org"
              />
            </Grid>
          </Grid>
        )}
        {/* orgType !== 'Parish' && (
      <React.Fragment>
        <Grid item xs={12}>
          <h3 className="title-org parent-org">Disable sending to</h3>
        </Grid>
        <Grid item xs={12}>
          <SearchChildOrg />
        </Grid>
      </React.Fragment>
    ) */}
        <Grid item xs={12} className="org-admins-block">
          <h3 className="title-org parent-org">Org Admin(s)</h3>
          <button className="button-add-admins-org" onClick={this.addAdminOrg} />
        </Grid>
        <Grid item xs={12} sm={12} className="org-admins">
          <Grid container spacing={0}>
            <OrgAdminList />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <ButtonStandard title="Save" onClick={this.handleSubmit} />
        </Grid>
      </section>
    );
  }
}

SettingsOrg.propTypes = SettingsOrgPropType;

const mS = state => ({
  organizationTypes: state.organizationReducer.organizationTypes,
  organizationType: state.organizationReducer.organizationType,
  orgForm: state.organizationReducer,
  deleteArchived: state.organizationReducer.selectedOrg.isDeleteArchivedMessageActivated,
  comment: state.organizationReducer.selectedOrg.comment,
  daysToDeleteArchivedMessages: state.organizationReducer.selectedOrg.daysToDeleteArchivedMessages,
  autoArchived: state.organizationReducer.selectedOrg.isAutoArchiveMessage,
  daysToArchiveMessages: state.organizationReducer.selectedOrg.daysToArchiveMessages,
  selectedOrg: state.organizationReducer.selectedOrg,
  organizationTypeSelected: state.organizationReducer.organizationType.selectedType,
  windowParams: state.organizationReducer.windowParams,
  discoverOrganizationSuggestionValue:
  state.organizationReducer.discoverOrganizationSuggestionValue,
  discoverOrganizationSuggestionValueId:
  state.organizationReducer.discoverOrganizationSuggestionValueId,
  parentOrganizationName: state.organizationReducer.parentOrganizationName,
  parentOrganizationSsoId: state.organizationReducer.parentOrganizationSsoId,
  parentOrganizationEvaId: state.organizationReducer.parentOrganizationEvaId,
  member: state.userProfileReducer.member,
  mainPictureUrl: state.organizationReducer.mainPictureUrl,
  secondStreetAddress: state.organizationReducer.secondStreetAddress,
  pictureData: state.organizationReducer.pictureData,
  roles: state.userProfileReducer.member.roles,
  lite: state.organizationReducer.lite,
  disableWelcomeEmail: state.organizationReducer.disableWelcomeEmail,
  disableWelcomeText: state.organizationReducer.disableWelcomeText,
});

const mD = {
  clearSelectedOrganization,
  getOrganizationTypes,
  updateData,
  updateType,
  linkOrgToMyParishAppOnServer,
  linkOrgToCaminoOnServer,
  resendBulletin,
  updateOrganization,
  verifyKeyword,
  verifyKeywordForOrg,
  statusWindowsMessage,
  updateIsDeleteArchived,
  updateLiteMode,
  enableRightDrawer,
  updateOrgRoleMembers,
  getOrgAdmins,
  updateMembersList,
  clearSeleted,
  snackBarStatus,
  updateSuspendedUntil,
  clearOrganizationForm,
  activateCustomTitle,
};

export default withRouter(
  withStyles(styles)(
    connect(
      mS,
      mD,
    )(SettingsOrg),
  ),
);
