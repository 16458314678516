import React, { Component } from 'react';
import { connect } from 'react-redux';
import Pagination from 'react-js-pagination';
import PropTypes from 'prop-types';
import { getSuperAdmins } from '../../../Actions/SuperUserActions';

class TablePagination extends Component {
  handleOnKeyPress = e => {
    e.preventDefault();
  };

  handlePage = page => {
    const { sortField, order, qty } = this.props;
    if (page > 0) {
      this.props.getSuperAdmins(page, sortField, order, qty);
    }
  };

  handlePageChange = pageNumber => {
    this.handlePage(pageNumber);
  };

  handlePageRowsQuantityChange = qty => {
    const { sortField, order } = this.props;
    this.props.getSuperAdmins(1, sortField, order, qty);
  };

  renderPages = () => {
    const { paginator } = this.props;
    const pages = [];
    for (let i = 1; i <= paginator.totalPages; i += 1) {
      const currentPage = paginator.currentPage === i ? 'page-container-active' : '';
      pages.push(
        <div
          className={`pages-container ${currentPage}`}
          onClick={() => this.handlePage(i)}
          onKeyPress={this.handleOnKeyPress}
          role="button"
          tabIndex="0"
        >
          {i}
        </div>,
      );
    }
    return pages;
  };

  /* eslint-disable */
  render() {
    const { paginator, org, qty } = this.props;
    return (
      <div className="table-pagination-container-super-admin">
        <div className="pagination-counter">
          {paginator.currentPage} of {paginator.totalPages}
        </div>

        <div className="quantity-per-page">
          <span
            className={`quantity-option${qty === 10 ? ' quantity-option-active' : ''}`}
            onClick={() => this.handlePageRowsQuantityChange(10)}
          >
            10
          </span>
          <span className="quantity-separator">|</span>
          <span
            className={`quantity-option${qty === 25 ? ' quantity-option-active' : ''}`}
            onClick={() => this.handlePageRowsQuantityChange(25)}
          >
            25
          </span>
          <span className="quantity-separator">|</span>
          <span
            className={`quantity-option${qty === 50 ? ' quantity-option-active' : ''}`}
            onClick={() => this.handlePageRowsQuantityChange(50)}
          >
            50
          </span>
          <span className="quantity-separator">|</span>
          <span
            className={`quantity-option${qty === 'all' ? ' quantity-option-active' : ''}`}
            onClick={() => this.handlePageRowsQuantityChange('all')}
          >
            All
          </span>
        </div>

        <Pagination
          prevPageText={<div className="paginator-arrow-left" />}
          nextPageText={<div className="paginator-arrow-right" />}
          activePage={paginator.currentPage}
          itemsCountPerPage={qty}
          totalItemsCount={paginator.numberOfItems}
          pageRangeDisplayed={3}
          onChange={this.handlePageChange}
          hideFirstLastPages
        />
      </div>
    );
  }
}

TablePagination.propTypes = {
  paginator: PropTypes.isRequired,
  getSuperAdmins: PropTypes.func.isRequired,
  sortField: PropTypes.string,
  order: PropTypes.string,
  qty: PropTypes.number.isRequired,

};

const mS = ({ superUserReducer: {paginator, qty, order, sortField } }) => ({
  paginator,
  qty,
  order,
  sortField,
});

const mD = {
  getSuperAdmins,
};

export default connect(
  mS,
  mD,
)(TablePagination);
