import React, { useState } from 'react';
import { connect } from 'react-redux';
import wingsIcon from '../../Theme/Icons/wings-icon.svg';
import { openInEva } from '../../Actions/organizationActions';
import { updateOrgRoleMembers } from '../../Actions/membersActionsOrg';
import purple from '@material-ui/core/colors/purple';
import CircularProgress from '@material-ui/core/CircularProgress';
import './index.sass';

const ViewInEvangelusButton = ({ memberId, id, orgId, updateOrgRoleMembers, openInEva }) => {
  const [isLoading, setIsLoading] = useState(false);
  const handleClick = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (!orgId || !memberId) return;

    if (orgId) {
      setIsLoading(true);
      const response = await updateOrgRoleMembers(
        [{ public_id: memberId }],
        orgId,
        true,
      );

      setIsLoading(false);

      const status = response.response && response.response.status ? response.response.status : response.status;

      if (status === 200) {
        openInEva(id);
      }
    }
  }

  if (isLoading) {
    return <CircularProgress style={{ color: purple[500], width: 20, height: 20 }} thickness={3}/>
  }

  return (
    <a className="view-in-eva-button" href="#!" onClick={handleClick} title="View in Evangelus">
      <img src={wingsIcon} alt="wingsIcon"/>
    </a>
  )
};

const mD = {
  openInEva,
  updateOrgRoleMembers,
};

export default connect(null, mD)(ViewInEvangelusButton);
