import React from 'react';
import Grid from '@material-ui/core/Grid';
import Input from '../Input';
import InputBirthday from '../Member/InputBirthday';
import SimpleSelect from '../SimpleSelect';
import UserFormProfilePropType from '../../PropTypes/UserFormProfilePropType';
import './index.sass';

const GridForm = ({
  formData,
  handleChange,
  handleChangePassword,
  handleSelectChange,
  parseToValue,
  handleChangeBirthday,
}) => (
  <div>
    <Grid
      container
      spacing={0}
      className="form-complete-profile form-complete-mobile form-complete-tablet first-block"
      id="form-complete-profile"
    >
      <Grid item xs={12} sm={6} md={6} className="left-form">
        <Input
          name="firstName"
          title="firstName"
          type="text"
          placeholder="First name"
          value={formData.firstName}
          icon="firstName"
          onChange={handleChange}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={6} className="right-form">
        <Input
          name="lastName"
          title="lastName"
          type="text"
          placeholder="Last name"
          value={formData.lastName}
          icon=""
          onChange={handleChange}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={6} className="left-form invite-form">
        <Input
          name="email"
          title="email"
          type="text"
          placeholder="Email"
          value={formData.email}
          icon="email"
          onChange={handleChange}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={6} className="right-form">
        <Input
          name="phoneNumber"
          title="phone"
          type="text"
          placeholder="Phone number"
          value={formData.phoneNumber}
          icon="phone"
          onChange={handleChange}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={6} className="left-form birthday-input">
        <InputBirthday
          name="birthDate"
          title="birthdate"
          type="text"
          placeholder="Date of birth"
          value={formData.birthDate}
          icon="birthday"
          icon2="help-gray"
          onChange={handleChange}
          onChangeBirthday={handleChangeBirthday}
        />
      </Grid>
    </Grid>

    <Grid
      container
      spacing={0}
      className="form-complete-profile form-complete-mobile form-complete-tablet second-block"
      id="form-complete-profile"
    >
      <Grid item xs={12} sm={6} md={6} className="left-form invite-form">
        <Input
          name="street"
          title="street"
          type="text"
          placeholder="Street address"
          value={formData.street}
          icon="street"
          onChange={handleChange}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={6} className="right-form invite-form">
        <Input
          name="city"
          title="city"
          type="text"
          placeholder="City"
          value={formData.city}
          icon="city"
          onChange={handleChange}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={6} className="left-form invite-form">
        <Input
          name="province"
          title="province"
          type="text"
          placeholder="State/Province"
          value={formData.province}
          icon="province"
          onChange={handleChange}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={6} className="right-form invite-form">
        <Input
          name="postalCode"
          title="postalCode"
          type="text"
          placeholder="Postal Code"
          value={formData.postalCode}
          icon="postal-code"
          onChange={handleChange}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={6} className="left-form invite-form">
        <div id="country-input-select-icon">
          <SimpleSelect
            value={formData.selectedCountry.public_id}
            options={parseToValue(formData.country)}
            handleChange={handleSelectChange}
            name="country"
            icon="input-eva-country-icon"
          />
        </div>
      </Grid>
    </Grid>
    <Grid
      container
      spacing={0}
      className="form-complete-profile form-complete-mobile form-complete-tablet third-block"
      id="form-complete-profile"
    >
      <Grid item xs={12} sm={6} md={6} className="left-form invite-form">
        <Input
          name="password"
          title="password"
          type="password"
          placeholder="Password"
          value={formData.password}
          icon="password"
          onChange={handleChangePassword}
        />
      </Grid>
    </Grid>
  </div>
);

GridForm.propTypes = UserFormProfilePropType;
export default GridForm;
