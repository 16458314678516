import React from 'react';
import PropTypes from 'prop-types';
// import moment from 'moment';
import { withRouter } from 'react-router-dom';
import purple from '@material-ui/core/colors/purple';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import TablePagination from './TablePagination';
import images from '../../../Theme/Images';
import SimpleModal from '../ConfirmRevokeAccessModal';

const styles = theme => ({
  progress: {
    margin: theme.spacing.unit * 2,
    textAlign: 'center',
  },
});

/**
 * Maps the result array to something manageable by the list
 * component.
 * @param {Object} elem
 */
const superAdminsMapper = ({
  _source: { user, firstName, lastName, profilePictureUrl, publicId },
}) => ({
  firstName,
  lastName,
  profilePictureUrl,
  email: user.hasEmail ? user.email : 'No Email',
  publicId,
});

/**
 * Comparation fucntion to sort super admin results.
 * @param {string} order Ascending (asc) or descending (desc)
 * @param {string} param Could be name or email
 */
const sorter = (order, param = 'name') => (item0, item1) => {
  try {
    let sortParam1;
    let sortParam0;
    const orderFactor = order === 'desc' ? -1 : 1;
    switch (param) {
      case 'name':
        sortParam0 = item0.firstName + item0.lastName;
        sortParam1 = item1.firstName + item1.lastName;
        break;
      default:
        sortParam0 = item0[param];
        sortParam1 = item1[param];
    }
    return orderFactor * sortParam0.localeCompare(sortParam1);
  } catch (err) {
    return 1;
  }
};

/**
 * Capitalizes a name for a given super user.
 * @param {Object} superUser
 */
const capitalizeName = superUser => {
  let { firstName, lastName } = superUser;
  if (!firstName) {
    firstName = ' ';
  }
  let lname;
  if (lastName) {
    lname = lastName[0].toUpperCase() + lastName.slice(1);
  } else {
    lname = '';
    lastName = ' ';
  }
  const fname = firstName[0].toUpperCase() + firstName.slice(1);
  return `${fname} ${lname}`;
};

class DataTable extends React.Component {
  state = {
    modalOpen: false,
    modalMessage: '',
    adminSelected: undefined,
  };

  handleClick = superUser => {
    const template = (
      <p className="confirm-revoke-warning">
        {`WARNING: This action will revoke ${capitalizeName(superUser)} access as a Super admin. `}
        <strong>Do you want to revoke it?</strong>
      </p>
    );
    this.setState({
      modalOpen: true,
      modalMessage: template,
      adminSelected: superUser,
    });
  };

  /**
   * If there is an admin selected, display its capitalized name.
   */

  /**
   * Calls the corresponding action that may trigger the
   * REVOKE_SUPER_USER_ACCESS
   * @param {Object} superUser
   */
  async proceedToRevokeSuperUserPrivileges() {
    const publicId = this.props.user;
    const { publicId: adminPublicId } = this.state.adminSelected;
    const result = await this.props.revokeSuperUserAccess(this.state.adminSelected);
    if (result && adminPublicId === publicId) {
      // If the user revoked himself/herself the super admin credentials must be logged out.
      this.props.history.push('/logout');
    }
    this.setState({ modalOpen: false, modalMessage: '', adminSelected: undefined });
    this.props.getSuperAdmins();
  }

  handleSortBy = sortField => {
    const { paginator, order, qty } = this.props;
    const nextOrder = order === 'desc' ? 'asc' : 'desc';
    /* check if was selected another field to sort */
    this.props.getSuperAdmins(paginator.currentPage, sortField, nextOrder, qty);
  };

  /**
   * Returns an object with the display information (Layout) of the super
   * @param {Object} superAdminFromReducer
   */
  extractSuperAdminInfo = member => {
    const superAdmin = {};
    superAdmin.name = (
      <div key={member.public_id} className="card-group-member-super-admin">
        <img src={member.profilePictureUrl ? member.profilePictureUrl : images.User} alt="member" />
        <p>{capitalizeName(member)}</p>
      </div>
    );
    superAdmin.email = member.email;
    superAdmin.accessStatus = member.accessStatus;
    return superAdmin;
  };

  renderProgress = () => {
    const { classes } = this.props;
    return (
      <CircularProgress className={classes.progress} style={{ color: purple[500] }} thickness={7} />
    );
  };

  render() {
    const {
      value,
      sortField,
      qty,
      paginator: { currentPage },
      order,
    } = this.props;
    // const { order, sortField } = this.state;
    let processedValue = value.sort(sorter(order, sortField));
    if (typeof qty === 'number') {
      processedValue = processedValue.slice((currentPage - 1) * qty, currentPage * qty);
    }
    return (
      <div>
        <div className="pagination-container-super-admin">
          <table className="diocesan-table">
            <tr>
              <th onClick={() => this.handleSortBy('name')} className="sort-column">
                Admin Name
                <span>
                  <i className="sort-icon" />
                </span>
              </th>

              <th onClick={() => this.handleSortBy('email')} className="sort-column disable">
                Email
                <span>
                  <i className="sort-icon" />
                </span>
              </th>

              <th className="table-field-access-status-super-admin">Access</th>
            </tr>

            {this.props.loading ? (
              <tr>
                <td colSpan={3} style={{ textAlign: 'center' }}>
                  {this.renderProgress()}
                </td>
              </tr>
            ) : (
              processedValue.map(superAdmin__ => {
                const superAdmin = this.extractSuperAdminInfo(superAdmin__);
                return (
                  <tr className="tr-body-list">
                    {/* Name field */}
                    <td className="table-field-name-super-admin">{superAdmin.name}</td>
                    {/* Email Field */}
                    <td className="disable table-field-email-super-admin">{superAdmin.email}</td>
                    {/* Access Status */}
                    <td className="table-field-access-status-super-admin">
                      <button onClick={() => this.handleClick(superAdmin__)}>
                        <i className="revoke-super-admin-status-icon" />
                      </button>
                    </td>
                  </tr>
                );
              })
            )}
          </table>
        </div>
        <TablePagination />
        <SimpleModal
          message={this.state.modalMessage}
          statusDialog={this.state.modalOpen}
          handleClose={() =>
            this.setState({ modalOpen: false, modalMessage: '', adminSelected: undefined })
          }
          handleConfirm={() => this.proceedToRevokeSuperUserPrivileges()}
        />
      </div>
    );
  }
}

DataTable.propTypes = {
  value: PropTypes.isRequired,
  classes: PropTypes.isRequired,
  loading: PropTypes.isRequired,
  paginator: PropTypes.isRequired,
  getSuperAdmins: PropTypes.func.isRequired,
  revokeSuperUserAccess: PropTypes.func.isRequired,
  user: PropTypes.instanceOf(Object).isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
  order: PropTypes.string.isRequired,
  qty: PropTypes.number.isRequired,
  sortField: PropTypes.string.isRequired,
};

export default withRouter(withStyles(styles)(DataTable));
