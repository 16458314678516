import React from 'react';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import { CustomInputPhone as InputPhone } from '../../InputPhone';
import { CustomInput as InputStandard } from '../../Input';
import EvaButton from '../../EvaButton';
import SearchMemberPropType from '../../../PropTypes/SearchMemberPropType';
import wingsSvg from '../../../Theme/Icons/wingsBig.svg';
import checkSvg from '../../../Theme/Icons/check.svg';
import { SearchMemberStyle, button, phoneIndent } from './styles';
import './index.sass';

const SearchMember = ({
  stepValues: { phone, email },
  nextStep,
  inputChange,
  validNumber,
  classes,
}) => (
  <Grid container justify="center" alignItems="center" id="search-member-container">
    <Grid item xs={10}>
      <p style={SearchMemberStyle.paragraph}>
        Enter a phone number or email <br /> address below.
      </p>
    </Grid>
    <Grid className={`input-phone-number-admin-org ${classes.phoneCustom}`} xs={10}>
      <InputPhone
        name="phone"
        placeholder="Phone number"
        value={phone}
        onInputPhoneChange={phoneNumber =>
          inputChange({ target: { value: phoneNumber, name: 'phone' } })
        }
      />
      {validNumber && <img className="input-phone-number-check" src={checkSvg} alt="check" />}
    </Grid>
    <Grid item xs={10}>
      <InputStandard
        name="email"
        placeholder="Email address"
        type="email"
        value={email}
        icon="email"
        onChange={inputChange}
      />
    </Grid>
    <Grid item xs={10} style={{ ...SearchMemberStyle.row, marginTop: '40px' }}>
      <EvaButton active onClick={() => nextStep(phone, email)}>
        <span style={{ fontFamily: button.fontFamily, textTransform: 'capitalize' }}>Continue</span>
      </EvaButton>
    </Grid>
    <Grid item xs={10} style={{ ...SearchMemberStyle.row, marginTop: '100px' }}>
      <img src={wingsSvg} alt="wings" />
    </Grid>
  </Grid>
);

SearchMember.propTypes = SearchMemberPropType;

export default withStyles(phoneIndent)(SearchMember);
