import active from '../../Theme/Icons/check.svg';
import colors from '../../Theme/Colors';

const styles = {
  root: {
    borderStyle: 'solid',
    borderWidth: '0px 0px 2px',
    borderColor: colors.grayCheck,
    display: 'flex',
    alignItems: 'center',
    '& .custom-input-sa': {
      backgroundImage: `url('${active}')`,
      backgroundRepeat: 'no-repeat',
      // padding: '6.5px 7.9px 6.5px 7.9px',
      backgroundSize: 'contain',
      backgroundPosition: 'center center',
      marginRight: '21px',
      height: '13px',
      width: '15.8px',
      display: 'inline-block',
      flexShrink: 0,
    },
    '& .custom-input-sa-check-not-visible': {
      visibility: 'hidden',
    },
    '& .input-eva-email-icon': {
      width: '45px',
      height: '45px',
      display: 'inline-block',
      flexShrink: 0,
    },
    '& .input-eva-firstName-icon': {
      width: '45px',
      height: '45px',
      display: 'inline-block',
      flexShrink: 0,
    },
    '& .input-eva-lastName-icon': {
      width: '45px',
      height: '45px',
      display: 'inline-block',
    },
  },
  input: {
    borderStyle: 'none',
    height: '74px',
    width: '85%',
    'background-color': 'transparent',
    '@media only screen and (max-width: 411px)': {
      paddingLeft: '10px',
    },
    '@media only screen and (max-width: 1023px)  and (min-width: 768px)': {
      margin: '0px',
      width: '90%',
    },
  },
};

export default styles;
