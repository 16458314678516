import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import purple from '@material-ui/core/colors/purple';
import { CSSTransition } from 'react-transition-group';
import OrgAdminItem from './OrgAdminItem';
import snackBarStatus from '../../Actions/snackbarActions';
import {
  getOrgAdmins,
  removeRoleOrgAdmin,
} from '../../Actions/membersActionsOrg';
import './index.sass';

const styles = theme => ({
  progress: {
    margin: theme.spacing.unit * 2,
  },
});

class OrgAdminList extends Component {
  removeRole = e => {
    const { selectedOrg, membersAdminOrgList } = this.props;
    const id = this.props.match.params.orgId;
    if (membersAdminOrgList.length > 0) {
      this.props
        .removeRoleOrgAdmin(selectedOrg.publicId, e, membersAdminOrgList)
        .then(res => {
          if (res.status === 200) {
            this.props.snackBarStatus({
              payload: {
                enable: true,
                title: 'Org Admin has been successfully removed.',
                type: 'success',
              },
            });
            this.refresh(id);
          }
        })
        .catch(() => {
          this.props.snackBarStatus({
            payload: {
              enable: true,
              title: 'Error , try again',
              type: 'error',
            },
          });
        });
    }
  };
  refresh = orgId => {
    this.props.getOrgAdmins(orgId);
  };
  renderProgress = () => {
    const { classes } = this.props;
    return (
      <CircularProgress
        className={classes.progress}
        style={{ color: purple[500] }}
        thickness={7}
      />
    );
  };
  renderMembers = () => {
    const { membersAdminOrgList } = this.props;
    const OrgAdminItems = membersAdminOrgList.map((admin, index) => (
      <CSSTransition timeout={500} classNames="cross-fade">
        <Grid item xs={12} sm={6} md={6} className="">
          <OrgAdminItem
            index={index}
            id={admin.publicId}
            admin={admin}
            removeRole={this.removeRole}
            memberList={membersAdminOrgList}
          />
        </Grid>
      </CSSTransition>
    ));
    return OrgAdminItems;
  };
  render() {
    const { loading } = this.props;
    return loading ? this.renderProgress() : this.renderMembers();
  }
}

const mS = state => ({
  selectedOrg: state.organizationReducer.selectedOrg,
  membersAdminOrgList: state.membersReducer.membersAdminOrgList,
  loading: state.membersReducer.loading,
});

const mD = {
  getOrgAdmins,
  removeRoleOrgAdmin,
  snackBarStatus,
};

export default withRouter(
  connect(
    mS,
    mD,
  )(withStyles(styles)(OrgAdminList)),
);
