import { ACTIONS } from '../Actions/topDrawerActions';

const initialState = {
  statusTopDrawer: false,
  selectedElement: {},
};
const topDrawerReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.DRAWER:
      return {
        ...state,
        statusTopDrawer: action.payload,
      };
    case ACTIONS.SELECT_ELEMENT:
      return {
        ...state,
        selectedElement: action.payload,
      };
    default:
      return state;
  }
};

export default topDrawerReducer;
