import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const styles = {
  root: {
    fontFamily: 'GothamSSm-Medium',
    background: 'white',
    borderRadius: 20,
    border: '2px solid',
    borderColor: '#9a66bf',
    color: '#9a66bf',
    minHeight: '40px',
    minWidth: '160px',
    '&.disbaled': {
      borderColor: '#a9a9a9',
      color: '#a9a9a9',
    },
  },
};

const EvaButton = ({ classes, children, onClick, active, className }) => (
  <Button
    className={`${classes.root} ${active ? 'disabled' : ''} ${className}`}
    disabled={active !== true}
    onClick={onClick}
  >
    {children}
  </Button>
);

EvaButton.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node,
  active: PropTypes.bool,
  className: PropTypes.string,
};

EvaButton.defaultProps = {
  children: 'No title',
  active: true,
  className: '',
};

export default withStyles(styles)(EvaButton);
