import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './index.sass';

const CustomScheduleInput = ({ value, onClick, placeholder }) => (
  <input
    className="custom-input"
    placeholder={placeholder}
    onClick={onClick}
    value={value}
  />
);

CustomScheduleInput.propTypes = {
  value: PropTypes.isRequired,
  onClick: PropTypes.isRequired,
  placeholder: PropTypes.isRequired,
};

const Calendar = ({
  showYearDropdown,
  showMonthDropdown,
  handleCalendar,
  date,
  isTime,
  placeholder,
}) => (
  <DatePicker
    customInput={<CustomScheduleInput onClick={handleCalendar} />}
    selected={date}
    onChange={handleCalendar}
    calendarClassName="custom-calendar"
    timeFormat={`${isTime ? 'HH:mm' : ''}`}
    dateFormat={`MMMM D, YYYY ${isTime ? 'LT' : ''}`}
    showTimeSelect={isTime}
    timeCaption="time"
    timeIntervals={15}
    placeholderText={placeholder}
    showYearDropdown={showYearDropdown}
    showMonthDropdown={showMonthDropdown}
  />
);

Calendar.defaultProps = {
  showYearDropdown: false,
  showMonthDropdown: false,
};
Calendar.propTypes = {
  showYearDropdown: PropTypes.bool,
  showMonthDropdown: PropTypes.bool,
  handleCalendar: PropTypes.isRequired,
  date: PropTypes.isRequired,
  isTime: PropTypes.isRequired,
  placeholder: PropTypes.isRequired,
};

export default Calendar;
