import { ACTIONS } from '../Actions/authHttpActions';
// fromGroup = true (action from a group) and fromGroup = false (action from the compose button)
const initialState = {
  response_code: '',
};
const authHttpReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.RESPONSE_CODE:
      return {
        ...state,
        response_code: action.payload,
      };
    default:
      return state;
  }
};
export default authHttpReducer;
