import { ACTIONS } from '../Actions/keywordActions';

const initialState = {
  list: [],
  selectedKeyword: {},
  loading: false,
};

const invitationReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.GET_KEYWORDS:
      return {
        ...state,
        list: action.payload,
      };

    case ACTIONS.ADD_KEYWORD:
      return {
        ...state,
        list: [action.payload].concat(state.list),
      }

    case ACTIONS.UPDATE_KEYWORD:
      return {
        ...state,
        list: state.list.map(item => item.publicId === action.payload.publicId ? action.payload : item),
      }

    case ACTIONS.LOADING_KEYWORD:
      return {
        ...state,
        loading: action.payload,
      }

    default:
      return state;
  }
};

export default invitationReducer;
