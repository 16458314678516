import buildUrl from 'build-url';
import AXIOS from '../Config/AxiosConfig';
import headers from './Helper';

/* eslint-enable */

// NOTE: In this case, the diocesan-application must be 'admin'.
const baseURL = '/admin-panel/organizations';

const Organization = {
  getOrganizationsShort(params) {
    const url = buildUrl(`v2${baseURL}`, {
      queryParams: params,
    });
    return AXIOS.get(url, {
      headers: headers(),
    });
  },
  getFullOrganization(publicId) {
    return AXIOS.get(`/v2/admin-panel/organizations/${publicId}`, {
      headers: headers(),
    });
  },
  getTypeOrganization() {
    return AXIOS.get('/admin-panel/organization-type', {
      headers: headers(),
    });
  },
  linkToMyParishApp(orgPublicId, linkToMyParishApp) {
    return AXIOS.put(
      `/admin-panel/organization/${orgPublicId}/link-to-mpa`,
      { linkToMyParishApp },
      { headers: headers() },
    );
  },
  linkToCamino(orgPublicId, linkToCamino) {
    return AXIOS.put(
      `/admin-panel/organization/${orgPublicId}/link-to-camino`,
      { linkToCamino },
      { headers: headers() },
    );
  },
  getDiscoverOrganization(searchTerm, organizationType, sortField, sortOrder) {
    const url = buildUrl('/admin-panel/sso/organization', {
      queryParams: {
        name: searchTerm,
        type: organizationType.toLowerCase(),
        sortField,
        sortOrder,
        limit: 100,
        page: 1,
      },
    });
    return AXIOS.get(url, {
      headers: headers(),
    });
  },
  createOrganization(data) {
    const {
      short_name,
      img,
      members,
      name,
      ssoMasterName,
      organization_type,
      organization_addresses,
      parent_organization,
      description,
      status,
      keyword,
      sso_organization_id,
      mpaOrganizationId,
    } = data;
    /* eslint-disable */
    return AXIOS.post(
      '/admin-panel/organization',
      {
        name: name ? name : short_name,
        short_name,
        sso_master_name: ssoMasterName,
        img,
        members,
        organization_type,
        organization_addresses,
        parent_organization,
        description,
        status,
        keyword,
        sso_organization_id,
        mpaOrganizationId: mpaOrganizationId ? mpaOrganizationId : null,
      },
      {
        headers: headers(),
      },
    );
    /* eslint-enable */
  },
  updateOrganization(data, organizationPublicId) {
    const {
      bulletinSend,
      bulletinSendAt,
      shortName,
      name,
      comment,
      postalCode,
      websiteUrl,
      tollFreeNumber,
      city,
      state,
      streetAddress,
      secondStreetAddress,
      // discoverOrganizationSuggestionValue,
      discoverOrganizationSuggestionValueId,
      organizationType,
      country,
      daysToDeleteArchivedMessages,
      daysToArchiveMessages,
      isDeleteArchivedMessageActivated,
      isAutoArchiveMessage,
      isBilingualSpanish,
      isSyncParish,
      linkToMyParishApp,
      mainPictureUrl,
      phoneNumber,
      timeZone,
      addressPublicId,
      status,
      parentOrganizationSsoId,
      parentOrganizationEvaId,
      keyword,
      lite,
      disableWelcomeEmail,
      disableWelcomeText,
      isMemberPreferenceAvailable,
      // selectedOrg: { id: ssoOrgId },
    } = data;
    const body = {
      // name: discoverOrganizationSuggestionValue,
      name,
      comment,
      bulletinSend,
      bulletinSendAt,
      short_name: shortName,
      disableWelcomeEmail,
      disableWelcomeText,
      keyword,
      website_url: websiteUrl,
      organization_type: organizationType,
      phone_number: phoneNumber,
      time_zone: timeZone,
      link_to_my_parish_app: linkToMyParishApp,
      lite,
      suspended_until: null,
      isDeleteArchivedMessageActivated,
      isAutoArchiveMessage,
      daysToDeleteArchivedMessages,
      daysToArchiveMessages,
      is_bilingual_spanish: isBilingualSpanish,
      is_sync_parish: isSyncParish,
      organization_addresses: [
        {
          public_id: addressPublicId,
          reference_address: streetAddress,
          state_text: state,
          city_text: city,
          postal_code: postalCode,
          country_text: country,
          second_reference_address: secondStreetAddress,
        },
      ],
      toll_free_number: tollFreeNumber,
      parent_organization: {
        ssoId: parentOrganizationSsoId || null,
        evaId: parentOrganizationEvaId || null,
      },
      img: mainPictureUrl,
      status,
      sso_organization_id: discoverOrganizationSuggestionValueId,
      isMemberPreferenceAvailable,
    };
    // provisional, to prevent error
    if (!discoverOrganizationSuggestionValueId) {
      delete body.sso_organization_id;
    }

    return AXIOS.put(`/admin-panel/organization/${organizationPublicId}`, body, {
      headers: headers(),
    });
  },

  resendBulletin(organizationPublicId) {
    return AXIOS.get(`/admin-panel/organization/${organizationPublicId}/resend-bulletin`, {
      headers: headers(),
    });
  },

  getTimeZone() {
    return AXIOS.get('/timezone', {
      headers: headers(),
    });
  },
  verifyKeyword(keyword) {
    return AXIOS.get(`/admin-panel/organization/keyword/exists/${keyword}`, {
      headers: headers(),
    });
  },
  verifyKeywordForOrg(keyword, orgPublicId) {
    return AXIOS.get(`/admin-panel/keyword/is-available/organization/${orgPublicId}/${keyword}`, {
      headers: headers(),
    });
  },
}; /* eslint-disable */

export default Organization;

/* eslint-disable eol-last */
