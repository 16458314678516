export const ACTIONS = {
  ACTIVATE_CUSTOM_TITLE: 'HEADER_BAR/CUSTOM_TITLE',
}

/**
 * Places a custom title where the burger menu and the EVANGELUS
 * title is placed.
 * @param {React.Component} customTitle
 */
export function activateCustomTitle(customTitle) {
  return function (dispatch) {
    dispatch({
      type: ACTIONS.ACTIVATE_CUSTOM_TITLE,
      payload: { customTitle },
    });
  };
}

export default ACTIONS;
