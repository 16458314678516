import { ACTIONS } from '../Actions/userActions';

const initialState = {
  username: '',
  email: '',
  public_id: '',
  display_name: '',
  language_preference: {},
  is_verified_email: true,
  is_incomplete: true,
  selectedOrganization: {},
  memberPublicId: '',
  member: {
    // this field is loaded as default state because is required on <SetingsOr/>
    // on mount (before fetched data is returned)
    roles: [],
  },
};

const userProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.GET_USER_PROFILE:
      return { ...action.payload };
    case ACTIONS.GET_MEMBER_PROFILE:
      return {
        ...state,
        ...action.payload,
      };
    case ACTIONS.SELECT_ORG:
      return { ...state, selectedOrganization: { ...action.payload } };
    default:
      return state;
  }
};

export default userProfileReducer;
