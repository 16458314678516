import AXIOS from '../Config/AxiosConfig';
import headers from './Helper';

const SuperUser = {
  getSuperUsers() {
    const Headers = headers();
    return AXIOS.get('/v2/admin-panel/superadmins/search', { headers: Headers });
  },
  revokeSuperUserAccess(memberPublicId) {
    const hdrs = headers();
    return AXIOS.delete(`admin-panel/member/${memberPublicId}/remove-superadmin`, {
      headers: hdrs,
    });
  },

  grantSuperUserAccess(payload) {
    const hdrs = headers();
    return AXIOS.put('/admin-panel/promote-to-superadmin', payload, { headers: hdrs });
  },

  inviteToBeSuperAdmin(payload) {
    const hdrs = headers();
    return AXIOS.post(
      '/admin-panel/invitation/multiple-organizations-and-members-superadmin',
      payload,
      { headers: hdrs },
    );
  },
};

export default SuperUser;
