import React, { PureComponent } from 'react';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import MobiscrollSelect from '../MobiscrollSelect';
import searchIcon from '../../Theme/Icons/search.png';
import snackBarStatus from '../../Actions/snackbarActions';
import { setEnforceFocus } from '../../Actions/rightDrawerShortActions';
import {
  searchDiscoverOrganizations,
  hydrateInputsDiscover,
} from '../../Actions/organizationActions';

class SearchOrganization extends PureComponent {
  getPlaceholder = () => {
    if (
      this.props.organizationType &&
      this.props.organizationType.name &&
      this.props.organizationType.name === 'Parish'
    ) {
      return 'Add a parish';
    }
    return 'Search for organization';
  };

  openOrgAutocomplete = () => {
    // if empty object or empty string then throw a messsage
    if (
      JSON.stringify(this.props.organizationType) === JSON.stringify({}) ||
      (typeof this.props.organizationType === 'string' && !this.props.organizationType.length)
    ) {
      this.props.snackBarStatus({
        payload: {
          enable: true,
          title: 'Please select a type first.',
          type: 'error',
        },
      });
      // return false to prevent open
      return false;
    }
    // else open search/select and clear previous suggestions
    this.props.setEnforceFocus(false);
    return true;
  };

  closeOrgAutocomplete = () => {
    this.props.setEnforceFocus(true);
  };

  handleFilterChange = debounce(e => {
    this.props.searchDiscoverOrganizations(e.filterText,
      this.props.organizationType.name, 'name', 'asc');
    // return false to prevent mobiscroll filter, since the API is filtering the suggestions
    return false;
  }, 600);

  handleSuggestionSelect = (e, inst) => {
    const { discoverOrganizationsSuggestions } = this.props;

    const selectedOrg = discoverOrganizationsSuggestions.find(
      suggestion => suggestion.id === inst.getVal(),
    );

    this.props.hydrateInputsDiscover(selectedOrg);
  };

  render() {
    const { discoverOrganizationSuggestionValueId, discoverOrganizationsSuggestions } = this.props;

    let orgSuggestions = [];

    if (discoverOrganizationsSuggestions && Array.isArray(discoverOrganizationsSuggestions)) {
      orgSuggestions = discoverOrganizationsSuggestions.map(suggestion => ({
        value: suggestion.id,
        text: suggestion.name,
        // html classes are styled by Select styles
        html: `<div class="cstm-select-itm-m">${
          suggestion.name
        }</div><div class="cstm-select-itm-s">${suggestion.city}, ${
          suggestion.stateCode
        } ${suggestion.zipCode || ''}</div>`,
      }));
    }

    return (
      <MobiscrollSelect
        responsive={{
          xsmall: {
            touchUi: true,
            display: 'center',
          },
          large: {
            touchUi: false,
            display: 'center',
          },
        }}
        filter
        multiline={2}
        height={65}
        placeholder={this.getPlaceholder()}
        filterPlaceholderText="Search"
        data={orgSuggestions}
        value={discoverOrganizationSuggestionValueId}
        iconLeft="none"
        iconRight={searchIcon}
        onFilter={this.handleFilterChange}
        onBeforeShow={this.openOrgAutocomplete}
        onBeforeClose={this.closeOrgAutocomplete}
        onSet={this.handleSuggestionSelect}
      />
    );
  }
}

SearchOrganization.propTypes = {
  organizationType: PropTypes.oneOfType([PropTypes.objectOf(PropTypes.string), PropTypes.string])
    .isRequired,
  discoverOrganizationsSuggestions: PropTypes.arrayOf(PropTypes.object).isRequired,
  snackBarStatus: PropTypes.func.isRequired,
  setEnforceFocus: PropTypes.func.isRequired,
  searchDiscoverOrganizations: PropTypes.func.isRequired,
  discoverOrganizationSuggestionValueId: PropTypes.number.isRequired,
  hydrateInputsDiscover: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  organizationType: state.organizationReducer.organizationType,
  discoverOrganizationsSuggestions: state.organizationReducer.discoverOrganizationsSuggestions,
  discoverOrganizationSuggestionValueId:
    state.organizationReducer.discoverOrganizationSuggestionValueId,
});

const mapDispatchToProps = {
  snackBarStatus,
  setEnforceFocus,
  searchDiscoverOrganizations,
  hydrateInputsDiscover,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SearchOrganization);
