import { ACTIONS } from '../Actions/membersActions';
import { ACTIONS_MEMBERS_ORG } from '../Actions/membersActionsOrg';
// fromGroup = true (action from a group) and fromGroup = false (action from the compose button)
const initialState = {
  memberList: [],
  loading: false,
  total: 0,
  membersAdminList: [],
  membersAdminOrgList: [],
};
const membersReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.UPDATE_LIST:
      return {
        ...state,
        memberList: action.payload,
      };
    case ACTIONS.SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case ACTIONS.GET_TOTAL_MEMBERS:
      return {
        ...state,
        total: action.payload,
      };
    case ACTIONS.GET_ORG_ADMINS:
      return {
        ...state,
        membersAdminOrgList: action.payload,
      };
    case ACTIONS_MEMBERS_ORG.GET:
      return {
        ...state,
        memberList: action.payload,
      };
    case ACTIONS_MEMBERS_ORG.UPDATE_LIST:
      return {
        ...state,
        ...action.payload,
      };
    case ACTIONS_MEMBERS_ORG.SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case ACTIONS_MEMBERS_ORG.GET_TOTAL_MEMBERS:
      return {
        ...state,
        total: action.payload,
      };
    case ACTIONS_MEMBERS_ORG.GET_ORG_ADMINS:
      return {
        ...state,
        membersAdminOrgList: action.payload,
      };
    case ACTIONS_MEMBERS_ORG.CLEAR:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};
export default membersReducer;
