import React from 'react';
import Grid from '@material-ui/core/Grid';
import './index.sass';

// this page was called from Components/UserFormProfile
// an this was the "message"
// const message = 'A previous session already exists.';

// now I'm ussing this message
const message = (
  <div>
    <div>
      You are not authorized to access this page.
    </div>
    <div style={{ marginTop: '20px' }}>
      Redirecting...
    </div>
  </div>
);

const Unauthorized = () => (
  <div>
    <Grid container spacing={0} className="main-content">
      <Grid item xs={12} className="title description-section-content">
        EVANGELUS
      </Grid>
      <Grid item xs={12} className=" subtitle description-section-content">
        {message}
      </Grid>
    </Grid>
  </div>
);

export default Unauthorized;
