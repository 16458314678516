import PropTypes from 'prop-types';

const OrgInviteAdminPropType = {
  clear: PropTypes.func.isRequired,
  classes: PropTypes.instanceOf(Object).isRequired,
  createOrg: PropTypes.bool,
  currentStep: PropTypes.number.isRequired,
  members: PropTypes.instanceOf(Array).isRequired,
  searchMemberAction: PropTypes.func.isRequired,
  selectedOrg: PropTypes.instanceOf(Object).isRequired,
  snackBarStatus: PropTypes.func.isRequired,
  steps: PropTypes.instanceOf(Object).isRequired,
  update: PropTypes.func.isRequired,
};

export default OrgInviteAdminPropType;
