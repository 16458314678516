import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  updatePassword,
  clearPassword,
  checkPinCode,
} from '../../Actions/passwordActions';
import Input from '../Input';
import ButtonStandard from '../Button';
import ErrorMessage from '../ErrorMessage';
import ResetPasswordPropTypes from '../../PropTypes/ResetPasswordPropType';
import './index.sass';

class ResetCode extends Component {
  submit = e => {
    const { pin, history, username } = this.props;
    e.preventDefault();

    if (pin) {
      this.props
        .checkPinCode(username, pin)
        .then(response => {
          if (response && response.data && response.data.code === 200) {
            history.push(`/forgot-password/${pin}`);
            this.props.updatePassword({
              payload: { error: '' },
            });
          } else if (response && response.code === 500) {
            this.props.updatePassword({
              payload: { error: '500 Server error' },
            });
          } else {
            this.props.updatePassword({ payload: { error: 'Invalid token' } });
          }
        })
        .catch(err => {
          this.props.updatePassword({ payload: { error: err.message } });
        });
    }
  };

  render() {
    return (
      <div className="container-center container-left-mobile reset-password">
        <div className="login__title">
          <p className="logo-evangelus">EVANGELUS</p>
        </div>

        <div>
          <p className="title-forgot-password">Reset Password</p>
          <p className="content-forgot-password">
            Please enter the reset code sent to<br /> your phone number to reset
            your<br /> password.
          </p>
        </div>
        {this.props.error ? (
          <ErrorMessage
            error={this.props.error}
            hideMessage={() =>
              this.props.updatePassword({
                payload: { error: '', pin: '' },
              })
            }
          />
        ) : null}
        <div />
        <form>
          <Input
            title="resetCode"
            type="text"
            name="pin"
            placeholder="Reset Code"
            maxLength={8}
            value={this.props.pin}
            onChange={e => {
              this.props.updatePassword({
                payload: { pin: e.target.value },
              });
            }}
          />
          <p className="content-forgot-password">
            The reset code will be valid for 24 <br />hours.
          </p>
          <ButtonStandard title="Continue" onClick={this.submit} />
        </form>
      </div>
    );
  }
}

const mS = state => ({
  pin: state.passwordReducer.pin,
  token: state.passwordReducer.token,
  error: state.passwordReducer.error,
  username: state.passwordReducer.username,
});

const mD = {
  updatePassword,
  clearPassword,
  checkPinCode,
};

ResetCode.propTypes = ResetPasswordPropTypes;

export default connect(
  mS,
  mD,
)(ResetCode);
