export default function headers(auth = true) {
  const items = { 'Diocesan-Application': 'admin' };
  const token = localStorage.getItem('token');

  if (auth && token) {
    items.Authorization = `Bearer ${token}`;
  }

  return items;
}
