import AXIOS from '../Config/AxiosConfig';
import { GENERAL } from '../Config/env';

const baseURL = '/admin-panel/diocese';
const Parish = {
  getParishes(parishId, token, currentPage, orderBy, order) {
    return AXIOS.get(
      `${baseURL}/${parishId}/parish/page=${currentPage}&limit=${
        GENERAL.paginator_limit
      }&order=${order}&order_by=${orderBy}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Diocesan-Application': 'admin',
        },
      },
    );
  },
};
export default Parish;
