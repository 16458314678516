import React, { Component } from 'react';
import Pagination from 'react-js-pagination';
import PropTypes from 'prop-types';
import PaginationPropType from '../PaginationPropType';

class TablePagination extends Component {
  handleOnKeyPress = e => {
    e.preventDefault();
  };

  renderPages = () => {
    const { pagination } = this.props;
    const pages = [];
    for (let i = 1; i <= pagination.totalPages; i += 1) {
      const currentPage = pagination.currentPage === i ? 'page-container-active' : '';
      pages.push(
        <div
          className={`pages-container ${currentPage}`}
          onClick={() => this.handlePage(i)}
          onKeyPress={this.handleOnKeyPress}
          role="button"
          tabIndex="0"
        >
          {i}
        </div>,
      );
    }
    return pages;
  };

  /* eslint-disable */
  render() {
    const { pagination, itemsCount, limit, currentPage, onItemsPerPageChange } = this.props;
    let lower;
    let upper;
    if (limit !== 'all') {
      lower = limit * (currentPage - 1) + 1;
      upper = limit === itemsCount ? limit * currentPage : limit * (currentPage - 1) + itemsCount;
    } else {
      lower = 1;
      upper = itemsCount;
    }
    return (
      <div className="table-pagination-container">
        <div className="pagination-counter">
          {lower} - {upper} of {pagination.total}
        </div>

        <div className="quantity-per-page">
          <span
            className={`quantity-option${limit === 10 ? ' quantity-option-active' : ''}`}
            onClick={() => onItemsPerPageChange(10)}
          >
            10
          </span>
          <span className="quantity-separator">|</span>
          <span
            className={`quantity-option${limit === 25 ? ' quantity-option-active' : ''}`}
            onClick={() => onItemsPerPageChange(25)}
          >
            25
          </span>
          <span className="quantity-separator">|</span>
          <span
            className={`quantity-option${limit === 50 ? ' quantity-option-active' : ''}`}
            onClick={() => onItemsPerPageChange(50)}
          >
            50
          </span>
          <span className="quantity-separator">|</span>
          <span
            className={`quantity-option${limit === 'all' ? ' quantity-option-active' : ''}`}
            onClick={() => onItemsPerPageChange('all')}
          >
            All
          </span>
        </div>

        <Pagination
          prevPageText={<div className="paginator-arrow-left"/>}
          nextPageText={<div className="paginator-arrow-right"/>}
          activePage={currentPage}
          itemsCountPerPage={limit}
          totalItemsCount={pagination.total}
          pageRangeDisplayed={3}
          onChange={this.props.onPageChange}
          hideFirstLastPages
        />
      </div>
    );
  }
}

TablePagination.propTypes = {
  pagination: PropTypes.objectOf(PaginationPropType).isRequired,
  onChange: PropTypes.func.isRequired,
  limit: PropTypes.oneOf(['all', 10, 25, 50]).isRequired,
  currentPage: PropTypes.number.isRequired,
  itemsCount: PropTypes.number.isRequired,
  onItemsPerPageChange: PropTypes.func.isRequired,
};

export default TablePagination;
