import PropTypes from 'prop-types';

const UserFormProfilePropTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  birthdate: PropTypes.string,
  phoneNumber: PropTypes.instanceOf(Object),
  email: PropTypes.string,
  error: PropTypes.string,
  updateUserProfile: PropTypes.func,
  getUserAndGroupInformation: PropTypes.func,
  classes: PropTypes.instanceOf(Object),
  formData: PropTypes.instanceOf(Object),
  match: PropTypes.instanceOf(Object),
};

export default UserFormProfilePropTypes;
