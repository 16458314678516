import PropTypes from 'prop-types';

const SimpleModalPropType = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  open: PropTypes.bool,
  close: PropTypes.bool,
};

export default SimpleModalPropType;
