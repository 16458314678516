import burgerMenu from '../../Theme/Icons/menu.svg';

const styles = {
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    maxWidth: '1200px',
    margin: '0 auto',
    height: '60px',
    '& div.right-options': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginRight: '20px',
      '& img.admin-photo': {
        width: '35px',
        height: '35px',
        borderRadius: '50%',
      },
      '& > p.logo-admin-mobile-v2': {
        fontFamily: 'GothamSSm-Book',
        fontSize: '14px',
        margin: '0px',
        color: '#692996',
        marginRight: '20px',
        '@media only screen and (max-width: 599px)': {
          marginRight: '5px',
          marginLeft: '10px',
        },
      },
      '& button': {
        '@media only screen and (max-width: 599px)': {
          display: 'block',
          minHeight: '40px',
          minWidth: '40px',
          marginRight: '5px',
          '& img': {
            display: 'block',
            margin: '0 auto',
          }
        },
      },
    },
  },
  organizationStyles: {
    '@media only screen and (max-width: 1023px)': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      '& .back-button-header': {
        marginLeft: '20px',
        marginRight: '10px',
        backgroundColor: 'transparent',
        borderStyle: 'none',
        pointer: 'cursor',
      },
      '& .title-styles': {
        fontFamily: 'GothamSSm-Medium',
        fontSize: '16px',
        fontWeight: 500,
        fontStyle: 'normal',
        fontStrech: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#4a4a4a',
        textTransform: 'capitalize',
      },
    },
    display: 'none',
  },
  iframeAndTitle: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    '& header.header-evangelus-v2': {
      color: '#9a66bf',
      fontSize: '24px',
      fontStrech: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      backgroundColor: '#f3f3f3',
      fontFamily: 'GothamSSm-Light',
      padding: 0,
    },
    '& div.iframe-button > input#drawer-toggle-v2': {
      position: 'absolute',
      visibility: 'hidden',
    },
    '& div.iframe-button > label#drawer-toggle-label-v2': {
      height: '45px',
      width: '45px',
      position: 'inherit',
      zIndex: 1111,
      cursor: 'pointer',
      backgroundImage: `url(${burgerMenu})`,
      backgroundPosition: 'center center',
      backgroundRepeat: 'no-repeat',
      display: 'block',
    },
    '& input#drawer-toggle-v2:checked ~ label#drawer-toggle-label-v2': {
      height: '100%',
      position: 'fixed',
      left: 0,
      top: 0,
      backgroundColor: '#000000',
      opacity: 0.4,
      width: '100%',
      backgroundImage: 'none',
    },
  },
  displayOnlyOnDesktop: {
    '@media only screen and (max-width: 1023px)': {
      display: 'none',
    },
    '@media only screen and (min-width: 1024px)': {
      display: 'flex',
    },
  },
};

export default styles;
