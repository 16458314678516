import Invitation from '../Api/Invitation';
import snackBarStatus from './snackbarActions';
import { yearMonthDayFormat } from '../Helpers/date';

export const ACTIONS = {
  CREATE: 'groupInviteMemberActions/create',
  CLEAR: 'groupInviteMemberActions/clear',
  UPDATE: 'groupInviteMemberActions/update',
};

export const create = state => ({ type: ACTIONS.CREATE, payload: state });

export const update = state => ({ type: ACTIONS.UPDATE, payload: state });

export const clear = () => ({ type: ACTIONS.CLEAR });

/* eslint-disable */
export const searchMemberAction = (member, organizationId) => async dispatch => {
  const { email, phone } = member;
  const reqData = {};

  if (organizationId) {
    reqData.org = organizationId;
  }

  if (email) {
    reqData.email = email;
  }

  if (phone) {
    reqData.phone = phone;
  }
  try {
    const {
      status,
      data: { data: { data: membersList } },
    } = await Invitation.getMembersToGroupAdd(reqData);

    if (status === 200 && Array.isArray(membersList)) {
      const stepTwo = [
        member,
        { ...member, firstName: '', lastName: '', birthday: null },
        {},
      ];

      let formattedData = membersList.map(member => ({
        firstName: member.firstName,
        lastName: member.lastName,
        phone: member.mainPhone && member.mainPhone.phoneNumber ? member.mainPhone.phoneNumber : null,
        email: member.email,
        profilePictureUrl: member.profilePictureUrl || null,
        publicId: member.publicId || null,
      }));

      const stepThree = [member, {}, { members: formattedData, permissionToAdd: false }];

      dispatch(
        update({
          steps: membersList.length ? stepThree : stepTwo,
          currentStep: membersList.length ? 2 : 1,
        }),
      );
    }
  } catch (error) {
    console.log(error, 'error');
  }
};

export const submitMembersActions = (organizations, members) => async dispatch => {
  const organizationList = organizations.map(organization => ({ public_id: organization }));
  const memberList = members.map(member => {
    if ('publicId' in member) {
      return {
        public_id: member.publicId,
      };
    }
    return {
      first_Name: member.firstName && member.firstName,
      last_name: member.lastName && member.lastName,
      birth_date: member.birthday && yearMonthDayFormat(member.birthday),
      phones:
        member.phone !== ''
          ? [
              {
                description: 'main',
                country_code: '',
                phone_number: member.phone,
                is_default: true,
              },
            ]
          : [],
      status: 'active',
      user: {
        email: member.email && member.email,
        username: member.email && member.email,
        plain_password: '1234',
      },
    };
  });
  members = memberList;
  organizations = organizationList;
  const membersInfo = { organizations, members };
  try {
    const {
      status,
      data: { data },
    } = await Invitation.inviteMembers(membersInfo);
    if (status === 200) {
      snackBarStatus({
        payload: {
          title: 'Members Added',
          type: 'success',
          enable: true,
        },
      })(dispatch);
      return data;
    }
  } catch (error) {
    const { message } =
      error.response && error.response.data
        ? error.response.data
        : 'Has occurred a Server or Unknown Error. Please try later.';
    snackBarStatus({
      payload: {
        title: message,
        type: 'error',
        enable: true,
      },
    })(dispatch);
  }
};
/* eslint-enable */
