import Auth from '../Api/Auth';
import snackBarStatus, { clearSnackBarStatus } from './snackbarActions';
import { ACTIONS as navigationActions } from './navigationControllerActions';
import { EVA_FRONT_URL } from '../Config/env';

export const ACTIONS = {
  LOG_IN: 'login/log_in',
  SSO_LOG_IN: 'login/sso_log_in',
  LOG_OUT: 'login/log_out',
  UPDATE: 'login/update',
  ERROR: 'login/error',
  CLEAR: 'login/clear',
};

export function updateLogin(values) {
  return dispatch => {
    dispatch({ type: ACTIONS.UPDATE, ...values });
  };
}

export function clearLogin() {
  return dispatch => {
    dispatch({ type: ACTIONS.CLEAR });
  };
}

// export function checkRole() {
//   return dispatch =>
//     Auth.getRoles()
//       .then(response => {
//         dispatch({ type: ACTIONS.UPDATE, payload: response.data });
//         return response.data;
//       })
//       .catch(err => err.response);
// }

export const checkRole = () => async dispatch => {
  try {
    const {
      status,
      data: { data },
    } = await Auth.getRoles();
    if (status === 200) {
      dispatch({ type: ACTIONS.UPDATE, payload: data });
    }
    return data;
  } catch (error) {
    snackBarStatus({
      payload: {
        title: error.message,
        type: 'error',
        enable: true,
      },
    })(dispatch);
    return error;
  }
};

export const login = ({ username, password }) => async dispatch => {
  try {
    const response = await Auth.login(username, password);
    if (response.status === 200) {
      dispatch({ type: ACTIONS.LOG_IN, payload: response.data });
      clearSnackBarStatus()(dispatch);
    }

    return response.data.data;
  } catch (error) {
    if (error.status === 401) {
      dispatch({
        type: ACTIONS.ERROR,
        payload: {
          error: 'Email or Password are incorrect',
          message: error.message,
        },
      });
    } else {
      dispatch({ type: ACTIONS.ERROR, payload: { error: error.message } });
    }
    return error;
  }
};

export const populateLoginDataFromStorage = () => dispatch => {
  const { token, expiration } = localStorage;
  let roles;
  try {
    roles = JSON.parse(localStorage.roles);
  } catch (error) {
    roles = null;
  }
  if (token && expiration && roles) {
    dispatch({
      type: ACTIONS.UPDATE,
      payload: { token, expiration, roles },
    });
  }
};

export const loginCheckSso = (ssoToken, customRedirect = null) => async dispatch => {
  try {
    const response = await Auth.loginCheckSso(ssoToken);
    if (
      response.status === 200 &&
      response.data &&
      response.data.data &&
      response.data.data.token &&
      response.data.data.expiration &&
      response.data.data.roles &&
      response.data.data.roles instanceof Array
    ) {
      const {
        data: { token, expiration, roles },
      } = response.data;
      const adminUrl =
        roles.includes('ROLE_ADMIN') || roles.includes('ROLE_SUPER_ADMIN') ? '/main' : null;
      // It's not an admin
      if (!adminUrl) {
        window.location.replace(`${EVA_FRONT_URL}/login-check?authToken=${ssoToken}`);
      }
      // It's an admin
      localStorage.setItem('token', token);
      localStorage.setItem('expiration', expiration);
      localStorage.setItem('roles', JSON.stringify(roles));
      localStorage.setItem('sso_token', ssoToken);

      dispatch({ type: ACTIONS.SSO_LOG_IN, payload: { token, expiration, roles, ssoToken } });
      dispatch({ type: navigationActions.REDIRECT, payload: customRedirect || adminUrl });
    } else {
      throw new Error('Missing data on login response');
    }
    return response;
  } catch (error) {
    // TODO: LOGOUT on error
    if (error.status === 401) {
      dispatch({
        type: ACTIONS.ERROR,
        payload: {
          error: 'Invalid Token',
          message: error.message,
        },
      });
    } else {
      dispatch({ type: ACTIONS.ERROR, payload: { error: error.message } });
    }
    return error;
  }
};

export function destroySession(dispatch) {
  localStorage.removeItem('token');
  localStorage.removeItem('expiration');
  localStorage.removeItem('roles');
  localStorage.removeItem('sso_token');
  dispatch({ type: navigationActions.REDIRECT, payload: '/login' });
}

export const logout = () => async dispatch => {
  try {
    const response = await Auth.logout();
    if (response.status === 200) {
      dispatch({ type: ACTIONS.LOG_OUT });
      destroySession(dispatch);
    }
    return response;
  } catch (error) {
    destroySession(dispatch);
    if (error.response) {
      dispatch({
        type: ACTIONS.ERROR,
        payload: { error: error.response.data.message },
      });
    } else {
      dispatch({
        type: ACTIONS.ERROR,
        payload: { error: error.message },
      });
    }
    return error;
  }
};
