import { EmailSimple, ValidEmailPhone } from './email';
import PhoneSimple from './phone';
import { PasswordCheck, ValidatePassword } from './password';
import { validDate, getYears, addDays } from './date';
import escapeCharacters from './characters';
import validateUrl from './url';
import token from './token';
import { copyToClipboard } from './utiliy';

export default {
  EmailSimple,
  PhoneSimple,
  ValidEmailPhone,
  PasswordCheck,
  ValidatePassword,
  validDate,
  getYears,
  escapeCharacters,
  addDays,
  validateUrl,
  token,
  copyToClipboard,
};
