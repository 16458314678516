import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import ButtonStandard from '../Button';
import WarningMessage from '../WarningMessage';
import UserFormProfilePropType from '../../PropTypes/UserFormProfilePropType';
import {
  getUserAndGroupInformation,
  updateUserProfile,
  updateData,
  getCountries,
} from '../../Actions/userFormProfileActions';
import {
  togglePrivacyPolicyCheck,
} from '../../Actions/privacyPolicyActions';
import { openSimpleModal } from '../../Actions/simpleModalActions';
import './index.sass';
import GridForm from './GridForm';
import SimpleModal from '../SimpleModal';
import Helpers from '../../Helpers';
import DummyContent from './contentPrivacyPolicy';

class UserFormProfile extends Component {
  state = {
    lowercase: false,
    number: false,
    specialCharacter: false,
    uppercase: false,
    length: false,
    showWarningMessage: false,
    showError: false,
    showMessage: false,
    message: '',
  };

  componentWillMount() {
    const { groupId, token } = this.props.match.params;
    this.props.getUserAndGroupInformation({
      groupId,
      token,
    });
    this.props.getCountries();
  }

  componentDidMount() {
    this.verifyIfWasAdded();
  }

  componentWillReceiveProps(newProps) {
    if (newProps.code === 200 && !localStorage.getItem('token')) {
      this.props.history.push('/login');
    } else if (newProps.code === 200 && localStorage.getItem('token')) {
      this.props.history.push('/unauthorized');
    }
  }

  verifyIfWasAdded = () => {
    if (this.props.previousToken === this.props.token && this.props.saved) {
      this.props.history.push('/unauthorized');
    }
  };

  handleChangePassword = (e, target) => {
    const { value } = e.target;
    const keys = Object.keys(this.state);
    keys.forEach(term => {
      const newState = {};
      newState[term] = Helpers.PasswordCheck(term, value);
      this.setState({ ...newState });
    });
    const payload = {};
    payload[target] = value;
    this.props.updateData({ payload });
  };

  handleChange = (e, attr) => {
    const payload = {};
    payload[`${attr}`] = e.target.value;
    this.props.updateData({ payload });
  };

  handleChangeBirthday = value => {
    this.props.updateData({ payload: { birthDate: value } });
  };

  handleSelectChange = e => {
    const selectedCountry = this.props.country.find(
      x => x.public_id === e.target.value,
    );
    this.props.updateData({ payload: { selectedCountry } });
  };

  requestedParams = target => {
    if (this.state[target]) return 'active';
    return null;
  };

  parseToValue = country =>
    country.map(c => ({
      value: c.public_id,
      label: c.name,
    }));

  handlePrivacyPolicyDialog = event => {
    event.preventDefault();
    this.props.openSimpleModal({ payload: { open: true } });
  };

  handleCloseModal = () => {
    this.props.openSimpleModal({ payload: { open: false } });
  };

  handlePrivacyPolicyCheck = () => {
    this.props.togglePrivacyPolicyCheck({
      payload: { toggle: !this.props.checkPrivacyPolicyStatus },
    });
  };

  handleCloseErrorMessage = () => {
    this.setState({ showWarningMessage: false });
  };

  submitUpdateProfile = async e => {
    e.preventDefault();
    const {
      firstName,
      lastName,
      birthDate,
      phoneNumber,
      email,
      password,
      street,
      city,
      province,
      privacyPolicyPublicId,
    } = this.props;
    const phone = phoneNumber;
    const pass = password;
    const countryPublicId = this.props.selectedCountry.public_id;
    const { groupId } = this.props.match.params;
    const { token } = this.props.match.params;
    const { checkPrivacyPolicyStatus } = this.props;
    if (checkPrivacyPolicyStatus) {
      if (Helpers.ValidatePassword({ ...this.state, password, pass })) {
        if (email) {
          if (true) {
            await this.props.updateUserProfile({
              firstName,
              lastName,
              birthDate,
              phone,
              email,
              countryPublicId,
              city,
              province,
              street,
              password,
              groupId,
              token,
              privacyPolicyPublicId,
            });
          }
        } else if (phone) {
          if (Helpers.PhoneSimple) {
            await this.props.updateUserProfile({
              firstName,
              lastName,
              birthDate,
              phone,
              email,
              countryPublicId,
              city,
              province,
              street,
              password,
              groupId,
              token,
              privacyPolicyPublicId,
            });
          }
        } else {
          this.props.updateData({
            payload: {
              error: 'Phone and Email must has a value.',
            },
          });
        }
      } else {
        this.props.updateData({
          payload: {
            error: "Passwords doesn't have required character or doesn't match",
          },
        });
      }
    } else {
      this.setState({
        showWarningMessage: true,
        message:
          'You need to accept the privacy policy to complete your profile successfully.',
      });
    }
  };

  render() {
    const formTitle = 'EVANGELUS';
    return (
      <Grid container spacing={0} className="main-content">
        <Grid item xs={12} className="title description-section-content">
          {formTitle}
        </Grid>
        <Grid
          item
          xs={12}
          md={8}
          sm={12}
          className="content description-section"
        >
          <p>
            Complete your profile to manage
            <br />communications from {this.props.groupName}
            <br />and join other groups.
          </p>
        </Grid>
        <Grid item xs={12} md={8} sm={12} id="warning-block">
          {this.state.showWarningMessage && (
            <div id="warning-message">
              <WarningMessage
                warning={this.state.message}
                hideMessage={this.handleCloseErrorMessage}
              />
            </div>
          )}
        </Grid>
        <Grid item xs={12} className="main-form">
          <form action="">
            <GridForm
              formData={this.props}
              handleChange={this.handleChange}
              handleChangePassword={this.handleChangePassword}
              handleSelectChange={this.handleSelectChange}
              parseToValue={this.parseToValue}
              handleChangeBirthday={this.handleChangeBirthday}
            />{' '}
            {/* Form Block */}
          </form>
        </Grid>
        <Grid
          item
          xs={12}
          className="title password-content-block password-content"
        >
          <div className="password-requires">
            <span className="title-medium">
              Your password requires at least:
            </span>
            <ul className="list-password-requires">
              <li className={this.requestedParams('lowercase')}>
                One lowercase character
              </li>
              <li className={this.requestedParams('uppercase')}>
                One uppercase character
              </li>
              <li className={this.requestedParams('number')}>One number</li>
              <li className={this.requestedParams('specialCharacter')}>
                One special character
              </li>
              <li className={this.requestedParams('length')}>
                Minimum 8 character length
              </li>
            </ul>
          </div>
        </Grid>
        <div className="main-contacted mobile-contacted">
          <Grid item xs={12} md={8} className="contacted-content-block">
            <div className="title-contacted mobile-contacted">
              How would you like <br />to be contacted?
            </div>
          </Grid>
        </div>
        <Grid
          item
          xs={12}
          sm={8}
          md={7}
          lg={6}
          className="contacted-content-block desktop-contacted"
        >
          <div className="title-contacted">
            How would you like to be contacted?
          </div>
        </Grid>
        <Grid md={1} />
        <Grid item xs={6} sm={1} md={1} className="main-contacted icons-phone">
          <i className="phone-icon" />
          <p className="title-icon">Texting</p>
        </Grid>
        <Grid item xs={6} sm={1} md={1} className="main-contacted icons-env">
          <i className="envelope-icon" />
          <p className="title-icon envelope-landscape">Email</p>
        </Grid>
        <div className="main-check check-spacing">
          <Grid item xs={12} className="check-content-block">
            <div className="section-privacy-policy">
              <div className="text-section">
                <div className="left-text">
                  <Checkbox
                    className="check-section"
                    iconStyle={{ fill: 'yellow' }}
                    labelStyle={{ color: 'white' }}
                    color="default"
                    onChange={this.handlePrivacyPolicyCheck}
                  />
                  I accept the{' '}
                  <u
                    className="underline-text privacy-policy-link"
                    key="privacyPolicy"
                    role="button"
                    tabIndex={0}
                    onKeyDown={event => this.handlePrivacyPolicyDialog(event)}
                    onKeyUp={event => this.handlePrivacyPolicyDialog(event)}
                    onClick={event => this.handlePrivacyPolicyDialog(event)}
                  >
                    Privacy Policy
                  </u>
                  <SimpleModal
                    title="Privacy Policy"
                    content={this.props.privacyPolicyContent}
                    open={this.props.openModal}
                    handleClose={this.handleCloseModal}
                    scroll="paper"
                  />
                </div>
              </div>
            </div>
          </Grid>
        </div>
        <Grid item xs={12} className="submit-btn">
          <ButtonStandard title="Create" onClick={this.submitUpdateProfile} />
        </Grid>
      </Grid>
    );
  }
}

const mS = state => ({
  firstName: state.userFormProfileReducer.firstName,
  lastName: state.userFormProfileReducer.lastName,
  birthDate: state.userFormProfileReducer.birthDate,
  phoneNumber: state.userFormProfileReducer.phoneNumber
    ? state.userFormProfileReducer.phoneNumber.phone_number ||
      state.userFormProfileReducer.phoneNumber
    : state.userFormProfileReducer.phoneNumber.phone_number ||
      state.userFormProfileReducer.phoneNumber,
  email: state.userFormProfileReducer.email,
  password: state.userFormProfileReducer.password,
  groupName: state.userFormProfileReducer.groupName,
  street: state.userFormProfileReducer.street,
  city: state.userFormProfileReducer.city,
  selectedCountry: state.userFormProfileReducer.selectedCountry,
  province: state.userFormProfileReducer.province,
  country: state.userFormProfileReducer.country
    ? state.userFormProfileReducer.country
    : state.userFormProfileReducer.country,
  postalCode: state.userFormProfileReducer.postalCode,
  error: state.userFormProfileReducer.error,
  token: state.userFormProfileReducer.token,
  code: state.userFormProfileReducer.code,
  saved: state.userFormProfileReducer.saved
    ? state.userFormProfileReducer.saved
    : '',
  previousToken: state.userFormProfileReducer.previousToken
    ? state.userFormProfileReducer.previousToken
    : '',
  openModal: state.simpleModalReducer.open,
  checkPrivacyPolicyStatus: state.privacyPolicyReducer.toggle,
  privacyPolicyContent:
    state.privacyPolicyReducer.content !== ''
      ? state.privacyPolicyReducer.content
      : DummyContent.text,
  privacyPolicyPublicId: state.privacyPolicyReducer.public_id,
});

const mD = {
  getUserAndGroupInformation,
  updateUserProfile,
  updateData,
  getCountries,
  openSimpleModal,
  togglePrivacyPolicyCheck,
};

UserFormProfile.propTypes = UserFormProfilePropType;
export default withRouter(
  connect(
    mS,
    mD,
  )(UserFormProfile),
);
