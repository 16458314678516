import React from 'react';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import images from '../../Theme/Images';
import Input from './CustomInput';
import EvaButton from '../EvaButton';

const SearchMember = ({
  classes,
  handleChange,
  email,
  phoneNumberSearch,
  findAdmins: findMembers,
  buildError,
  validEmail = false,
}) => (
  <Grid container spacing={0} className="new-super-admin-form distance-superadmin" justify="center">
    <Grid item xs={10} sm={10} alignItems="center" className={classes.textJustification}>
      <div className={classes.instructions}>
        <p className={classes.textStyles}>Enter an email address bellow.</p>
      </div>
    </Grid>
    <Grid item xs={10} sm={10} alignItems="center" className={classes.inputCentering}>
      <Input
        type="text"
        name="email"
        placeholder="Email"
        value={email}
        onChange={handleChange}
        icon="email"
        checkVisible={validEmail}
      />
    </Grid>
    {buildError('email')}
    <Grid item xs={6} sm={6} alignItems="center" className={classes.flexedButtonContainer}>
      {/* <button className={classes.buttonClass}
          onClick={this.findAdmins}>Continue</button> */}
      <EvaButton
        onClick={findMembers}
        active={Boolean(email || phoneNumberSearch)}
        className={`${classes.buttonClass160Width} ${
          email || phoneNumberSearch ? classes.buttonClass : classes.buttonClassInactive}`
        }
      >
        <span className={classes.spanTextDisplay}>Continue</span>
      </EvaButton>
    </Grid>
    <Grid item xs={8} sm={8} alignItems="center" className={classes.textJustification}>
      <img src={images.Wings} alt="Evangelus" className={classes.evaneglusWings} />
    </Grid>
  </Grid>
);

SearchMember.propTypes = {
  handleChange: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  phoneNumberSearch: PropTypes.string.isRequired,
  classes: PropTypes.instanceOf(Object).isRequired,
  findAdmins: PropTypes.func.isRequired,
  buildError: PropTypes.func.isRequired,
  validEmail: PropTypes.bool.isRequired,
};

export default SearchMember;
