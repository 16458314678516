import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import purple from '@material-ui/core/colors/purple';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import TablePagination from './TablePagination';
import PaginationPropType from '../PaginationPropType';
import ViewInEvangelusButton from '../../ViewInEvangelusButton';
import './index.sass';

const styles = theme => ({
  progress: {
    margin: theme.spacing.unit * 2,
    textAlign: 'center',
  },
});

class DataTable extends React.Component {
  renderProgress = () => {
    const { classes } = this.props;
    return (
      <CircularProgress className={classes.progress} style={{ color: purple[500] }} thickness={7}/>
    );
  };

  getSortClassByName = name => this.props.sort === name ? `sort-direction-${this.props.sortOrder}` : '';

  render() {
    const { organizations, memberId, onSortChange } = this.props;

    return (
      <div className="organizations-list">
        <div className="pagination-container">
          <table className="diocesan-table">
            <tr>
              <th
                onClick={() => onSortChange('name')}
                className={`sort-column ${this.getSortClassByName('name')}`}
              >
                Name
                <span>
                  <i className="sort-icon"/>
                </span>
              </th>

              <th
                onClick={() => onSortChange('location')}
                className={`sort-column ${this.getSortClassByName('location')} no-right-border`}
              >
                Location
                <span>
                  <i className="sort-icon"/>
                </span>
              </th>

              <th
                style={{ width: '150px' }}
                className="disable"
              >
                Parent Organization
              </th>

              <th
                style={{ width: '150px' }}
                className="disable"
              >
                Edition/ Status
              </th>

              <th
                colSpan={2}
                onClick={() => onSortChange('lastSent')}
                style={{ width: '150px' }}
                className={`sort-column ${this.getSortClassByName('lastSent')} disable`}
              >
                Last Sent
                <span>
                  <i className="sort-icon"/>
                </span> /
                Recent&nbsp;Messages
              </th>
            </tr>

            {!this.props.loading && (
              organizations.map(organization => (
                <tr
                  key={organization.public_id}
                  className={`tr-body-list organization-state-${organization.status}`}
                >
                  {/* Name Field */}
                  <td className="table-field-name" onClick={() => this.props.onSelect(organization.publicId)}>
                    {organization.name}
                  </td>

                  {/* Location Field */}
                  <td className="table-field-location no-right-border" onClick={() => this.props.onSelect(organization.publicId)}>
                    {organization.address && (
                      <React.Fragment>
                        {
                          organization.address.referenceAddress
                            ? `${organization.address.referenceAddress} `
                            : ''
                        }

                        {
                          organization.address.cityText ?
                            `${organization.address.cityText} ` : ''
                        }

                        {
                          organization.address.stateText ?
                            `${organization.address.stateText} ` : ''
                        }

                        {
                          organization.address.postalCode ?
                            `${organization.address.postalCode} ` : ''
                        }

                      </React.Fragment>
                    )}
                  </td>

                  {/* Parent org Field */}
                  <td className="table-field-parent-org disable" onClick={() => this.props.onSelect(organization.publicId)}>
                    {organization.organizationParent ? organization.organizationParent.name : ''}
                  </td>

                  {/* Edition / Status Field */}
                  <td className="table-field-parent-org disable" onClick={() => this.props.onSelect(organization.publicId)}>
                    {/* {organization.organizationParent ? organization.organizationParent.name : ''} */}
                   {organization.lite ? 'Pub Ed' : 'Eva'}
                   <div className="organization-status">{organization.status}</div>
                  </td>

                  {/* Last Sent / Recent Messages Field */}
                  <td
                    className={`disable table-field-member-since ${
                      organization.status !== 'active' ? 'status-title' : ''
                      }`}
                    onClick={() => this.props.onSelect(organization.publicId)}
                  >
                    {organization.status === 'active' && organization.lastSent
                      ? moment(organization.lastSent).fromNow(true)
                      : organization.status}
                      <br/>
                      {organization.messageCount}
                  </td>
                  <td className="action-cell">
                    <ViewInEvangelusButton
                      id={organization.id}
                      orgId={organization.publicId}
                      memberId={memberId}
                    />
                  </td>
                </tr>
              ))
            )}

            {this.props.loading && (
              <tr>
                <td colSpan={6} style={{ textAlign: 'center' }}>
                  {this.renderProgress()}
                </td>
              </tr>
            )}
          </table>
        </div>

        <TablePagination
          itemsCount={organizations.length}
          limit={this.props.limit}
          currentPage={this.props.currentPage}
          pagination={this.props.pagination}
          onPageChange={this.props.onPageChange}
          onItemsPerPageChange={this.props.onItemsPerPageChange}
        />
      </div>
    );
  }
}

DataTable.propTypes = {
  sort: PropTypes.oneOf(['name', 'location', 'lastSent', 'messageCount']),
  sortOrder: PropTypes.oneOf(['asc', 'desc']),
  organizations: PropTypes.isRequired,
  pagination: PropTypes.objectOf(PaginationPropType).isRequired,
  currentPage: PropTypes.number.isRequired,
  limit: PropTypes.oneOf(['all', 10, 25, 50]).isRequired,
  memberId: PropTypes.string.isRequired,
  classes: PropTypes.isRequired,
  loading: PropTypes.isRequired,
  onSelect: PropTypes.func.isRequired,
  onSortChange: PropTypes.func.isRequired,
  onPageChange: PropTypes.func.isRequired,
  onItemsPerPageChange: PropTypes.func.isRequired,
};

export default withStyles(styles)(DataTable);
