import React from 'react';
import './index.sass';
import logo from './ripple-loading.svg';

const LoadingScreen = () => (
  <div className="container-center login">
    <div className="login__title">
      <p className="login__welcome">Loading data...</p>
      <p className="logo-evangelus">EVANGELUS</p>
    </div>
    <img src={logo} className="login-load-logo" alt="Eva" />
  </div>
);

export default LoadingScreen;
