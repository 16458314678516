import React, { Component } from 'react';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import Hidden from '@material-ui/core/Hidden';
import ClearIcon from '@material-ui/icons/Clear';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import colors from '../../Theme/Colors';
import { enableRightDrawer } from '../../Actions/rightDrawerActions';
import RightDrawerPropType from '../../PropTypes/RightDrawerPropType';
import './index.sass';

const drawerWidth = window.innerWidth > 768 ? 528 : window.innerWidth;

const styles = theme => ({
  [theme.breakpoints.only('sm')]: {
    drawerPaper: {
      width: `${window.innerWidth}px !important`,
      height: `${window.innerHeight}px !important`,
    },
  },
  drawerPaper: {
    position: 'fixed',
    top: 0,
    bottom: 0,
    height: 'fit-content',
    display: 'block',
    width: '528px',
    zIndex: '1250',
    minHeight: '100vh',
    boxShadow: 'none',
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-left',
    padding: '0 30px',
    ...theme.mixins.toolbar,
    minHeight: 40,
  },
  drawerTitle: {
    fontFamily: 'GothamSSm-Medium',
    fontSize: '20px',
    color: colors.graytext,
    width: 'calc(100% - 50px)',
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing.unit * 3,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  'content-left': {
    marginLeft: -drawerWidth,
  },
  'content-right': {
    marginRight: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  'contentShift-left': {
    marginLeft: 0,
  },
  'contentShift-right': {
    marginRight: 0,
  },
  drawerHeaderCustom: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-left',
    padding: '0 8px',
    ...theme.mixins.toolbar,
    minHeight: 40,
  },
  [theme.breakpoints.between('xs', 'sm')]: {
    drawerHeaderCustom: {
      background: '#f3f3f3',
      margin: '0',
      minHeight: 60,
    },
    closeButton: {
      background: 'none',
      border: 'none',
      padding: 0,
      font: 'inherit',
      outline: 'inherit',
      cursor: 'pointer',
      color: '#9a66bf',
      margin: '0 15px 0 auto',
    },
  },
  '@media (max-width: 768px)': {
    drawerPaper: {
      width: '100vw !important',
      marginTop: '0',
    },
    drawerHeader: {
      padding: '0 8px',
    },
  },
});

class RightDrawer extends Component {
  state = {
    side: 'right',
  };

  handleDrawerClose = () => {
    if (this.props.cleaner) {
      this.props.cleaner();
    }
    this.props.enableRightDrawer({
      payload: {
        close: true,
        enable: false,
        deviceCustom: false,
      },
    });
    if (window.innerWidth >= 1024) {
      document.querySelector('.right-drawer.drawer-standard > .drawer-md-up').style.display =
        'none';
    }
  };

  handleChangeAnchor = event => {
    this.setState({
      side: event.target.value,
    });
  };

  render() {
    const { classes, element, status, title, deviceCustom, close } = this.props;
    const { side } = this.state;

    return (
      <div className="right-drawer drawer-standard">
        <Hidden mdUp>
          <Drawer
            variant="temporary"
            anchor={side}
            open={status}
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <div className={deviceCustom ? classes.drawerHeaderCustom : classes.drawerHeader}>
              {!deviceCustom && (
                <IconButton onClick={this.handleDrawerClose}>
                  <ClearIcon />
                </IconButton>
              )}
              {deviceCustom && (
                <button className="go-back-image" onClick={this.handleDrawerClose} />
              )}
              <div className={classes.drawerTitle}>{title}</div>
              {deviceCustom && (
                <button className={classes.closeButton} onClick={this.handleDrawerClose}>
                  Close
                </button>
              )}
            </div>
            <div className="right-drawer-content">{element}</div>
          </Drawer>
        </Hidden>
        <Hidden smDown>
          <div className="drawer-md-up">
            <Drawer
              anchor={side}
              open={status}
              classes={{
                paper: classes.drawerPaper,
              }}
            >
              <div className={classes.drawerHeader}>
                {close === false ? (
                  ''
                ) : (
                  <IconButton onClick={this.handleDrawerClose}>
                    <ClearIcon />
                  </IconButton>
                )}
                <div className={classes.drawerTitle}>{title}</div>
              </div>
              <div className="right-drawer-content">{element}</div>
            </Drawer>
          </div>
        </Hidden>
      </div>
    );
  }
}

const mS = state => ({
  status: state.rightDrawerReducer.enable,
  element: state.rightDrawerReducer.element,
  title: state.rightDrawerReducer.title,
  cleaner: state.rightDrawerReducer.cleaner,
  deviceCustom: state.rightDrawerReducer.deviceCustom,
  close: state.rightDrawerReducer.close,
});

const mD = {
  enableRightDrawer,
};

RightDrawer.propTypes = RightDrawerPropType;

export default connect(
  mS,
  mD,
)(withStyles(styles, { withTheme: true })(RightDrawer));
