import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import styles from './styles';
import backIcon from '../../Theme/Icons/back.svg'
import { DefaulTitlebar } from '.';
/**
 *
 * Default left side of the title bar.
 * @param {Object}    props
 * @param {Function} backButtonAction Activates the action to go back to organizations
 */
export const OrganizationBar = ({
  classes,
  history,
  ...props
}) => {
  const tabletsView = (
    <div className={classes.organizationStyles}>
      <button className="back-button-header" onClick={() => history.goBack()}>
        <img src={backIcon} alt="Go Back" />
      </button>
      <span className="title-styles">Organization</span>
    </div>
  );
  const desktopView = (
    <div className={classes.displayOnlyOnDesktop}>
      <DefaulTitlebar {...props} />
    </div>
  );
  return (
    <React.Fragment>
      {tabletsView}
      {desktopView}
    </React.Fragment>
  )
}


export default withRouter(withStyles(styles)(OrganizationBar));
