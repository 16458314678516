import Notifications from '../Api/Notifications';
import snackBarStatus from './snackbarActions';
import findMessage from '../Helpers/utiliy';

export const ACTIONS = {
  GET_NOTIFICATIONS: 'settings/get_notifications',
  DISMISS_NOTIFICATIONS: 'settings/dismiss_notifications',
  ACCEPT_JOIN_REQUEST: 'settings/accept_join',
};

export const getNotificationsMessages = () => {
  const token = localStorage.getItem('token');
  return async dispatch => {
    try {
      const {
        data: { code, data },
      } = await Notifications.getNotifications(token);
      if (code >= 300) {
        throw new Error('Notifications not found');
      }

      const {
        joinRequestsNotifications,
        memberJoinedGroupNotifications,
        replyMessageNotifications,
      } = data;
      const joinRequestAmount = data.joinRequestsNotifications.length;
      const memberJoinedGroupAmount = memberJoinedGroupNotifications.length;
      const replyMessageAmount = replyMessageNotifications.length;

      dispatch({
        type: ACTIONS.GET_NOTIFICATIONS,
        payload: {
          joinRequestsNotifications,
          memberJoinedGroupNotifications,
          replyMessageNotifications,
          joinRequestAmount,
          memberJoinedGroupAmount,
          replyMessageAmount,
        },
      });
    } catch (error) {
      snackBarStatus({
        payload: {
          title: findMessage(error),
          type: 'error',
          enable: true,
        },
      })(dispatch);
    }
  };
};

export const dismissNotifications = notificationId => {
  const token = localStorage.getItem('token');
  const notifications = { notifications: [notificationId] };
  return async dispatch => {
    try {
      const { data } = await Notifications.dismissNotification(token, notifications);
      if (data.code === 0) {
        dispatch({
          type: ACTIONS.DISMISS_NOTIFICATIONS,
          payload: data,
        });
      } else {
        throw new Error('Could not dismiss notifications');
      }
    } catch (error) {
      snackBarStatus({
        payload: {
          title: findMessage(error),
          type: 'error',
          enable: true,
        },
      })(dispatch);
    }
  };
};

export const acceptJoinRequest = data => {
  const token = localStorage.getItem('token');
  return async dispatch => {
    try {
      const {
        data: { code },
      } = await Notifications.acceptJoinRequest(token, data);
      if (code === 0) {
        dispatch({
          type: ACTIONS.ACCEPT_JOIN_REQUEST,
        });
      } else {
        throw new Error('Could not accept join request');
      }
    } catch (error) {
      snackBarStatus({
        payload: {
          title: findMessage(error),
          type: 'error',
          enable: true,
        },
      })(dispatch);
    }
  };
};

export const denyJoinRequest = data => {
  const token = localStorage.getItem('token');
  return async dispatch => {
    try {
      const {
        data: { code },
      } = await Notifications.denyJoinRequest(token, data);
      if (code === 0) {
        dispatch({
          type: ACTIONS.ACCEPT_JOIN_REQUEST,
        });
      } else {
        throw new Error('Could not deny join request');
      }
    } catch (error) {
      snackBarStatus({
        payload: {
          title: findMessage(error),
          type: 'error',
          enable: true,
        },
      })(dispatch);
    }
  };
};
