export const PasswordCheck = (option, text) => {
  switch (option) {
    case 'lowercase':
      return /^(?=.*[a-z]).+$/.test(text);
    case 'number':
      return /^(?=.*\d).+$/.test(text);
    case 'specialCharacter':
      return /^(?=.*[_\W]).+$/.test(text);
    case 'uppercase':
      return /^(?=.*[A-Z]).+$/.test(text);
    case 'length':
      return text.length > 8;
    case 'focus':
      return 'input-eva-focused';
    default:
      return false;
  }
};

export const ValidatePassword = ({
  lowercase,
  number,
  specialCharacter,
  uppercase,
  length,
  first,
  second,
}) =>
  lowercase &&
  number &&
  specialCharacter &&
  uppercase &&
  length &&
  first !== '' &&
  first === second;
