import React from 'react';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import { withStyles } from '@material-ui/core/styles';
import PhoneInputPropType from '../../PropTypes/PhoneInputPropType';
import { styles } from './PhoneInputStyles';
import phoneIcon from '../../Theme/Icons/phone.svg';

const InputPhoneV2 = ({
  onInputPhoneChange,
  name,
  placeholder = '',
  value = '',
  classes,
  style,
  valid = false,
}) => (
  <div className={classes.root} style={style}>
    <div className="icon">
      <img src={phoneIcon} alt="phone input" />
    </div>
    <div className="input">
      <PhoneInput
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={onInputPhoneChange}
        country="US"
        limitMaxLength
        autoComplete="off"
      />
    </div>
    <div className={`valid-phone ${!valid ? 'valid-phone-hidden' : ''}`} />
  </div>
);

const InputPhone = withStyles(styles)(InputPhoneV2);

InputPhoneV2.propTypes = PhoneInputPropType;

export default InputPhone;
