import Auth from '../Api/Auth';
import findMessage from '../Helpers/utiliy';

export const ACTIONS = {
  CLEAR: 'password/clear',
  UPDATE: 'password/update',
  RESET: 'password/reset',
  REQUEST: 'password/request',
  ERROR: 'password/error',
};

export const resetPassword = (token, plainPassword, phone = null) => dispatch =>
  Auth.resetPassword(token, plainPassword, phone)
    .then(response => {
      const patcher = { type: ACTIONS.RESET, payload: response.data };
      return dispatch(patcher);
    })
    .catch(err => {
      dispatch({
        type: ACTIONS.ERROR,
        payload: { error: err.response && err.response.data && err.response.data.code === 401 ? 'Email or Password are incorrect' : findMessage(err) },
      });
    });

export const requestPassword = payload => dispatch =>
  Auth.requestPassword(payload)
    .then(response => {
      const patcher = { type: ACTIONS.REQUEST, payload: response.data };
      return dispatch(patcher);
    })
    .catch(err => {
      dispatch({
        type: ACTIONS.ERROR,
        payload: { error: err.response && err.response.data && err.response.data.code === 401 ? 'Unauthorized' : findMessage(err) },
      });
    });

export function updatePassword(values) {
  return dispatch => {
    dispatch({ type: ACTIONS.UPDATE, ...values });
  };
}

export function clearPassword() {
  return dispatch => {
    dispatch({ type: ACTIONS.CLEAR });
  };
}

export function checkPinCode(phone, token) {
  return dispatch =>
    Auth.checkPin(phone, token)
      .then(response => {
        dispatch({ type: ACTIONS.UPDATE, payload: { checked: true } });
        return response;
      })
      .catch(err => {
        dispatch({
          type: ACTIONS.ERROR,
          checked: false,
          payload: { error: findMessage(err) },
        });
      });
}
