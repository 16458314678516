import React from 'react';
import MainContainerPropType from '../../PropTypes/MainContainerPropType';
import NavigationController from '../../Components/NavigationController';
import './index.sass';

const Main = ({ children }) => (
  <div
    className="main-eva"
  >
    <NavigationController />
    {children}
  </div>
);

Main.propTypes = MainContainerPropType;
export default Main;
