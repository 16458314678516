const groupsObject = [
  {
    value: '1',
    label: 'Group 1',
  },
  {
    value: '2',
    label: 'Group 2',
  },
  {
    value: '3',
    label: 'Group 3',
  },
];

export default groupsObject;
