export const registerButton = {
  fontSize: '16px',
  marginTop: '37px',
  fontFamily: 'GothamSSm-Book',
  color: '#9a66bf',
  background: 'none',
  border: 'none',
  padding: 0,
  cursor: 'pointer',
  outline: 'inherit',
  width: '100%',
};

export const promotionText = {
  fontFamily: 'GothamSSm-Book',
  fontSize: '16px',
  textAlign: 'center',
  color: '#a9a9a9',
  margin: 0,
};

export const registerText = {
  fontFamily: 'GothamSSm-Book',
  fontSize: '13px',
  textAlign: 'center',
  color: '#a9a9a9',
  margin: 0,
};

export const button = {
  fontSize: '16px',
  color: '#9a66bf',
  fontFamily: 'GothamSSm-Bold',
  background: 'none',
  border: 'none',
  padding: 0,
  cursor: 'pointer',
  outline: 'inherit',
  width: '100%',
};
