import Profile from '../Api/CompleteProfile';
import findMessage from '../Helpers/utiliy';

export const ACTIONS = {
  GET: 'userFormProfile/getUserInformation',
  UPDATE: 'userFormProfile/updateUserInformation',
  UPDATE_DATA: 'userFromProfile/updateData',
  GET_COUNTRIES: 'userFormProfile/getCountries',
  CLEAR: 'userFormProfile/clear',
  ERROR: 'userFormProfile/error',
  RESET: 'userFormProfile/reset',
  SET_TOKEN: 'userFormProfile/setToken',
  SET_OLD_TOKEN: 'userFormProfile/setOldToken',
  SET_SAVED: 'userFormProfile/saved',
};

const UNAUTHORIZED = 401;
const UNAUTHORIZED_MESSAGE = 'Incorrect data';

export function getUserAndGroupInformation({ groupId, token }) {
  return dispatch => {
    Profile.getUserAndGroupInformation(groupId, token)
      .then(response => {
        dispatch({ type: ACTIONS.GET, payload: response.data.data });
        dispatch({ type: ACTIONS.SET_TOKEN, payload: token });
      })
      .catch(err => {
        dispatch({
          type: ACTIONS.ERROR,
          payload: {
            error:
              err.response && err.response.data && err.response.data.code === UNAUTHORIZED
                ? UNAUTHORIZED_MESSAGE
                : findMessage(err),
          },
        });
      });
  };
}

export function getCountries() {
  return dispatch => {
    Profile.getCountries()
      .then(response => {
        const country = [response.data.data.find(x => x.iso_code_alpha3 === 'USA')];
        dispatch({ type: ACTIONS.GET_COUNTRIES, payload: country });
      })
      .catch(err => {
        dispatch({
          type: ACTIONS.ERROR,
          payload: {
            error:
              err.response && err.response.data && err.response.data.code === UNAUTHORIZED
                ? UNAUTHORIZED_MESSAGE
                : findMessage(err),
          },
        });
      });
  };
}

export function updateUserProfile({
  firstName,
  lastName,
  birthDate,
  phone,
  email,
  countryPublicId,
  city,
  province,
  street,
  password,
  groupId,
  token,
  privacyPolicyPublicId,
}) {
  return dispatch => {
    Profile.updateUserProfile(
      firstName,
      lastName,
      birthDate,
      phone,
      email,
      countryPublicId,
      city,
      province,
      street,
      password,
      groupId,
      token,
      privacyPolicyPublicId,
    )
      .then(response => {
        dispatch({ type: ACTIONS.UPDATE, payload: response.data });
        dispatch({ type: ACTIONS.SET_SAVED, payload: true });
        dispatch({ type: ACTIONS.SET_OLD_TOKEN, payload: token });
      })
      .catch(err => {
        dispatch({
          type: ACTIONS.ERROR,
          payload: {
            error:
              err.response && err.response.data && err.response.data.code === UNAUTHORIZED
                ? UNAUTHORIZED_MESSAGE
                : findMessage(err),
          },
        });
      });
  };
}

export function updateData(values) {
  return dispatch => {
    dispatch({ type: ACTIONS.UPDATE_DATA, ...values });
  };
}

export function setProfileLocation(location) {
  return dispatch => {
    dispatch({
      type: ACTIONS.SET_PROFILE_LOCATION,
      ...location,
    });
  };
}
