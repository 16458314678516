import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import MainMenu from '../../Components/MainMenu';
import { enableTopDrawer } from '../../Actions/topDrawerActions';
import SuperAdminsComponent from '../../Components/SuperAdmins';

class SuperAdmin extends React.Component {
  constructor(props) {
    super(props);
    this.props.enableTopDrawer(false);
  }

  render() {
    return (
      <MainMenu
        value={false}
        content={
          <div>
            <SuperAdminsComponent />
          </div>
        }
      />
    );
  }
}

SuperAdmin.propTypes = {
  enableTopDrawer: PropTypes.func.isRequired,
};

const mS = state => ({
  statusTopDrawer: state.topDrawerReducer.statusTopDrawer,
});

const mD = {
  enableTopDrawer,
};

export default withRouter(
  connect(
    mS,
    mD,
  )(SuperAdmin),
);
