import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import {
  navigationControllerRedirect,
  __NavigationControllerState,
} from '../../Actions/navigationControllerActions';
import NavigationControllerPropTypes from '../../PropTypes/NavigationControllerPropType';

class NavigationController extends Component {
  state = {
    redirect: false,
    url: null,
    toUpdate: false,
  };

  render() {
    if (this.props.redirect) {
      this.setState({ redirect: true, url: this.props.goToURL, toUpdate: true });

      /* eslint-disable */
      this.props.__NavigationControllerState(null, false);
      /* eslint-enable */
    } else if (this.state.toUpdate) {
      this.setState({ redirect: false, url: null, toUpdate: false });
    }
    return (
      this.state.redirect && this.state.url ?
        <Redirect to={this.state.url} />
        : null
    );
  }
}

NavigationController.propTypes = NavigationControllerPropTypes;

const mS = state => ({
  redirect: state.navigationControllerReducer.redirect,
  goToURL: state.navigationControllerReducer.goToURL,
});

const mD = {
  navigationControllerRedirect,
  __NavigationControllerState,
};

export default connect(mS, mD)(NavigationController);
