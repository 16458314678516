import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from 'prop-types';
import purple from '@material-ui/core/colors/purple';

const AdminEvaSwitchButton = props => (
  !props.isLoading ? (
    <button className="change-superadmin" onClick={props.handleAction} title="Evangelus Admin">
      <img src={props.icon} alt="switch" />
    </button>
  ) : (
    <CircularProgress
      style={{
        color: purple[500],
        width: 20,
        height: 20,
        margin: '0 8px',
        position: 'relative',
        top: -4,
      }}
      thickness={3}
    />
  )
);

AdminEvaSwitchButton.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  handleAction: PropTypes.func.isRequired,
  icon: PropTypes.string.isRequired,
};

export default AdminEvaSwitchButton;
