import AXIOS from '../Config/AxiosConfig';
import Auth from '../Api/Auth';
import snackBarStatus from './snackbarActions';
import { ACTIONS as navigationActions } from './navigationControllerActions';

export const ACTIONS = {
  RESPONSE_CODE: 'response/code',
};

export function setupInterceptors() {
  return dispatch => {
    AXIOS.interceptors.response.use(
      response => {
        response.data.code && dispatch({ type: ACTIONS.RESPONSE_CODE, payload: response.data.code });
        return response;
      },
      error => {
        let data = { code: 500 };
        if (error.response && error.response.data) {
          /* eslint-disable */
          data = error.response.data;
          /* eslint-enable */
        }
        if (data.code === 400) {
          return Promise.reject(data);
        }

        if (data.code === 401) {
          if (
            data.message === 'Expired JWT Token' ||
            data.message === 'request.invalid_token'
          ) {
            dispatch({
              type: ACTIONS.RESPONSE_CODE,
              payload: error,
            });
            localStorage.removeItem('token');
            localStorage.removeItem('sso_token');
            localStorage.removeItem('expiration');
            if (!/^\/login/.test(window.location.pathname)) {
              dispatch({ type: navigationActions.REDIRECT, payload: '/login' });
            }
            return Promise.reject(error);
          }
        }
        return Promise.reject(error);
      },
    );
  };
}

export const checkSsoLogin = token => async dispatch => {
  try {
    const response = await Auth.checkSsoLogin(token);
    return response;
  } catch (error) {
    if (error && error.response && error.response.status === 401) {
      localStorage.removeItem('token');
      localStorage.removeItem('expiration');
      localStorage.removeItem('roles');
      localStorage.removeItem('sso_token');
      dispatch({ type: navigationActions.REDIRECT, payload: '/login' });
    }
    snackBarStatus({
      payload: {
        title: error.message,
        type: 'error',
        enable: true,
      },
    })(dispatch);
    return error;
  }
};

