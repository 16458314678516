import ErrorIcon from './Icons/error.svg';
import Email from './Icons/email.svg';
import Username from './Icons/username.svg';
import Password from './Icons/password.svg';
import PhoneEmail from './Icons/phone-email.svg';
import GroupIcon from './Icons/groups.svg';
import MessagesIcon from './Icons/messages.svg';
import TemplateIcon from './Icons/templates.svg';
import DashboardIcon from './Icons/dashboard.svg';
import userList from './Icons/user-list.svg';
import HelpIcon from './Icons/help.svg';
import AddMemberIcon from './Icons/add-member.svg';
import QuickActionIcon from './Icons/quick-action.svg';
import DropDown from './Icons/dropdown-arrow-white.svg';
import NewMessageWhite from './Icons/new-message-white.svg';
import User from './Icons/member.svg';
import RevokeAccess from './Icons/deny-invitation.svg';
import Wings from './Icons/wingsBig.svg';

export default {
  ErrorIcon,
  Email,
  Username,
  Password,
  PhoneEmail,
  GroupIcon,
  MessagesIcon,
  TemplateIcon,
  DashboardIcon,
  userList,
  HelpIcon,
  AddMemberIcon,
  QuickActionIcon,
  DropDown,
  NewMessageWhite,
  User,
  RevokeAccess,
  Wings,
};
