import colors from '../../Theme/Colors';
import purpleArrow from '../../Theme/Icons/left-arrow-purple.svg';

const styles = {
  container: {
    borderBottom: 'none !important',
    display: 'flex',
    alignItems: 'flex-end',
  },
  icon: {
    width: '51px',
    height: '51px',
    lineHeight: '51px',
    textAlign: 'center',
    borderBottom: 'solid 2px #e3e3e3',
    '&>img': {
      height: '50%',
      verticalAlign: 'middle',
    },
  },
  input: {
    border: 'none',
    width: '100%',
    backgroundColor: 'transparent',
    height: '51px',
    outline: 'none',
    textAlign: 'left',
    paddingLeft: '0',
    margin: '0',
    fontFamily: 'GothamSSm-Medium',
    fontSize: '16px',
    color: colors.graytext,
    borderBottom: 'solid 2px #e3e3e3',
    '&.twoIcons': {
      width: 'calc(100% - 102px)',
    },
    '&.oneIcon': {
      width: 'calc(100% - 51px)',
    },
    '&.center': {
      textAlign: 'center',
    },
  },
  rightIcon: {
    '@media only screen and (min-width: 1024px)': {
      marginLeft: '3%',
    },
    '@media only screen and (max-width: 1023px)': {
      marginLeft: '1%',
    },
  },
  calendar: {
    '&.mbsc-fr-bubble': { // prevent app container to move
      position: 'fixed',
    },
    '&.mbsc-ios .mbsc-fr-overlay': { // overlay styles
      background: 'transparent',
    },
    '&.mbsc-ios .mbsc-fr-w': { // general popup styles
      color: colors.graytext,
      fontFamily: 'GothamSSm-Medium',
      background: 'white !important',
    },
    '&.mbsc-ios .mbsc-fr-popup': { // general popup styles
      borderRadius: '7px',
      boxShadow: '0 2px 10px 0 rgba(93, 90, 90, 0.5)',
      '& .mbsc-fr-arr': {
        boxShadow: '0 2px 10px 0 rgba(93, 90, 90, 0.5)',
        background: 'white',
      },
    },
    // set and cancel button styles
    '&.mbsc-ios .mbsc-fr-btn-cont': {
      border: 'none',
    },
    '&.mbsc-ios .mbsc-fr-btn.mbsc-fr-btn0, &.mbsc-ios .mbsc-fr-btn.mbsc-fr-btn1': {
      fontWeight: 'bold !important',
      fontSize: '12px !important',
      color: `${colors.purple} !important`,
      padding: '8px 16px !important',
      lineHeight: '2.75em !important',
    },
    '&.mbsc-ios .mbsc-fr-hdr': { // header date display
      padding: '1.45em 4.375em',
      color: colors.graytext,
      fontSize: '12px',
    },
    '& .mbsc-cal .mbsc-cal-btn': { // month and year selection arrows
      backgroundImage: `url("${purpleArrow}") !important`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      '&::before': {
        content: '" "',
      },
      '&.mbsc-ic-arrow-right5': {
        transform: 'rotate(180deg)',
      },
    },
    '&.mbsc-ios': { // picker selector tabs
      '& .mbsc-cal-tab': {
        border: `1px solid ${colors.purple}`,
        color: colors.purple,
      },
      '& .mbsc-fr-popup .mbsc-fr-w .mbsc-cal-tabs-c .mbsc-cal-tabs .mbsc-cal-tab.mbsc-selected': {
        background: colors.purple,
        color: 'white',
      },
    },
    '& .mbsc-cal-days': { // days name
      background: colors.purpleShade,
      borderRadius: '20px',
    },
    '&.mbsc-ios .mbsc-cal-cell': {
      border: 'none',
    },
    '&.mbsc-ios .mbsc-cal-cell:focus .mbsc-cal-cell-txt, &.mbsc-ios.mbsc-no-touch:not(.mbsc-ev-cal) .mbsc-cal-cell:not(.mbsc-disabled):hover .mbsc-cal-cell-txt, &.mbsc-ios.mbsc-no-touch .mbsc-cal-day:not(.mbsc-disabled) .mbsc-cal-day-date:hover': {
      background: colors.purpleShade,
    },
    '&.mbsc-ios .mbsc-cal-c .mbsc-cal .mbsc-cal-body .mbsc-cal-row .mbsc-selected .mbsc-cal-cell-txt': {
      background: colors.purple,
      borderColor: colors.purple,
    },
    '&.mbsc-ios .mbsc-cal-today': {
      color: colors.purple,
    },
  },
};

export default styles;
