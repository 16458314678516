import axios from 'axios';
import URL from './env';

const AXIOS = axios.create({
  baseURL: URL,
  headers: {
    'Content-Type': 'application/json',
  },
  timeout: 100000,
});

export default AXIOS;
