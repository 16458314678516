export const ACTIONS = {
  STATUS: 'snackbar/status',
};

export const clearSnackBarStatus = () => dispatch => {
  dispatch({ type: ACTIONS.CLEAR });
};

export default function snackBarStatus(value) {
  return dispatch => {
    dispatch({ type: ACTIONS.STATUS, ...value });
  };
}
