/* eslint-disable */
import React from 'react';
import MainMenu from '../../Components/MainMenu';
import CircularProgress from '@material-ui/core/CircularProgress';
import EvaButton from '../../Components/EvaButton';
import StatisticAPI from '../../Api/Statistic';
import DatePicker from '../../Components/DatePicker';
import moment from 'moment/moment';
import download from 'downloadjs';
import './index.sass';

class Statistic extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      statistic: {
        total: null, send: null, delivery: null, open: null,
      },
      filter: {
        from: '', to: '',
      },
      loading: false,
    };
  }

  renderProgress = () => <CircularProgress className="DataTable-progress-52" style={{ color: '#9c27b0' }}
                                           thickness={7} />

  handleClick = (e) => {
    e.preventDefault()
    const { from, to } = this.state.filter;
    this.setState({ ...this.state, loading: true, statistic: { total: null } })
    StatisticAPI.getStatistic(from, to).then(res => {
      this.setState({ ...this.state, statistic: res.data.data, loading: false })
    })

  }

  downloadStatisticFile = (type) => {
    const { from, to } = this.state.filter;
    this.setState({ ...this.state, loading: true })
    StatisticAPI.getStatisticFile(from, to, type)
      .then(a => {
        download(new Blob([a.data]), `statistic - ${type}.csv`, 'text/csv')
        this.setState({ ...this.state, loading: false })
      })
      .catch(a => {
        this.setState({ ...this.state, loading: false })
      })

  };

  downloadFailedBulletinReport = () => {
    const { from, to } = this.state.filter;
    this.setState({ ...this.state, loading: true })
    StatisticAPI.getFailedBulletinReport(from, to)
      .then(a => {
        download(new Blob([a.data]), `statistic - Failed Bulletin Report.csv`, 'text/csv')
        this.setState({ ...this.state, loading: false })
      })
      .catch(a => {
        this.setState({ ...this.state, loading: false })
      })
  };

  render() {
    const { total, send, delivery, open } = this.state.statistic;

    return (<MainMenu
      value={0}
      content={<div className="statistic-root">
        <div className="statistic-form">
          <div className="picker-wrapper">
            <DatePicker
              placeholder="Range From"
              value={this.state.from ? this.state.from : null}
              handleSelection={(e, inst) => {
                const value = moment(inst.getVal());
                this.setState({ ...this.state, filter: { ...this.state.filter, from: value._i } });
              }}
              max={new Date()}
              controls={['calendar']}
              dateFormat="yy-mm-dd"
              timeFormat="h:ii A"
              returnFormat="iso8601"
              iconLeft="none"
              iconRight="none"
            />
            <DatePicker
              placeholder="Range To"
              value={this.state.to ? this.state.to : null}
              handleSelection={(e, inst) => {
                const value = moment(inst.getVal());
                this.setState({ ...this.state, filter: { ...this.state.filter, to: value._i } });
              }}
              min={this.state.filter.from}
              max={new Date()}
              controls={['calendar']}
              dateFormat="yy-mm-dd"
              timeFormat="h:ii A"
              returnFormat="iso8601"
              iconLeft="none"
              iconRight="none"
            />
            <div>
              <EvaButton onClick={(e) => this.handleClick(e)} className="create-button">
                Get Statistic
              </EvaButton>
            </div>
          </div>

          {((total !== null) && !this.state.loading) && <div className="statistic-info">
            <div>Total: {total}</div>
            <div>Send: {send}</div>
            <div>Delivery: {delivery}</div>
            <div>Open: {open}</div>
          </div>}

          {this.state.loading && (<div className="progress-wrapper"> {this.renderProgress()}</div>)}

          <div className="buttons-wrapper">
            <EvaButton onClick={() => this.downloadStatisticFile('send')} className="create-button">
              Download Send
            </EvaButton>
            <EvaButton onClick={() => this.downloadStatisticFile('delivery')} className="create-button">
              Download Delivery
            </EvaButton>
            <EvaButton onClick={() => this.downloadStatisticFile('open')} className="create-button">
              Download Open
            </EvaButton>
          </div>
          <div className="buttons-wrapper">
            <EvaButton onClick={() => this.downloadFailedBulletinReport()} className="create-button">
              Download Failed Bulletin Report
            </EvaButton>
          </div>
        </div>
      </div>}
    />);
  }
}

export default Statistic;
