import React from 'react';
import InputPropType from '../../PropTypes/InputPropType';
import './index.sass';

const InputStandard = React.forwardRef(
  (
    { name, type, placeholder, value, onChange, icon, focused, maxLength, onBlur, readOnly },
    ref,
  ) => {
    const iconClass = icon ? `input-eva-${icon}-icon` : '';
    return (
      <div>
        <input
          ref={ref}
          name={name}
          type={type}
          placeholder={placeholder}
          value={value}
          onChange={event => !readOnly && onChange(event, name)}
          onBlur={onBlur}
          className={`${iconClass} ${focused} input-eva-border-bottom input-eva`}
          maxLength={maxLength}
          autoComplete="off"
          readOnly={readOnly}
        />
      </div>
    );
  },
);

InputStandard.propTypes = InputPropType;

export default InputStandard;
