const env = () => {
  const { APP_CONFIG, API_URL, SHORTENER_URL } = process.env;

  switch (APP_CONFIG) {
    case 'staging':
      return {
        API_URL: API_URL || 'https://api.diocesan-staging.com',
        SHORTENER_URL: SHORTENER_URL || 'eva.us/',
        API_SSO_URL: 'http://sso.gateway.diocesan-staging.com',
        API_CUSTOM_SSO: 'http://sso.gateway.diocesan-staging.com',
        EVA_FRONT_URL: 'https://eva.diocesan-staging.com',
      };
    case 'prod':
      return {
        API_URL: API_URL || 'https://api.diocesan.com',
        SHORTENER_URL: SHORTENER_URL || 'eva.us/',
        API_SSO_URL: 'http://sso.gateway.diocesan.com',
        API_CUSTOM_SSO: 'http://sso.gateway.diocesan.com',
        EVA_FRONT_URL: 'https://eva.diocesan.com',
      };
    default:
      return {
        API_URL: API_URL || 'https://api.diocesan-dev.com',
        SHORTENER_URL: SHORTENER_URL || 'eva.us/',
        API_SSO_URL: 'http://sso.gateway.diocesan-dev.com',
        API_CUSTOM_SSO: 'http://sso.gateway.diocesan-dev.com',
        EVA_FRONT_URL: 'https://eva.diocesan-dev.com',
      };
  }
};

export const GENERAL = {
  paginator_limit: 5,
};

export const { API_URL, SHORTENER_URL, EVA_FRONT_URL } = env();

export const ENV = env();

export const SSO_MENU_URL = `${API_URL}/public/sso/menu`;

export default API_URL;
