import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import SimpleSelectPropType from '../../PropTypes/SimpleSelectPropType';
import groupsObject from './dummy';
import './index.sass';

const styles = {
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
};

const SimpleSelect = ({
  name,
  handleChange,
  options,
  value,
  inputProps,
  className,
  icon,
  disabled,
}) => (
  <div className={icon} id="simple-select">
    <Select
      disabled={disabled}
      className={className}
      value={value}
      onChange={handleChange}
      inputProps={inputProps}
    >
      <MenuItem value="">
        <em>{name}</em>
      </MenuItem>
      {options.map(option => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </Select>
  </div>
);

SimpleSelect.defaultProps = {
  className: 'input-eva-border-bottom input-eva select-eva-border-bottom select',
  options: groupsObject,
  inputProps: {
    name: 'groups',
    id: 'groups',
  },
  value: '',
  disabled: false,
};

SimpleSelect.propTypes = SimpleSelectPropType;

export default withStyles(styles)(SimpleSelect);
