import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import CloseIcon from '@material-ui/icons/Close';
import amber from '@material-ui/core/colors/amber';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import { withStyles } from '@material-ui/core/styles';
import snackBarStatus from '../../Actions/snackbarActions';

const styles1 = theme => ({
  success: {
    backgroundColor: '#9a66bf',
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.dark,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing.unit,
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
});

function MySnackbarContent(props) {
  const {
    classes,
    className,
    message,
    onClose,
    type,
    variant,
    ...other
  } = props;
  const RenderIcon = type === 'success' ? CheckCircleIcon : ErrorIcon;
  return (
    <SnackbarContent
      className={classNames(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <RenderIcon
            className={classNames(classes.icon, classes.iconVariant)}
          />
          {message}
        </span>
      }
      action={[
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          className={classes.close}
          onClick={onClose}
        >
          <CloseIcon className={classes.icon} />
        </IconButton>,
      ]}
      {...other}
    />
  );
}

MySnackbarContent.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  className: PropTypes.string,
  message: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(['success', 'warning', 'error', 'info']).isRequired,
};

MySnackbarContent.defaultProps = {
  className: '',
};

const MySnackbarContentWrapper = withStyles(styles1)(MySnackbarContent);

const styles2 = theme => ({
  margin: {
    margin: theme.spacing.unit,
  },
});

class SnackBar extends React.Component {
  handleClose = () => {
    this.props.snackBarStatus({
      payload: { enable: false },
    });
  };
  render() {
    const { enable, title, type } = this.props;
    return (
      <div>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={enable}
          onClose={this.handleClose}
        >
          <MySnackbarContentWrapper
            onClose={this.handleClose}
            variant={type}
            message={title}
            type={type}
          />
        </Snackbar>
      </div>
    );
  }
}

SnackBar.propTypes = {
  enable: PropTypes.bool,
  title: PropTypes.string,
  type: PropTypes.string,
  snackBarStatus: PropTypes.func.isRequired,
};

SnackBar.defaultProps = {
  enable: false,
  title: '',
  type: '',
};

const mS = state => ({
  enable: state.snackBarReducer.enable,
  title: state.snackBarReducer.title,
  type: state.snackBarReducer.type,
});

const mD = {
  snackBarStatus,
};

export default connect(
  mS,
  mD,
)(withStyles(styles2)(SnackBar));
