import SuperUser from '../Api/SuperUser';
import snackBarStatus from './snackbarActions';
import findMessage from '../Helpers/utiliy';
import Invitation from '../Api/Invitation';

export const ACTIONS = {
  GET_SUPER_USERS_LIST: 'super_user/get_list',
  GRANT_SUPER_USER_PRIVILEGES: 'super_user/grant_super_user_access',
  REVOKE_SUPER_USER_ACCESS: 'super_user/revoke_super_user_access',
};

/*  ====================================================================================
  GET_SUPER_USERS_LIST Action helpers and action
  ==================================================================================== */

/**
 * Given the super users list a limit per page and a number of page
 * returns the pagitator of the super user.
 * @param {Object[]} superUserList
 * @param {number | string} limit
 * @param {number} page
 */
const generatePaginatorParams = (superUserList, limit, page) => {
  let totalPages;
  let actualPage = page;
  if (limit !== 'all' && typeof limit === 'number') {
    // Wholepages means COMPLETE pages of <limit> elements.
    const wholePages = Math.floor(superUserList.length / limit);
    // partialPage indicates if there is a page with less than <limit> elements but greater than
    // zero.
    const partialPage = superUserList.length % limit > 0;
    totalPages = wholePages + Number(partialPage);
  } else {
    totalPages = 1;
    actualPage = 1;
  }

  const numberOfItems = superUserList.length;
  const paginator = {
    currentPage: actualPage,
    numberOfItems,
    totalPages,
  };
  return paginator;
};

/**
 * Fetches from the backend the set of users with the SUPER_ADMIN privilege.
 *
 * @param {number} page The page to be consulted (First one is 1)
 * @param {string} orderBy The attribute to order the rows
 * @param {string} order asc (Ascending) or desc (Descending)
 * @param {number} limit The limit of results per page. (Defaults to 10)
 */
export const getSuperAdmins = (
  page = 1,
  orderBy = 'name',
  order = 'asc',
  limit = 10,
) => async dispatch => {
  try {
    dispatch({
      type: ACTIONS.GET_SUPER_USERS_LIST,
      payload: { loading: true },
    });
    const response = await SuperUser.getSuperUsers();
    const superUserList = response.data.data.data;
    // Result must be processed before being sent to the reducer.
    const paginator = generatePaginatorParams(superUserList, limit, page);
    if (response.status === 200) {
      dispatch({
        type: ACTIONS.GET_SUPER_USERS_LIST,
        payload: {
          superUserList,
          loading: false,
          order,
          paginator,
          sortField: orderBy,
          qty: limit,
        },
      });
    } else {
      throw new Error('Could not get super users');
    }
  } catch (error) {
    console.log(error);
    if (error && error.message) {
      snackBarStatus({
        payload: {
          title: findMessage(error),
          type: 'error',
          enable: true,
        },
      })(dispatch);
    }
  }
};

/* ====================================================================================
  REVOKE_SUPER_USER_ACCESS Action and helpers.
==================================================================================== */

export const revokeSuperUserAccess = superUser => async dispatch => {
  try {
    const {
      data: { code, message },
    } = await SuperUser.revokeSuperUserAccess(superUser.publicId);
    if (code === 200) {
      snackBarStatus({
        payload: {
          type: 'success',
          enable: true,
          title: message,
        },
      })(dispatch);
      dispatch({
        type: ACTIONS.REVOKE_SUPER_USER_ACCESS,
        payload: superUser.publicId,
      });
      return true;
    }
    snackBarStatus({
      payload: {
        type: 'error',
        enable: true,
        title: message,
      },
    })(dispatch);
  } catch (err) {
    snackBarStatus({
      payload: {
        type: 'error',
        enable: true,
        title: findMessage(err),
      },
    })(dispatch);
  }
  return false;
};

/* ==================================================
  GRANT_SUPER_USER_PRIVILEGE Action and helpers
 ==================================================== */

/**
 * Calls the endpoint to promoe users with the diocesan.com or
 * teravsiontech.com emails. The endpoint receives their public ids.
 * @param {string[]} publicIds
 */
export const grantSuperUserPrivileges = publicIds => async dispatch => {
  try {
    const payload = {};
    const mappedPublicIds = publicIds.map(elem => ({ public_id: elem }));
    payload.members = mappedPublicIds;
    const {
      data: { code, data, message },
    } = await SuperUser.grantSuperUserAccess(payload);
    if (code === 200) {
      snackBarStatus({
        payload: {
          type: 'success',
          enable: true,
          title: 'User promoted to Super Admin.',
        },
      })(dispatch);
      dispatch({
        type: ACTIONS.GRANT_SUPER_USER_PRIVILEGES,
        payload: data,
      });
      return true;
    }
    snackBarStatus({
      payload: {
        type: 'error',
        enable: true,
        title: `${message}`,
      },
    })(dispatch);
  } catch (err) {
    snackBarStatus({
      payload: {
        type: 'error',
        enable: true,
        title: findMessage(err),
      },
    })(dispatch);
  }
  return false;
};

/*  ======================================================================
  INVITE ACTION.
  ====================================================================== */

/**
 * Resuests the backend to send an invitation to the given user to
 * join the diocesan org and be a super admin as well.
 * @param {string} firstName
 * @param {string} lastName
 * @param {Date} birthdate
 * @param {string} email
 * @param {string} phoneNumber_
 */
export const sendInvitationToJoinSuperAdminTeam = (
  firstName,
  lastName,
  birthdate,
  email,
  phoneNumber_,
) => async dispatch => {
  /* eslint-disable */
  const phones = phoneNumber_
    ? [
        {
          description: 'main',
          country_code: '',
          phone_number: phoneNumber_,
          is_default: true,
        },
      ]
    : undefined;
  /* eslint-enable */
  const payload = {
    members: [
      {
        first_name: firstName,
        last_name: lastName,
        birth_date: birthdate,
        user: {
          username: email,
          display_email: email,
          email,
          plain_password: '',
        },
        profile_picture_url: '',
        state_text: '',
        city_text: '',
        postal_code: '',
        reference_address: '',
        status: 'active',
        phones,
        _m_p_a_user_id: 0,
      },
    ],
  };
  let status = true;
  try {
    const {
      data: { code, message },
    } = await SuperUser.inviteToBeSuperAdmin(payload);
    if (code === 200) {
      snackBarStatus({
        payload: {
          type: 'success',
          enable: true,
          title: message,
        },
      })(dispatch);
      status = true;
    }
  } catch (err) {
    snackBarStatus({
      payload: {
        type: 'error',
        enable: true,
        title: findMessage(err),
      },
    })(dispatch);
    status = false;
  }
  return status;
};

/* ======================================================================
    FINDING CANDIDATES
====================================================================== */

/**
 * Performs a call to the backend to retreive the possible candidates to be
 * super admin and transforms the payload into something manageable to the
 * SuperAdmins component.
 */
export const findCandidates = (email, phone) => async dispatch => {
  try {

    const reqData = {

    }

    if (email) {
      reqData.email = email;
    }

    if (phone) {
      reqData.phone = phone;
    }

    const {
      data: { code, data },
    } = await Invitation.getMembersToGroupAdd(reqData);
    if (code === 200) {
      return data;
    }
    snackBarStatus({
      payload: {
        title: `Server responded with code: ${code}.`,
        type: 'error',
        enable: true,
      },
    })(dispatch);
    return [];
  } catch (err) {
    console.log(err);
    snackBarStatus({
      payload: {
        title: findMessage(err),
        type: 'error',
        enable: true,
      },
    })(dispatch);
  }
  return [];
};
