import React from 'react';
import queryString from 'query-string';
import { withStyles } from '@material-ui/core/styles';
import SideMenuPropType from '../../PropTypes/SideMenuPropType';
import { SSO_MENU_URL } from '../../Config/env';
import styles from './styles';

class SideMenu extends React.Component {
  state = {
    windowHeight: 0,
  };

  componentDidMount() {
    this.updateWindowSizeData();
    window.addEventListener('resize', this.updateWindowSizeData);
    window.addEventListener('orientationchange', this.updateWindowSizeData);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowSizeData);
    window.removeEventListener('orientationchange', this.updateWindowSizeData);
  }

  updateWindowSizeData = () => {
    const windowHeight = window.innerHeight;

    this.setState({ windowHeight });
  };

  render() {
    const { active, classes, authToken, env } = this.props;
    const { windowHeight } = this.state;
    return (
      <iframe
        className={`${classes.root} ${active && 'active'}`}
        id="diocesan-side-menu"
        src={`${SSO_MENU_URL}?${queryString.stringify({ authToken, env })}`}
        style={{
          height: `${windowHeight}px`, // workaround for iOS
        }}
        title="Diocesan Side Menu"
      />
    );
  }
}

SideMenu.propTypes = SideMenuPropType;

SideMenu.defaultProps = {
  active: false,
};

export default withStyles(styles)(SideMenu);
