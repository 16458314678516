import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from 'prop-types';
import purple from '@material-ui/core/colors/purple';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { styles } from './styles';
import MatchFound from './MatchFound';
import InviteMember from './InviteMember';
import SearchMember from './SearchMember';

function validEmail(email) {
  /* eslint-disable*/
  const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@(diocesan\.com|teravisiontech\.com)$/;
  /* eslint-enable */
  return emailRegex.test(email);
}

function isValidPhone(phone) {
  if (phone) {
    const parsedNumber = parsePhoneNumberFromString(phone);
    if (!parsedNumber.isValid()) {
      return false;
    }
  }
  return true;
}


class SuperAdminAdd extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      phoneNumberSearch: '',
      firstName: '',
      lastName: '',
      birthdate: undefined,
      // orgToAssign: '',
      searchAdmin: true,
      inviteAdmin: false,
      matchAssignAdmin: false,
      matchesList: [],
      errors: {},
      loading: false,
      validPhone: false,
    };
  }

  /**
   * Given a valid email or phone number, a search will be requested to the backend to find
   * a match. If there is any match the the assign admin view will be shown.
   */
  findMembers = async () => {
    const { email, phoneNumber } = this.state;
    const providedEmailIsValid = this.validateEmail();
    if (!providedEmailIsValid) {
      return;
    }
    this.setState({
      loading: true,
    });
    const matches = await this.props.findAdmins(email, phoneNumber);
    if (matches.data.length > 0) {
      this.setState({
        inviteAdmin: false,
        searchAdmin: false,
        matchAssignAdmin: true,
        matchesList: matches.data,
        loading: false,
      });
    } else {
      this.setState({
        inviteAdmin: true,
        searchAdmin: false,
        matchAssignAdmin: false,
        matchesList: [],
        loading: false,
      });
    }
  };

  inviteAdmin = () => {
    const { firstName, lastName, birthdate, email, phoneNumberSearch } = this.state;
    const emailOk = this.validateEmail();
    const validPhoneNumber = this.validatePhone();
    if (validPhoneNumber && emailOk) {
      this.setState({
        loading: true,
      });
      this.props
        .inviteAdmin(firstName, lastName, birthdate, email, phoneNumberSearch)
        .then(result => {
          if (result) {
            this.props.disableDrawer();
            this.props.getSuperAdmins();
          }
          this.setState({ loading: false });
        });
    }
  };

  /**
   * Calls the action method to assign admins (given there is at least a match)
   * and then returns.
   * @param {object[]} adminlist The array of admins to be added to the list.
   */
  assignAdmins = () => {
    const adminList = this.state.matchesList.reduce((prev, elem) => {
      if (elem.selectedToBeSuperAdmin) {
        prev.push(elem.publicId);
      }
      return prev;
    }, []);
    try {
      if (adminList.length < 1) {
        return;
      }
      this.setState({ loading: true });
      this.props.assignAdmins(adminList).then(success => {
        if (success) {
          this.props.disableDrawer();
          this.props.getSuperAdmins();
        }
        this.setState({ loading: false });
      });
    } catch (err) {
      console.log(err);
    }
  };

  /**
   * Checks whether an email is correct or not.
   */
  validateEmail = () => {
    const { email } = this.state;
    if (!validEmail(email)) {
      this.setState(prevState => ({
        errors: {
          ...prevState.errors,
          email: 'Invalid Email. Use an email with @diocesan.com domain or check syntax.',
        },
      }));
      return false;
    } else if (!email) {
      this.setState(prevState => ({ errors: { ...prevState.errors, email: 'You must provide an email' } }));
      return false;
    }
    this.setState(prevState => ({ errors: { ...prevState.errors, email: undefined } }));
    return true;
  };

  /**
   * Checks whether a phone is correct or not.
   */
  validatePhone = () => {
    const { phoneNumberSearch } = this.state;
    if (!isValidPhone(phoneNumberSearch)) {
      this.setState(prevState => ({
        errors: {
          ...prevState.errors,
          phoneNumberSearch: 'Invalid phone number.',
        },
      }));
      return false;
    }
    this.setState(prevState => ({ errors: { ...prevState.errors, phoneNumberSearch: undefined } }));
    return true;
  };

  /**
   * Manages input changes in the forms fields exposed to the user.
   * @param {Object} event The event object
   * @param {string} attribute name of the attribute to be changed
   */
  hanldeChange = (event, attribute) => {
    const payload = {};
    payload[`${attribute}`] = event.target.value;
    this.setState({ ...payload });
  };

  handlePhoneNumberChange = value => {
    const phoneNumberRegex = /^\+?\d{1,3}\d{10}$/;
    const validPhone = phoneNumberRegex.test(value);
    this.setState({ phoneNumberSearch: value, validPhone });
  };

  /**
   * Hanldes the change of input date in the component.
   * @param {Object} event
   * @param {Object} instance Represents the date
   */
  /* eslint-disbale */
  hanldeBidthdateSelection = (_, instance) => {
    this.setState({ birthdate: moment(instance.getVal()).format('YYYY-MM-DD') });
  };
  /* eslint-enable */

  /**
   * If an error occurs the error must be shown.
   */
  buildError = field => {
    const { classes } = this.props;
    const { errors } = this.state;
    if (errors[field] !== undefined && errors[field] !== null) {
      return (
        <Grid item xs={10} sm={10} alignItems="center">
          <p className={classes.errorMessageFormat}>{this.state.errors[field]}</p>
        </Grid>
      );
    }
    return '';
  };

  /**
   * Restores the initial state of the component.
   */
  restoreInitialState = () => {
    this.setState({
      email: '',
      phoneNumberSearch: '',
      firstName: '',
      lastName: '',
      birthdate: undefined,
      // orgToAssign: '',
      searchAdmin: true,
      inviteAdmin: false,
      matchAssignAdmin: false,
      matchesList: [],
      errors: {},
      loading: false,
      validPhone: false,
    });
  };

  /**
   * Marks an element as selected to be super admin.
   * If the element is selected, it does the oposite.
   * @param {Object} member The member object containing all information needed
   */
  addToMatchList = member => {
    const { matchesList } = this.state;
    const memberIncluded = matchesList.reduce(
      (prev, curr) => prev || (curr.publicId === member.publicId && curr.selectedToBeSuperAdmin),
      false,
    );
    const hoFilter = cond => elem => {
      if (elem.publicId === member.publicId) {
        return { ...elem, selectedToBeSuperAdmin: cond };
      }
      return elem;
    };
    if (!memberIncluded) {
      const newMatchesList = matchesList.map(hoFilter(true));
      this.setState({
        matchesList: newMatchesList,
      });
    } else {
      const newMatchesList = matchesList.map(hoFilter(false));
      this.setState({
        matchesList: newMatchesList,
      });
    }
  };

  renderProgress = () => {
    const { classes } = this.props;
    return (
      <CircularProgress className={classes.progress} style={{ color: purple[500] }} thickness={7} />
    );
  };

  render() {
    const {
      inviteAdmin,
      searchAdmin,
      matchAssignAdmin,
      email,
      firstName,
      lastName,
      phoneNumberSearch,
      birthdate,
      matchesList,
      loading,
      validPhone,
    } = this.state;
    if (loading) {
      return this.renderProgress();
    }
    if (searchAdmin && !inviteAdmin && !matchAssignAdmin) {
      return (
        <SearchMember
          email={email}
          phoneNumberSearch={phoneNumberSearch}
          classes={this.props.classes}
          findAdmins={this.findMembers}
          handlePhoneNumberChange={this.handlePhoneNumberChange}
          handleChange={this.hanldeChange}
          buildError={this.buildError}
          valid={validPhone}
          validEmail={validEmail(email)}
        />
      );
    } else if (!searchAdmin && inviteAdmin && !matchAssignAdmin) {
      return (
        <InviteMember
          classes={this.props.classes}
          email={email}
          firstName={firstName}
          lastName={lastName}
          birthdate={birthdate}
          handleChange={this.hanldeChange}
          hanldeBidthdateSelection={this.hanldeBidthdateSelection}
          buildError={this.buildError}
          invite={this.inviteAdmin}
          emailValid={validEmail(email)}
          handlePhoneNumberChange={this.handlePhoneNumberChange}
          validPhone={this.state.validPhone}
        />
      );
    } else if (!searchAdmin && !inviteAdmin && matchAssignAdmin) {
      return (
        <MatchFound
          tryAgain={this.restoreInitialState}
          stepValues={{ members: matchesList }}
          nextStep={this.assignAdmins}
          selectAdmin={this.addToMatchList}
        />
      );
    }
    return <p>No match</p>;
  }
}

SuperAdminAdd.propTypes = {
  classes: PropTypes.isRequired,
  assignAdmins: PropTypes.func.isRequired,
  inviteAdmin: PropTypes.func.isRequired,
  findAdmins: PropTypes.func.isRequired,
  disableDrawer: PropTypes.func.isRequired,
  getSuperAdmins: PropTypes.func.isRequired,
};

export default withStyles(styles)(SuperAdminAdd);
