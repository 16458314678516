import color from '../../Theme/Colors';
import active from '../../Theme/Icons/check.svg';

export const phoneInputContainerStyle = {
  borderBottom: '2px solid grey',
  height: '48px',
  borderWidth: '0 0 2px',
  borderColor: '#d9d9d9',
  marginBottom: '5px',
};

export const RegisterMemberStyle = {
  row: {
    textAlign: 'center',
    marginTop: '40px',
    textTransform: 'initial',
  },
};

export const styles = {
  root: {
    height: '42px',
    borderBottom: `2px solid ${color.evaPrimaryWhite}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& .icon': {
      width: '40px',
      margin: '0 8px',
      height: '30px',
    },
    '& .input': {
      width: 'calc(100% - 100px)',
      marginRight: '60px',
      '& .react-phone-number-input': {
        margin: '0',
        width: 'auto',
        position: 'inherit',
      },
    },
    '& .leading-icon>img': {
      height: '100%',
    },
    '& .react-phone-number-input__country.react-phone-number-input__country--native': {
      padding: '0',
    },
    '& input.react-phone-number-input__input.react-phone-number-input__phone': {
      fontFamily: 'GothamSSm-Medium',
      fontSize: '16px',
      color: color.evaGrey,
      height: '40px',
      border: 'none',
      textAlign: 'center',
      margin: '0 !important',
      padding: '0 !important',
      '&::placeholder': {
        padding: '0 !important',
      },
    },
    '& .valid-phone': {
      backgroundImage: `url('${active}')`,
      backgroundRepeat: 'no-repeat',
      // padding: '6.5px 7.9px 6.5px 7.9px',
      backgroundSize: 'contain',
      backgroundPosition: 'center center',
      marginRight: '21px',
      height: '13px',
      width: '15.8px',
      flexShrink: 0,
    },
    '& .valid-phone-hidden': {
      visibility: 'hidden',
    },
    '& div.react-phone-number-input__icon': {
      borderStyle: 'none',
      width: '28px',
      height: '20px',
    },
    '& div.react-phone-number-input__icon > img.react-phone-number-input__icon-image': {
      borderRadius: '5px',
    },
  },
};
