import moment from 'moment';

export const validDate = date => {
  const momentDate = moment(date);
  return momentDate.isValid();
};

export const formatDate = date => moment(date).format('MMMM Do YYYY');

export const getYears = date => moment().diff(date, 'years');

export const addDays = (date, days) => moment(date, 'YYYY-DD-MM').add('days', days);

export const yearMonthDayFormat = date => moment(date).format('YYYY-MM-DD');
