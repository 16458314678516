import AXIOS from '../Config/AxiosConfig';

const Profile = {
  updateUserProfile(
    firstName,
    lastName,
    birthDate,
    phoneNumber,
    email,
    countryPublicId,
    city,
    province,
    street,
    password,
    groupId,
    token,
    privacyPolicyPublicId,
  ) {
    return AXIOS.put(
      `/public/eva/complete-invitation/group/${groupId}/invitation/${token}`,
      {
        firstName,
        lastName,
        birthDate,
        phone: phoneNumber,
        email,
        countryPublicId,
        city,
        province,
        street,
        password,
        privacyPolicyPublicId,
      },
      {
        headers: {
          'Diocesan-Application': 'admin',
        },
      },
    );
  },
  getUserAndGroupInformation(groupId, token) {
    return AXIOS.get(
      `/public/eva/complete-invitation/group/${groupId}/invitation/${token}`,
      {
        groupId,
        token,
      },
      {
        headers: {
          'Diocesan-Application': 'admin',
        },
      },
    );
  },
  getCountries() {
    return AXIOS.get('/public/countries', {
      headers: {
        'Diocesan-Application': 'admin',
      },
    });
  },
};

export default Profile;
