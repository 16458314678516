export const ACTIONS = {
  DRAWER: 'top_drawer/get',
  SELECT_ELEMENT: 'top_drawer/select_element',
};

export function enableTopDrawer(data) {
  return dispatch => {
    dispatch({ type: ACTIONS.DRAWER, payload: data });
  };
}

export const selectElementFromTable = element => {
  let selectedElement = {};
  if (element && element.address) {
    selectedElement = {
      ...element.address,
    }
  }
  const address = {
    name: element.name,
    type: element.type,
    ssoMasterName: element.ssoMasterName,
    ...selectedElement,
  };

  return dispatch => {
    dispatch({ type: ACTIONS.SELECT_ELEMENT, payload: address });
  };
};
