import { ACTIONS } from '../Actions/membersActions';
import { ACTIONS_MEMBERS_ORG } from '../Actions/membersActionsOrg';
// fromGroup = true (action from a group) and fromGroup = false (action from the compose button)
const initialState = {
  membersByFilter: [],
  selectedByFilter: [],
  selectedDetail: [],
  membersOrgByFilter: [],
  selectedOrgByFilter: [],
  selectedOrgDetail: [],
};
const filterMembersReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.FILTER:
      return {
        ...state,
        membersByFilter: [...action.payload],
      };
    case ACTIONS.SELECTED:
      return {
        ...state,
        selectedByFilter: [action.payload, ...state.selectedByFilter],
      };
    case ACTIONS.CLEAR_SELECTED:
      return {
        ...state,
        ...initialState,
      };
    case ACTIONS_MEMBERS_ORG.FILTER:
      return {
        ...state,
        membersOrgByFilter: [...action.payload],
      };
    case ACTIONS_MEMBERS_ORG.SELECTED:
      return {
        ...state,
        selectedOrgByFilter: [action.payload, ...state.selectedOrgByFilter],
      };
    case ACTIONS_MEMBERS_ORG.CLEAR_SELECTED:
      return {
        ...state,
        ...initialState,
      };
    case ACTIONS_MEMBERS_ORG.CLEAR_DETAIL_MEMBERS:
      return {
        ...state,
        selectedOrgDetail: action.payload,
      };
    case ACTIONS.UPDATE_LIST:
      return { ...state, ...action.payload };
    case ACTIONS_MEMBERS_ORG.UPDATE_LIST:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
export default filterMembersReducer;
