/* eslint-disable */
const backtrack = (options = [], latest, index = 0) => {
  let current = { ...latest };

  for (const option of options) {
    if (typeof option === 'object' && option !== null) {
      if ((!latest || current.index <= index) && 'message' in option) {
        current = {
          message: option.message,
          index,
        };
      }
      const following = backtrack(Object.values(option), current, index + 1);

      if (following.index > current.index) {
        current = { ...following };
      }
    }
  }

  return current;
};
/* eslint-enable */
const findMessage = (object = {}, priority = false) => {
  if (priority) return object.message || 'Unexpected error';
  const options = Object.values((typeof object === 'object' && object) || {});

  return (
    (options.length && backtrack(options, { message: object.message, index: 0 }, 1).message) ||
    'Unexpected error'
  );
};

export default findMessage;

export const copyToClipboard = el => {
  const input = el;
  if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {
    // save current contentEditable/readOnly status
    const { contentEditable, readOnly } = input;

    // convert to editable with readonly to stop iOS keyboard opening
    input.contentEditable = true;
    input.readOnly = true;

    // create a selectable range
    const range = document.createRange();
    range.selectNodeContents(input);

    // select the range
    const selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
    input.setSelectionRange(0, 999999);

    // restore contentEditable/readOnly to original state
    input.contentEditable = contentEditable;
    input.readOnly = readOnly;
  } else {
    input.select();
  }
  document.execCommand('copy');
  input.blur();
};
