/* eslint-disable */
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { getTimeZone } from '../../Actions/organizationActions';
import MainMenu from '../../Components/MainMenu';
import { enableTopDrawer } from '../../Actions/topDrawerActions';
import CircularProgress from '@material-ui/core/CircularProgress';
import Checkbox from '@material-ui/core/Checkbox';
import { createKeyword, getKeywords, updateKeyword } from '../../Actions/keywordActions';
import EvaButton from '../../Components/EvaButton';
import { CustomInput } from '../../Components/Input';

import './index.sass';

class ShortCodes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      keyword: '',
      message: '',
      editingItem: null
    };
    this.props.enableTopDrawer(false);
  }

  componentDidMount() {
    // this.renderComponent();
    this.props.getTimeZone();
    this.props.getKeywords();
  }

  renderProgress = () => {
    return (
      // <CircularProgress className={classes.progress} style={{ color: purple[500] }} thickness={7}/>
      <CircularProgress className='DataTable-progress-52' style={{ color: '#9c27b0' }} thickness={7}/>
    );
  };

  handleKeyword = (e) => {
    this.setState({ keyword: e.target.value })
  }
  handleMessage = (e) => {
    this.setState({ message: e.target.value })
  }

  handleClick = (e) => {
    e.preventDefault()
    if (this.state.keyword.length && this.state.message.length) {
      this.props.createKeyword(this.state.keyword, this.state.message);
    }
  }

  handleKeywordChange = value => {
    this.setState({
      editingItem: {
        ...this.state.editingItem,
        keyword: value
      }
    })
  }

  handleMessageChange = value => {
    this.setState({
      editingItem: {
        ...this.state.editingItem,
        message: value
      }
    })
  }

  handleEdit = item => {
    this.setState({ editingItem: item });
  }

  handleSave = () => {
    const { editingItem: { publicId, keyword, message } } = this.state;
    this.props.updateKeyword(publicId, keyword, message)
      .then(() => this.setState({ editingItem: null }));
  }

  render() {
    const { list, loading } = this.props;
    const { editingItem } = this.state;

    const keywordsArr = list;

    return (
      <MainMenu
        value={0}
        content={
          <div className="short-codes-root">
            <div className="short-codes-form">
              <CustomInput
                name="Keyword"
                placeholder="Keyword"
                // icon=""
                color="#FAFAFA"
                value={this.state.keyword}
                onChange={this.handleKeyword}
              />
              <CustomInput
                name="message"
                placeholder="Message"
                // icon=""
                color="#FAFAFA"
                value={this.state.message}
                onChange={this.handleMessage}
              />
              <div>
                <EvaButton onClick={(e) => this.handleClick(e)} className="create-button">
                  Create
                </EvaButton>
              </div>
            </div>
            <div className='short-code'>
            </div>
            <div className="pagination-container">
              <table className="diocesan-table">
                <tr>
                  <th className="cell-checkbox" />
                  <th className="cell-keyword">Short Code</th>
                  <th className="cell-message">Message</th>
                  <th className="cell-actions"/>
                </tr>

                {loading ? (
                  <tr>
                    <td colSpan={4} style={{ textAlign: 'center' }}>
                      {this.renderProgress()}
                    </td>
                  </tr>
                ) : (
                  keywordsArr && keywordsArr.map(item => (
                    <tr key={item.publicId}>
                      <td className="cell-checkbox">
                        <div className="custom-checkbox">
                          {/* <input type="checkbox" id={item.publicId} onChange={() => this.props.onCheck(publicId)}/> */}
                          
                          <Checkbox
                            // checked={selectedAdmin[index]}
                            // onChange={() => selectAdmin(index)}
                            // value={selectedAdmin[index]}
                            // style={checkBox.root}
                          />
                        </div>
                      </td>
                      <td className="cell-keyword">
                        <input
                          className="input-table"
                          type="text"
                          disabled={!editingItem || editingItem.publicId !== item.publicId}
                          value={editingItem && editingItem.publicId === item.publicId ? editingItem.keyword : item.keyword}
                          onChange={e => this.handleKeywordChange(e.target.value)}
                        />
                      </td>
                      <td className="cell-message">
                        <input
                          className="input-table"
                          type="text"
                          disabled={!editingItem || editingItem.publicId !== item.publicId}
                          value={editingItem && editingItem.publicId === item.publicId ? editingItem.message : item.message}
                          onChange={e => this.handleMessageChange(e.target.value)}/>
                      </td>
                      <td className="cell-actions">
                        {(!editingItem || editingItem.publicId !== item.publicId) &&
                        (<EvaButton onClick={() => this.handleEdit(item)} className="edit-button">
                          <div>Edit</div>
                        </EvaButton>)}
                        {(editingItem && editingItem.publicId === item.publicId) &&
                         (<EvaButton onClick={this.handleSave} className="save-button">
                          Save
                        </EvaButton>)}
                      </td>
                    </tr>
                  ))
                )}
              </table>
            </div>
          </div>
        }
      />
    );
  }
}

ShortCodes.propTypes = {
  statusTopDrawer: PropTypes.bool.isRequired,
};

const mS = state => ({
  statusTopDrawer: state.topDrawerReducer.statusTopDrawer,
  list: state.keywordReducer.list,
  loading: state.keywordReducer.loading,
});

const mD = {
  getKeywords,
  createKeyword,
  updateKeyword,
  enableTopDrawer,
  getTimeZone,
};

export default withRouter(
  connect(
    mS,
    mD,
  )(ShortCodes),
);
