import AXIOS from '../Config/AxiosConfig';
/* eslint-disable */
import * as headerFunctions from './Helpers';
/* eslint-enable */
import headers from './Helper';

const baseAdminURL = '/admin-panel';


const Members = {
  updateOrgRoleMembers(data, orgId) {
    return AXIOS.put(
      `${baseAdminURL}/organization/${orgId}/promote-to-admin`,
      { ...data },
      { headers: headers() },
    );
  },
  getOrgAdmins(orgId) {
    return AXIOS.get(`${baseAdminURL}/organization/${orgId}/get-org-admin`, {
      headers: headers(),
    });
  },
  removeRoleOrgAdmin(orgId, memberId) {
    return AXIOS.delete(
      `${baseAdminURL}/organization/${orgId}/member/${memberId}/remove-admin`,
      {
        headers: headers(),
      },
    );
  },
};

export default Members;
