import PropTypes from 'prop-types';
import React from 'react';
import Scrollbars from 'react-custom-scrollbars';
import Grid from '@material-ui/core/Grid';
import Input from './CustomInput';
import InputPhone from './PhoneInput';
import helpIcon from '../../Theme/Icons/help-gray.svg';
import birthdayIcon from '../../Theme/Icons/birthday.svg';
import EvaButton from '../EvaButton';
import DatePicker from '../DatePicker';

/**
 * When a member is not found in the backend this form must be displayed.
 * @param {Object} parameters Parameters needed for the component to operate correctlty
 * @param {Object} parameters.classes Injected styles by material ui HOC
 * in the parent component
 * @param {string} parameters.email
 * @param {string} parameters.phoneNumberSearch
 * @param {string} parameters.firstName
 * @param {string} parameters.lastName
 * @param {string} parameters.birthdate In iso format (YYYY-MM-DD)
 * @param {Function} parameters.handleChange Manages text inputs in the parent component
 * @param {Function} parameters.hanldeBidthdateSelection Handles the birthday daate changes
 * @param {Function} parameters.buildError Function that renders an error display in case of
 * invalid inputs.
 */
const InviteMember = ({
  classes,
  email,
  phoneNumberSearch,
  firstName,
  lastName,
  birthdate,
  handleChange,
  hanldeBidthdateSelection,
  handlePhoneNumberChange,
  buildError,
  invite,
  emailValid = false,
  validPhone = false,
}) => {
  const size = 10;
  const A_DAY = 24 * 3600000;
  const TOMORROW = new Date(new Date().getTime() + A_DAY);
  return (
    <Scrollbars
      autoHeight
      autoHeightMin={window.innerHeight - 100}
      autoHeightMax={window.innerHeight - 100}
    >
      <Grid
        container
        spacing={0}
        className={`new-super-admin-form  ${classes.topMargin}`}
        justify="center"
      >
        <Grid item xs={size} sm={10} alignItems="center" className={classes.textJustification}>
          <div className={classes.instructionsLong}>
            <p className={classes.textStyles}>
              Complete the informaction below to invite the super admin
            </p>
          </div>
        </Grid>
        <Grid item xs={10} sm={10} alignItems="center" className={classes.fstMarginTop}>
          <InputPhone
            onInputPhoneChange={handlePhoneNumberChange}
            placeholder="Phone Number"
            name="phoneNumberSearch"
            value={phoneNumberSearch}
            valid={validPhone}
          />
        </Grid>
        {buildError('phoneNumberSearch')}
        <Grid item xs={10} sm={10} alignItems="center" className={classes.sndMarginTop}>
          <Input
            type="text"
            name="email"
            placeholder="Email"
            value={email}
            onChange={handleChange}
            icon="email"
            checkVisible={emailValid}
          />
        </Grid>
        {buildError('email')}
        <Grid item xs={10} sm={10} alignItems="center" className={classes.thrdMarginTop}>
          <Input
            type="text"
            name="firstName"
            placeholder="First Name"
            value={firstName}
            onChange={handleChange}
            icon="firstName"
          />
        </Grid>
        <Grid item xs={10} sm={10} alignItems="center">
          <Input
            type="text"
            name="lastName"
            placeholder="Last Name"
            value={lastName}
            icon="lastName"
            onChange={handleChange}
          />
        </Grid>
        <Grid
          item
          xs={10}
          sm={10}
          alignItems="center"
          className={classes.frthMarginTop}
        >
          <DatePicker
            placeholder="Day of Birth"
            value={birthdate}
            handleSelection={hanldeBidthdateSelection}
            controls={['calendar']}
            headerText="{value}"
            dateFormat="mm/dd/yy"
            timeFormat="h:ii A"
            returnFormat="jsdate"
            iconLeft={birthdayIcon}
            iconRight={helpIcon}
            centerText
            invalid={[
              {
                start: TOMORROW,
                end: new Date(2099, 12, 31),
              },
            ]}
            classes={{
              container: classes.datePickerBorder,
              calendar: classes.calendarCustomStyle,
            }}
          />
        </Grid>
        <Grid item xs={7} sm={7} alignItems="center">
          <EvaButton
            onClick={() => invite()}
            active={Boolean(email)}
            className={email ? classes.buttonClass : classes.buttonClassInactive}
          >
            <span className={classes.spanTextDisplay}>Add Super admin</span>
          </EvaButton>
        </Grid>
      </Grid>
    </Scrollbars>
  );
};

InviteMember.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  email: PropTypes.string.isRequired,
  phoneNumberSearch: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  birthdate: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  hanldeBidthdateSelection: PropTypes.func.isRequired,
  handlePhoneNumberChange: PropTypes.func.isRequired,
  buildError: PropTypes.func.isRequired,
  invite: PropTypes.func.isRequired,
  emailValid: PropTypes.bool.isRequired,
  validPhone: PropTypes.bool.isRequired,
};

export default InviteMember;
