import colors from '../../Theme/Colors';
import searchIcon from '../../Theme/Icons/search.png';

const styles = {
  container: {
    borderBottom: `2px solid ${colors.evaPrimaryWhite}`,
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    width: '51px',
    height: '51px',
    lineHeight: '51px',
    textAlign: 'center',
    '&>img': {
      height: '50%',
      verticalAlign: 'middle',
    },
  },
  selectInput: {
    textOverflow: 'ellipsis',
    outline: 'none',
    border: 'none',
    margin: '0',
    width: '100%',
    height: '51px',
    fontSize: '16px',
    fontFamily: 'GothamSSm-Medium',
    fontWeight: 'normal',
    color: colors.graytext,
    cursor: 'pointer',
    background: 'transparent',
    padding: '0',
    '&.twoIcons': {
      width: 'calc(100% - 102px)',
    },
    '&.oneIcon': {
      width: 'calc(100% - 51px)',
    },
    '&.center': {
      textAlign: 'center',
    },
  },
  select: { // popup
    '&.mbsc-ios .mbsc-fr-overlay': { // backdrop
      background: 'rgba(0,0,0,0.2)',
    },
    '&.mbsc-ios .mbsc-sel-filter-clear': { // clear filter icon
      fontSize: '20px',
    },
    '&.mbsc-ios.mbsc-fr-center .mbsc-sel-filter-cont': { // filter input
      background: 'white',
      '& .mbsc-sel-filter-input.mbsc-control': {
        fontFamily: 'GothamSSm-Medium',
        fontSize: '16px',
        color: colors.graytext,
        backgroundColor: colors.evaPrimaryWhite,
        backgroundImage: `url(${searchIcon})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '7px 50%',
        backgroundSize: '18px',
        paddingLeft: '32px',
        '&::placeholder': {
          fontSize: '16px',
          color: colors.greyish,
        },
      },
    },
    '&.mbsc-ios.mbsc-fr-pointer .mbsc-fr-persp .mbsc-fr-popup .mbsc-fr-w': { // desktop popup background
      background: 'white',
    },
    '& .mbsc-fr-w': { // popup background
      background: 'white',
    },
    '&.mbsc-ios .mbsc-sc-whl-o, &.mbsc-ios.mbsc-calendar .mbsc-sc-whl-o': { // selector shadow
      background: 'linear-gradient(white, rgba(245,245,245,0) 52%, rgba(245,245,245,0) 48%, white)',
    },
    '&.mbsc-ios.mbsc-sel-multi .mbsc-sc-itm': { // selectors items
      fontFamily: 'GothamSSm-Light',
      color: colors.graytext,
      textAlign: 'left',
      '&.mbsc-sc-itm-sel': {
        background: 'rgba(154, 102, 191, 0.15)',
        color: colors.purple,
        '&>.mbsc-sc-itm-ml>.cstm-select-itm-s': {
          color: colors.purple,
        },
      },
      '&.mbsc-active': {
        background: 'rgba(154, 102, 191, 0.15)',
      },
      '&>.mbsc-sc-itm-ml': { // multiline items
        '&>.cstm-select-itm-m': { // Main text
          fontSize: '16px',
          lineHeight: '17px',
        },
        '&>.cstm-select-itm-s': { // Secondary text
          fontSize: '13px',
          lineHeight: '14px',
          color: colors.greyish,
        },
      },
    },
    '& .mbsc-fr-btn-s > div, & .mbsc-fr-btn-c > div': { // buttons
      height: '2.75em',
      lineHeight: '2.75em !important',
      padding: '0 .625em !important',
      fontSize: '18px !important',
      color: colors.purple,
    },
    '& .mbsc-fr-btn-s > div': { // Select Button
      fontFamily: 'GothamSSm-Bold',
    },
    '& .mbsc-fr-btn-c > div': { // Cancel Button
      fontFamily: 'GothamSSm-Light',
    },
    '&.mbsc-ios.mbsc-sel-multi.mbsc-fr-bubble': { // display bubble
      '& .mbsc-sc-itm': {
        color: colors.greyish,
        fontSize: '14px',
        fontFamily: 'GothamSSm-Medium',
        '&.mbsc-sc-itm-sel': { // selected item
          background: 'transparent',
        },
      },
    },
    '&.mbsc-ios.mbsc-fr-bottom': { // display bottom
      '& .mbsc-fr-btn-c > div, & .mbsc-fr-btn-s > div': {
        fontFamily: 'GothamSSm-Medium',
      },
      '& .mbsc-fr-w': {
        background: 'white',
      },
      '& .mbsc-sc-whl-gr-3d': {
        '& .mbsc-sc-itm-sel': {
          color: colors.amethyst,
        },
        '& .mbsc-sc-itm': {
          color: colors.amethyst,
        },
        '& .mbsc-sc-whl-c': {
          background: 'white',
        },
        '& .mbsc-sc-itm-3d': {
          color: colors.greyish,
        },
      },
    },
  },
  '@media (max-width: 768px)': { // desktop
    select: {
      '& .mbsc-sc-whl-multi .mbsc-sc-itm-sel:before': {
        left: 15,
      },
      '&.mbsc-ios.mbsc-sel-multi .mbsc-sc-itm': {
        padding: '0 2.6em',
      },
    },
  },
  '@media (min-width: 769px)': { // desktop
    select: {
      '&.mbsc-ios.mbsc-fr-bubble': { // selectors on display bubble
        '& .mbsc-sc-itm.mbsc-btn-e:hover': {
          background: 'transparent',
        },
      },
      '&.mbsc-ios.mbsc-sel-multi .mbsc-sc-itm': { // selectors items
        '&.mbsc-btn-e:hover': {
          background: 'rgba(154, 102, 191, 0.15)',
        },
        '&>.mbsc-sc-itm-ml': {
          '&>.cstm-select-itm-m': { // Main text
            fontSize: '18px',
            lineHeight: '19px',
          },
          '&>.cstm-select-itm-s': { // Secondary text
            fontSize: '15px',
            lineHeight: '16px',
          },
        },
      },
    },
  },
};

export default styles;
