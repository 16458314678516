import { ACTIONS } from '../Actions/passwordActions';

const initialState = {
  firstPassword: '',
  secondPassword: '',
  pin: '',
  username: '',
  error: '',
  phone: '',
};

const passwordReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.UPDATE:
      return { ...state, ...action.payload };
    case ACTIONS.REQUEST:
      return { ...state, ...action.payload };
    case ACTIONS.RESET:
      return { ...state, ...action.payload };
    case ACTIONS.ERROR:
      return { ...state, ...action.payload };
    case ACTIONS.CLEAR:
      return initialState;
    default:
      return state;
  }
};

export default passwordReducer;
