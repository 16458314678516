import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Scrollbars } from 'react-custom-scrollbars';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
// Components
import MemberFound from '../../Components/AddAdminTabs/InviteNewSteps/MemberFound';
import RegisterMember from '../../Components/AddAdminTabs/InviteNewSteps/RegisterMember';
import SearchMember from '../../Components/AddAdminTabs/InviteNewSteps/SearchMember';
// import EvaButton from '../../Components/EvaButton';
// Action Creators
import {
  create,
  clear,
  update,
  submitMembersActions,
  searchMemberAction,
} from '../../Actions/orgInviteAdminActions';
import { updateOrganizationAction } from '../../Actions/organizationActions';
import snackBarStatus from '../../Actions/snackbarActions';
import { getOrgAdmins } from '../../Actions/membersActionsOrg';
import { disableRightDrawer } from '../../Actions/rightDrawerActions';
// Helpers
import OrgInviteAdminPropType from '../../PropTypes/OrgInviteAdminPropType';
import Helpers from '../../Helpers';
import { yearMonthDayFormat } from '../../Helpers/date';
// Styles
import styles from './styles';

const stepComponents = [SearchMember, RegisterMember, MemberFound, submitMembersActions];

class OrgInviteAdmin extends Component {
  constructor(props) {
    super(props);
    this.stepHandlers = [
      'searchMemberHandler',
      'registerMemberHandler',
      'memberFoundHandler',
      'submitCandidates',
    ];
    this.state = {
      selectedAdmin: [false, false],
      validNumber: false,
      isEnableButton: false,
    };
  }

  componentDidUpdate() {
    const { sentOrgAdmins, selectedOrg } = this.props;
    if (sentOrgAdmins) {
      this.props.update({ sentOrgAdmins: false });
      this.props.getOrgAdmins(selectedOrg.publicId);
    }
  }

  componentWillUnmount() {
    this.props.clear();
  }

  handleAdminSelect = index => {
    const { selectedAdmin } = this.state;
    const selection =
      index === 0
        ? [!selectedAdmin[index], selectedAdmin[1]]
        : [selectedAdmin[0], !selectedAdmin[index]];
    this.setState({ selectedAdmin: selection });
  };

  validMemberInfo = memberInfo => {
    let error = '';
    if (memberInfo) {
      if (memberInfo.email && !Helpers.EmailSimple(memberInfo.email)) {
        error = 'Invalid email.';
      } else if (memberInfo.phone) {
        const parsedPhone = parsePhoneNumberFromString(memberInfo.phone);
        if (!parsedPhone.isValid()) {
          error = 'Invalid phone.';
        }
      }
      return error;
    }
    error = 'Data is missing';
    return error;
  };

  displaySnackbar = (type, message) => {
    this.props.snackBarStatus({
      payload: {
        enable: true,
        title: message,
        type,
      },
    });
  };

  nextStep = (...params) => {
    const { currentStep } = this.props;
    return this[this.stepHandlers[currentStep]](...params);
  };

  checkMember = email => {
    const { membersAdminOrgList } = this.props;
    const newMember = membersAdminOrgList.filter(member => member.user
      && member.user.email === email);

    return newMember.length > 0;
  };

  searchMemberHandler = (phone, email) => {
    const { createOrg } = this.props;
    /* eslint-disable */
    if ((email && Helpers.EmailSimple(email)) || phone) {
      if (!createOrg) {
        if (this.checkMember(email)) {
          this.displaySnackbar('error', 'Email already exists, try with another');
        } else {
          this.props.searchMemberAction({ phone, email });
        }
      } else {
        this.props.searchMemberAction({ phone, email });
      }
    } else if (!email && !phone) {
      this.displaySnackbar('error', 'Phone or Email Required');
    } else {
      this.displaySnackbar('error', 'Invalid Field');
    }
    /* eslint-enable */
  };

  registerMemberHandler = memberInfo => {
    const { admins, createOrg, members } = this.props;
    const error = this.validMemberInfo(memberInfo);
    if (!error) {
      if (createOrg) {
        const memberProfile = {
          ...memberInfo,
          birthday: memberInfo.birthday && yearMonthDayFormat(memberInfo.birthday),
        };
        this.props.update({
          members: [...members, memberProfile],
        });
        this.displaySnackbar('success', 'Admin selected');
        this.props.updateOrganizationAction({
          admins: [...admins, memberProfile],
        });
        this.props.disableRightDrawer();
      } else {
        this.props.update({
          members: [...members, memberInfo],
        });
        this.submitCandidates([...members, memberInfo]);
      }
    } else {
      this.displaySnackbar('error', error);
    }
  };

  memberFoundHandler = membersFound => {
    const { members, createOrg, admins: rdxAdmins } = this.props;
    const { selectedAdmin } = this.state;
    const admins = [];
    Object.values(selectedAdmin).forEach((adm, index) => {
      if (adm) {
        admins.push(membersFound[index]);
      }
    });
    if (createOrg) {
      this.displaySnackbar('success', 'Admin selected');
      this.props.updateOrganizationAction({ admins: [...rdxAdmins, ...admins] });
      this.props.disableRightDrawer();
    } else {
      this.submitCandidates(admins);
      this.props.update({
        members: [...members, ...membersFound],
        currentStep: 0,
        steps: [{ phone: '', email: '' }, {}, {}],
      });
    }
  };

  tryAgainHandler = () => {
    this.props.update({
      members: [],
      currentStep: 0,
      steps: [{ phone: '', email: '' }, {}, {}],
    });
    this.setState({
      selectedAdmin: [false, false],
    });
  };

  handleInputChange(index, { target }) {
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;
    if (target.type === 'phone' && target.value !== '' && target.value !== undefined) {
      const stringPhone = target.value.replace('+', '');
      if (Number(stringPhone.length) === 11) {
        this.setState({ validNumber: true });
      }
      if (Number(stringPhone.length) < 11) {
        this.setState({ validNumber: false });
      }
    }
    const updatedStep = this.props.steps;
    updatedStep[index] = { ...updatedStep[index], [name]: value };
    this.props.update({ steps: updatedStep });
    this.forceUpdate();
  }

  submitCandidates = async members => {
    const { submitting, selectedOrg } = this.props;
    const organizations = [selectedOrg.publicId];
    if (!submitting) {
      await this.props.submitMembersActions(organizations, members);
      this.props.update({
        members: [],
        currentStep: 0,
        steps: [{ phone: '', email: '' }, {}, {}],
        sentOrgAdmins: true,
      });
    }
  };
  /* eslint-disable */
  render() {
    const { currentStep, classes, steps, selectedOrg } = this.props;
    const { selectedAdmin, validNumber, isEnableButton } = this.state;
    const CurrentComponent = stepComponents[currentStep];
    const WindowHeight = window.innerHeight - 140;
    return (
      <Scrollbars autoHeight autoHide autoHeightMin={WindowHeight} autoHeightMax={WindowHeight}>
        <div className={classes.root}>
          {steps ? (
            <CurrentComponent
              nextStep={this.nextStep}
              stepValues={steps[currentStep]}
              inputChange={this.handleInputChange.bind(this, currentStep)}
              tryAgain={this.tryAgainHandler}
              organization={selectedOrg.name}
              selectAdmin={this.handleAdminSelect}
              selectedAdmin={selectedAdmin}
              validNumber={validNumber}
              isEnableButton={isEnableButton}
            />
          ) : (
            <div />
          )}
        </div>
      </Scrollbars>
    );
  }
  /* eslint-enable */
}
OrgInviteAdmin.propTypes = OrgInviteAdminPropType;

const mapStateToProps = (
  {
    membersReducer: { membersAdminOrgList },
    organizationReducer: { selectedOrg, admins },
    orgInviteAdminReducer,
  },
  ownProps,
) => ({
  selectedOrg,
  ...orgInviteAdminReducer,
  ...ownProps,
  admins,
  membersAdminOrgList,
});

const mapDispatchToProps = {
  create,
  clear,
  disableRightDrawer,
  getOrgAdmins,
  searchMemberAction,
  snackBarStatus,
  submitMembersActions,
  update,
  updateOrganizationAction,
};

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(OrgInviteAdmin),
);
