
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import './index.sass';
import '../LoadingScreen/index.sass';
import logo from '../LoadingScreen/ripple-loading.svg';


/**
 * Custom Generic Modal
 *
 * @param {boolean} status Status Loading
 *
 * behavior :
 *
 * import { statusLoading } from 'Actions/loadingActions';
 *
 * this.props.statusLoading(status) Open / Close
 *
 */

const styles = theme => ({
  progress: {
    margin: theme.spacing.unit * 2,
  },
});

const Loading = ({ isLoading }) => (
  <div className={`loading-container ${isLoading ? 'loading-container--enable' : 'loading-container--disable'}`}>
    <div className="container-center login">
      <div className="login__title">
        <p className="login__welcome">Loading...</p>
        <p className="logo-evangelus">EVANGELUS</p>
      </div>
      <img src={logo} className="login-load-logo" alt="Eva" />
    </div>
  </div>
);

Loading.propTypes = {
  isLoading: PropTypes.bool.isRequired,
};

const mS = ({ loadingReducer: { isLoading } }) => ({ isLoading });

export default withStyles(styles)(connect(mS, null)(Loading));
