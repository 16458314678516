import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { loginCheckSso } from '../../Actions/loginActions';
import { navigationControllerRedirect } from '../../Actions/navigationControllerActions';
import LoginPropTypes from '../../PropTypes/LoginPropType';
import './index.sass';
import logo from './ripple-loading.svg';

const queryString = require('query-string');

class LoginCheckSso extends Component {
  async componentDidMount() {
    const paramsString = this.props.location.search;
    const params = queryString.parse(paramsString);
    const { authToken, redirectTo } = params;

    await this.props.loginCheckSso(authToken, redirectTo);
  }

  render() {
    return (
      <div className="container-center login">
        <div className="login__title">
          <p className="login__welcome">Welcome to</p>
          <p className="logo-evangelus">EVANGELUS</p>
        </div>
        <img src={logo} className="login-load-logo" alt="Eva" />
      </div>
    );
  }
}
const mS = null;
const mD = {
  loginCheckSso,
  navigationControllerRedirect,
};
LoginCheckSso.propTypes = LoginPropTypes;
export default withRouter(connect(mS, mD)(LoginCheckSso));
