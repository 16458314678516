import React from 'react';
import { NavLink } from 'react-router-dom';
// import actionButtonSuperAdmin from '../../Theme/Icons/action-button-super-admin.svg';
import './index.sass';

const ActionButton = () => (
  <div>
    <NavLink
      className="float"
      id="menu-share"
      to="/super-admins"
    >
      {/* <img src={actionButtonSuperAdmin} alt="View superadmins" /> */}
      <i className="button-action-super-admin" />
    </NavLink>
  </div>
);

// this implementation could be used later

// class ActionButton extends Component {
//   state = {
//     active: false,
//   };

//   mouseEnter = e => {
//     e.preventDefault();
//     this.setState({ active: true });
//   };

//   mouseLeave = e => {
//     e.preventDefault();
//     this.setState({ active: false });
//   };

//   handleClick = e => {
//     let { active } = this.state;
//     e.preventDefault();
//     if (active === true) {
//       active = false;
//     } else {
//       active = true;
//     }
//     this.setState({ active });
//   };

//   render() {
//     const { active } = this.state;
//     return (
//       <div>
//         <NavLink
//           className="float"
//           id="menu-share"
//           to="/super-admins"
//         >
//           <i className="button-action" />
//         </NavLink>
//         <ul
//           className={`action-menu ${active ? 'active' : 'inactive'}`}
//           onMouseEnter={this.mouseEnter}
//           onMouseLeave={this.mouseLeave}
//         >
//           <li>
//             <a href="/">
//               <i className="help-action" />
//             </a>
//           </li>
//           <li>
//             <a href="/">
//               <i className="add-action" />
//             </a>
//           </li>
//           <li>
//             <a href="/">
//               <i className="message-action" />
//             </a>
//           </li>
//         </ul>
//       </div>
//     );
//   }
// }

export default ActionButton;
