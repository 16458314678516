import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import SimpleModalPropType from '../../PropTypes/SimpleModalPropType';
import './index.sass';

const SimpleModal = ({
  title,
  content,
  open,
  handleClose,
  className,
  scroll,
}) => (
  <div>
    <Dialog
      open={open}
      onClose={handleClose}
      scroll={scroll}
      labelledby="scroll-dialog-title"
      className={className}
    >
      <div className="close-block-icon">
        <IconButton onClick={handleClose}>
          <ClearIcon />
        </IconButton>
      </div>
      <DialogTitle id="scroll-dialog-title" className="modal-title">
        <div>{title}</div>
      </DialogTitle>
      <DialogContent>
        <DialogContentText className="modal-content">
          {content}
        </DialogContentText>
      </DialogContent>
    </Dialog>
  </div>
);

SimpleModal.propTypes = SimpleModalPropType;

export default SimpleModal;
