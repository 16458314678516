import Members from '../Api/Members';

export const ACTIONS = {
  GET: 'members/get',
  FILTER: 'members/filter',
  ADD: 'members/add',
  SELECTED: 'members/selected',
  UPDATE_LIST: 'members/update',
  ERROR: 'members/error',
  CLEAR: 'members/clear',
  CLEAR_SELECTED: 'members/clear_selected',
  SET_LOADING: 'members/set_loading',
  GET_TOTAL_MEMBERS: 'members/get_total_members',
};
