import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import mobiscroll from '../../lib/mobiscroll/js/mobiscroll.react.min';
import '../../lib/mobiscroll/css/mobiscroll.react.min.css';
import styles from './styles';

class MobiscrollSelect extends Component {
  constructor(props) {
    super(props);

    this.mbscInst = null;
  }

  saveMbscInst = (e, inst) => {
    this.mbscInst = inst;
  }

  showMbsc = () => {
    this.mbscInst.show();
  }

  render() {
    const {
      classes,
      centerText,
      // if an icon is equal to 'none' then it wont be displayed
      iconLeft,
      altIconLeft,
      iconLeftOnClick,
      iconRight,
      altIconRight,
      iconRightOnClick,
      ...props
    } = this.props;

    let inputIcons = '';
    if (iconLeft === 'none' && iconRight === 'none') {
      inputIcons = 'twoIcons';
    } else if (iconLeft === 'none' || iconRight === 'none') {
      inputIcons = 'oneIcon';
    }
    const centerTextClass = centerText ? 'center' : '';
    const iconLeftOnClickHandler = iconLeftOnClick || this.showMbsc;
    const iconRightOnClickHandler = iconRightOnClick || this.showMbsc;

    return (
      <div className={`${classes.container} custom-select-container`}>
        {iconLeft !== 'none' &&
          <div
            className={`${classes.icon} left-icon`}
            onClick={iconLeftOnClickHandler}
            role="presentation" // this role is just to prevent eslint check, this could be improved
          >
            <img src={iconLeft || ''} alt={altIconLeft || ''} />
          </div>
        }

        <mobiscroll.Select
          theme="ios"
          setText="Select"
          inputClass={`mbsc-custom-select-input ${classes.selectInput} ${centerTextClass} ${inputIcons}`}
          cssClass={`mbsc-custom-select ${classes.select}`}
          onInit={this.saveMbscInst}
          {...props}
        />

        {iconRight !== 'none' &&
          <div
            className={`${classes.icon} right-icon`}
            onClick={iconRightOnClickHandler}
            role="presentation" // this role is just to prevent eslint check, this could be improved
          >
            <img src={iconRight || ''} alt={altIconRight || ''} />
          </div>
        }
      </div>
    );
  }
}

MobiscrollSelect.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  iconLeft: PropTypes.string,
  altIconLeft: PropTypes.string,
  iconLeftOnClick: PropTypes.func,
  iconRight: PropTypes.string,
  altIconRight: PropTypes.string,
  iconRightOnClick: PropTypes.func,
  centerText: PropTypes.bool,
};

MobiscrollSelect.defaultProps = {
  iconLeft: '',
  altIconLeft: '',
  iconLeftOnClick: undefined,
  iconRight: '',
  altIconRight: '',
  iconRightOnClick: undefined,
  centerText: false,
};

export default withStyles(styles)(MobiscrollSelect);
