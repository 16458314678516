import User from '../Api/User';
import snackBarStatus from './snackbarActions';
import findMessage from '../Helpers/utiliy';

export const ACTIONS = {
  GET_USER_PROFILE: 'user/get_profile',
  GET_MEMBER_PROFILE: 'user/get_member_profile',
  SELECT_ORG: 'user/select_organization',
};

export const getUserProfile = () => {
  const token = localStorage.getItem('token');
  return async dispatch => {
    try {
      const {
        data: { code, data },
      } = await User.getProfile(token);
      if (code === 200) {
        dispatch({
          type: ACTIONS.GET_USER_PROFILE,
          payload: data,
        });
      } else {
        throw new Error('Could not get user profile');
      }
    } catch (error) {
      snackBarStatus({
        payload: {
          title: findMessage(error),
          type: 'error',
          enable: true,
        },
      })(dispatch);
    }
  };
};
export const getMemberProfile = () => {
  const token = localStorage.getItem('token');
  return async dispatch => {
    try {
      const {
        data: {
          data: { public_id },
        },
      } = await User.getMember(token);
      dispatch({
        type: ACTIONS.GET_MEMBER_PROFILE,
        payload: public_id,
      });
    } catch (error) {
      snackBarStatus({
        payload: {
          title: findMessage(error),
          type: 'error',
          enable: true,
        },
      })(dispatch);
    }
  };
};
export function selectOrganization(organization) {
  return dispatch => {
    dispatch({ type: ACTIONS.SELECT_ORG, payload: organization });
  };
}
