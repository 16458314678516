import ACTIONS from '../Actions/headerBarActions';

const initialState = {
  customTitle: null,
}

function headerBarReducer(state = initialState, action) {
  const { payload, type } = action;
  switch (type) {
    case ACTIONS.ACTIVATE_CUSTOM_TITLE:
      return {
        ...state,
        customTitle: payload.customTitle,
      };
    default:
      return state;
  }
}

export default headerBarReducer;
