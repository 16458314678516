import React from 'react';
import MainLayoutPropType from '../../PropTypes/MainLayoutPropType';

const MainLayout = ({ children }) => (
  <div className="main-layout-eva">{children}</div>
);

MainLayout.propTypes = MainLayoutPropType;

export default MainLayout;
