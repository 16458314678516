import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
/**
 *
 * Default left side of the title bar.
 * @param {Object}    props
 * @param {Function}  props.handleMenuToggle Function to activate the iframe of the SSO
 * @param {Boolean}   props.showMenu Used to know whether the iframe menu should be shown or
 * not.
 */
export const DefaultTitleBar = ({
  handleMenuToggle,
  showMenu = false,
  classes,
}) =>
  (
    <div className={classes.iframeAndTitle}>
      <div className="iframe-button">
        <input
          type="checkbox"
          id="drawer-toggle-v2"
          name="drawer-toggle"
          checked={showMenu}
          onChange={handleMenuToggle}
        />
        <label
          htmlFor="drawer-toggle"
          id="drawer-toggle-label-v2"
          className={showMenu ? 'iframe-active' : ''}
        />
      </div>
      <header className="header-evangelus-v2">EVANGELUS</header>
    </div>
  );

export default withStyles(styles)(DefaultTitleBar);
