import { ACTIONS } from '../Actions/parishActions';
// fromGroup = true (action from a group) and fromGroup = false (action from the compose button)
const initialState = {
  parishes: [],
  paginator: [],
  loading: false,
  selectedParish: [],
};
const parishReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.GET:
      return {
        ...state,
        ...action.payload,
      };
    case ACTIONS.SELECTED_PARISH:
      return {
        ...state,
        selectedOrg: action.payload,
      };
    default:
      return state;
  }
};
export default parishReducer;
