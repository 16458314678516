import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import mobiscroll from '../../lib/mobiscroll/js/mobiscroll.react.min';
import styles from './styles';
import EvaTooltip from '../EvaTooltip';

class DatePicker extends PureComponent {
  constructor(props) {
    super(props);

    // inputRef used to anchor the bubble to the input container
    // centering the bubble in the process
    this.inputRef = React.createRef();
  }

  componentDidMount() {
    // the input ref is not set on first render
    // which is why is needed to re-render
    this.forceUpdate();
  }

  render() {
    const {
      placeholder,
      handleSelection,
      value,
      classes,
      centerText,
      // if an icon is equal to 'none' then it wont be displayed
      iconLeft,
      altIconLeft,
      iconRight,
      altIconRight,
      ...props
    } = this.props;

    let inputIcons = '';

    if (iconLeft === 'none' && iconRight === 'none') {
      inputIcons = 'twoIcons';
    } else if (iconLeft === 'none' || iconRight === 'none') {
      inputIcons = 'oneIcon';
    }
    // null value is not accepted, therefore undefined is set isntead
    const inputRef = this.inputRef.current ? this.inputRef.current : undefined;
    const centerTextClass = centerText ? 'center' : '';

    return (
      <div className={`${classes.container} datepicker-container`} ref={this.inputRef}>
        {iconLeft !== 'none' &&
          <div className={`${classes.icon} left-icon`}>
            <img src={iconLeft || ''} alt={altIconLeft || ''} />
          </div>
        }

        <mobiscroll.Calendar
          value={value}
          theme="ios"
          onSet={handleSelection}
          cssClass={`mbsc-custom-datepicker ${classes.calendar}`}
          anchor={inputRef}
          display="bubble" // can be overwritten using responsive
          {...props}
        >
          <input
            className={`datepicker-custom-input ${classes.input} ${inputIcons} ${centerTextClass}`}
            placeholder={placeholder}
          />
        </mobiscroll.Calendar>

        {iconRight !== 'none' &&
          <div className={`${classes.icon} ${classes.rightIcon} right-icon`}>
            <EvaTooltip title="Age is used for the protection of minors">
              <img src={iconRight || ''} alt={altIconRight || ''} />
            </EvaTooltip>
          </div>
        }
      </div>
    );
  }
}

DatePicker.propTypes = {
  placeholder: PropTypes.string,
  handleSelection: PropTypes.func.isRequired,
  value: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  disabled: PropTypes.bool.isRequired,
  iconLeft: PropTypes.string,
  altIconLeft: PropTypes.string,
  iconRight: PropTypes.string,
  altIconRight: PropTypes.string,
  centerText: PropTypes.bool,
};

DatePicker.defaultProps = {
  placeholder: '',
  value: null,
  iconLeft: '',
  altIconLeft: '',
  iconRight: '',
  altIconRight: '',
  centerText: false,
};

export default withStyles(styles)(DatePicker);
