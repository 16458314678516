import AXIOS from '../Config/AxiosConfig';
import headers from './Helper';

const v2baseURL = '/v2/eva';

const Invitation = {
  getMember() {
    return AXIOS.get('/member/profile-information', {
      headers: headers(),
    });
  },
  inviteMembers(data) {
    return AXIOS.post('/admin-panel/invitation/multiple-organizations-and-members', data, {
      headers: headers(),
    });
  },
  getMembersToGroupAdd(data) {
    return AXIOS.post(`${v2baseURL}/member/search`,
      data,
      { headers: headers() },
    );
  },

};

export default Invitation;
