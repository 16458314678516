import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import NotFound from '../Components/NotFound';
import Main from '../Containers/Main';
import Logout from '../Components/Logout';
import ForgotPassword from '../Components/ForgotPassword';
import ResetCode from '../Components/ResetCode';
import ChangePassword from '../Components/ChangePassword';
import ForgotPasswordMessage from '../Components/ForgotPasswordMessage';
import MainLayout from '../Containers/MainLayout';
import UserFormProfile from '../Components/UserFormProfile';
import UnauthorizedPage from '../Components/SessionAuthorization';
import { populateLoginDataFromStorage } from '../Actions/loginActions';
import { setupInterceptors } from '../Actions/authHttpActions';
import AuthPropTypes from '../PropTypes/AuthPropType';
import OrgContainer from '../Containers/OrgContainer';
import ShortCodes from '../Containers/ShortCodes';
import SingleOrg from '../Containers/SingleOrg';
import AdminPromotion from '../Components/AdminPromotion';
import PermissionsValidator from '../Components/PermissionsValidator';
import { ENV } from './env';
import LoginCheckSso from '../Components/LoginCheckSso';
import SuperAdmin from '../Containers/SuperAdmin';
import Statistic from '../Containers/Statistic';

const requireAuth = (Comp, props) => {
  const token = localStorage.getItem('token');
  const expiration = localStorage.getItem('expiration');

  if (token && expiration && new Date(expiration).getTime() > Date.now()) {
    return <Comp {...props} />;
  }
  return <Redirect to="/login" />;
};
class Routes extends Component {
  constructor(props) {
    super(props);

    // set permissions data on redux store on application instantiation
    // for the permissions validator
    this.props.populateLoginDataFromStorage();
  }

  componentDidMount() {
    this.props.setupInterceptors();
  }
  render() {
    return (
      <Router {...this.props}>
        <Main>
          <PermissionsValidator />
          <MainLayout>
            <Switch>
              <Route exact path="/" render={props => requireAuth(OrgContainer, props)} />
              <Route
                exact
                path="/login"
                render={() => {
                  const redirect = encodeURIComponent(`${window.location.origin}/login-check`);
                  window.location.href = `${ENV.API_SSO_URL}/proxy/secure?redirect=${redirect}`;
                  return '';
                }}
              />
              <Route exact path="/login-check" component={LoginCheckSso} />
              <Route exact path="/logout" component={Logout} />
              <Route exact path="/request-password" component={ForgotPassword} />
              <Route exact path="/reset-password" component={ResetCode} />
              <Route exact path="/forgot-password/:token" component={ChangePassword} />
              <Route exact path="/complete-profile/:groupId/:token" component={UserFormProfile} />
              <Route exact path="/request-password-email" component={ForgotPasswordMessage} />
              <Route exact path="/unauthorized" component={UnauthorizedPage} />
              <Route exact path="/main" render={props => requireAuth(OrgContainer, props)} />
              <Route
                exact
                path="/organizations"
                render={props => requireAuth(OrgContainer, props)}
              />
              <Route exact path="/short-codes" render={props => requireAuth(ShortCodes, props)} />
              <Route exact path="/statistic" render={props => requireAuth(Statistic, props)} />
              <Route exact path="/super-admins" render={props => requireAuth(SuperAdmin, props)} />
              <Route
                exact
                path="/organizations/:orgId"
                render={props => requireAuth(SingleOrg, props)}
              />
              <Route exact path="/admin/:groupId/promote/:promoteId" component={AdminPromotion} />
              <Route component={NotFound} />
            </Switch>
          </MainLayout>
        </Main>
      </Router>
    );
  }
}

const mS = state => ({
  authCode: state.authHttpReducer.response_code.code,
});

const mD = {
  setupInterceptors,
  populateLoginDataFromStorage,
};
Routes.propTypes = AuthPropTypes;
export default connect(mS, mD)(Routes);
