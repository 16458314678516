import { ACTIONS } from '../Actions/userFormProfileActions';

const initialState = {
  firstName: '',
  lastName: '',
  birthDate: '',
  phoneNumber: '',
  email: '',
  error: '',
  password: '',
  groupId: '',
  token: '',
  previousToken: '',
  member: {},
  group: {},
  code: '',
  street: '',
  city: '',
  province: '',
  country: [],
  postalCode: '',
  selectedCountry: 'Country',
  saved: false,
};

const userFormProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.UPDATE:
      return {
        ...state,
        ...action.payload,
      };
    case ACTIONS.UPDATE_DATA:
      return {
        ...state,
        ...action.payload,
      };
    case ACTIONS.GET_COUNTRIES:
      return {
        ...state,
        country: action.payload,
      };
    case ACTIONS.GET:
      return {
        ...state,
        firstName: action.payload.member.first_name,
        lastName: action.payload.member.last_name,
        birthDate: action.payload.member.birth_date,
        phoneNumber: action.payload.member.main_phone,
        email: action.payload.member.user.email,
        groupName: action.payload.group.name,
        groupId: action.payload.group.public_id,
        street: action.payload.member.reference_address,
        city: action.payload.member.city_text,
        province: action.payload.member.state_text,
        postalCode: action.payload.member.postal_code,
        error: action.payload.error,
        token: action.payload.token,
        code: action.payload.code,
      };
    case ACTIONS.SET_TOKEN:
      return {
        ...state,
        token: action.payload,
      };
    case ACTIONS.SET_OLD_TOKEN:
      return {
        ...state,
        previousToken: action.payload,
      };
    case ACTIONS.SET_SAVED:
      return {
        ...state,
        saved: action.payload,
      };
    default:
      return state;
  }
};

export default userFormProfileReducer;
