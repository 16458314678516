export const ACTIONS = {
  CHANGE_TAB: 'menu/change_tab',
};

export function changeTab(selectedTab, value) {
  return dispatch => {
    dispatch({
      type: ACTIONS.CHANGE_TAB,
      payload: { element: selectedTab, tab: value },
    });
  };
}
