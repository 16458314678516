import React from 'react';
import SuccessMessagePropType from '../../PropTypes/SuccessPropType';
import './index.sass';

const GenericMessage = props => (
  <div className={`form-message ${props.type}`}>
    <p className="form-text">
      <span>{props.title} </span>
      {props.message}
    </p>
    <button
      onClick={() => {
        props.hideMessage();
      }}
      className="form-close"
      type="button"
      aria-label="Close"
    >
      <i className="close-icon" />
    </button>
  </div>
);

GenericMessage.propTypes = SuccessMessagePropType;

export default GenericMessage;
