import React from 'react';
import Grid from '@material-ui/core/Grid';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import { withStyles } from '@material-ui/core/styles';
import PhoneInputPropType from '../../PropTypes/PhoneInputPropType';
import './index.sass';
import { styles } from './styles';
import phoneIcon from '../../Theme/Icons/phone.svg';
import CustomInputPhone from './CustomInputPhone';

const InputPhoneV1 = ({
  inputClassName,
  onInputPhoneChange,
  name,
  placeholder = '',
  value = '',
}) => (
  <Grid container spacing={0}>
    <Grid item xs={1}>
      <div className="phone-icon" />
    </Grid>
    <Grid item xs={8} sm={7} md={11}>
      <PhoneInput
        inputClassName={inputClassName}
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={onInputPhoneChange}
        country="US"
        limitMaxLength
        autoComplete="off"
      />
    </Grid>
  </Grid>
);

const InputPhoneV2 = ({
  onInputPhoneChange,
  name,
  placeholder = '',
  value = '',
  classes,
  style,
}) => (
  <div className={classes.root} style={style}>
    <div className="icon">
      <img src={phoneIcon} alt="phone input" />
    </div>
    <div className="input">
      <PhoneInput
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={onInputPhoneChange}
        country="US"
        limitMaxLength
        autoComplete="off"
      />
    </div>
  </div>
);

const InputPhone = withStyles(styles)(InputPhoneV2);

InputPhoneV1.propTypes = PhoneInputPropType;
InputPhoneV2.propTypes = PhoneInputPropType;

export default InputPhoneV1;
export { InputPhone, CustomInputPhone };
