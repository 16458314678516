export const ACTIONS = {
  ENABLE_SHORT: 'rightDrawerShort/enable',
  TOGGLE_ENFORCE_FOCUS: 'rightDrawerShort/toggle_enforce_focus',
  CHANGE_DRAWER_TITLE: 'rightDrawerShort/change_title',
};

export function enableRightDrawer(value) {
  return dispatch => {
    dispatch({ type: ACTIONS.ENABLE_SHORT, ...value });
    if (window.innerWidth >= 1024) {
      document.querySelector('.right-drawer-edit-member > .drawer-md-up').style.display = 'block';
    }
  };
}

export const setEnforceFocus = enforceFocus => ({
  type: ACTIONS.TOGGLE_ENFORCE_FOCUS,
  payload: { enforceFocus },
});

export const setTitle = newTitle => ({
  type: ACTIONS.CHANGE_DRAWER_TITLE,
  payload: { title: newTitle },
});
