/* eslint-disable */
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import './index.css';
import CreateStore from './Store/Store';
import Routes from './Config/Routes';
import registerServiceWorker from './registerServiceWorker';
import JssProvider from 'react-jss/lib/JssProvider';
import { createGenerateClassName } from '@material-ui/core/styles';

const store = CreateStore();
const generateClassName = createGenerateClassName({
  dangerouslyUseGlobalCSS: true,
  productionPrefix: 'diocesan-admin',
});

ReactDOM.render(
  <JssProvider generateClassName={generateClassName}>
    <div>
      <Provider store={store}>
        <Routes />
      </Provider>
    </div>
  </JssProvider>,
  document.getElementById('root'),
);
registerServiceWorker();
