import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import tooltipStyle from './tooltipStyle';

const styles = theme => tooltipStyle(theme);

const EvaTooltip = ({ children, title, placement = 'bottom' }) => <Tooltip title={title} placement={placement}>{children}</Tooltip>;

EvaTooltip.propTypes = {
  children: PropTypes.element.isRequired,
  title: PropTypes.string.isRequired,
  placement: PropTypes.string.isRequired,
};

export default withStyles(styles)(EvaTooltip);
