import PropTypes from 'prop-types';

const PaginationPropType = {
  nextPage: PropTypes.number.isRequired,
  previousPage: PropTypes.number.isRequired,
  totalItems: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
};

export default PaginationPropType;
