import { ACTIONS } from '../Actions/organizationsActions';

const initialState = {
  organizations: [],
  params: {
    term: '',
    page: null,
    sort: null,
    sortOrder: null,
    limit: null,
  },
  isLoading: false,
  pagination: {
    currentPage: null,
    nextPage: null,
    previousPage: null,
    totalPages: null,
    total: null,
  },
};

const organizationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.LOAD:
      return {
        ...state,
        ...action.payload,
      };

    case ACTIONS.LIMIT_UPDATE:
      return {
        ...state,
        params: {
          ...state.params,
          limit: action.payload,
        },
      };

    case ACTIONS.PAGE_UPDATE:
      return {
        ...state,
        params: {
          ...state.params,
          page: action.payload,
        },
      };

    case ACTIONS.LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    default:
      return state;
  }
};

export default organizationsReducer;
