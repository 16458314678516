import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  paperWidthXs: {
    maxWidth: '620px',
    '@media only screen and (min-width: 375px) and (max-width: 414px)': {
      maxWidth: '327px',
    },
    '@media only screen and (min-width: 768px) and (max-width: 1023px)': {
      maxWidth: '568px',
    },
  },
};

const ModalConfirm = ({ handleClose, statusDialog, handleConfirm, message, classes }) => (
  <Dialog
    open={statusDialog}
    onClose={handleClose}
    className="revoke-access"
    style={{
      backgroundColor: 'transparent',
      borderRadius: '10px',
    }}
    classes={{ paperWidthSm: classes.paperWidthXs }}
  >
    <DialogContent className="revoke-access-modal-message">
      <div className="attention-Settings"> {message} </div>
      <div className="modal-actions">
        <button onClick={() => handleConfirm()} className="button">
          Yes, I want to revoke this access
        </button>
        <button onClick={() => handleClose()} className="button">
          {"No, I don't want to revoke this access"}
        </button>
      </div>
    </DialogContent>
  </Dialog>
);

ModalConfirm.propTypes = {
  handleClose: PropTypes.func.isRequired,
  statusDialog: PropTypes.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
  classes: PropTypes.isRequired,
};

export default withStyles(styles)(ModalConfirm);
