import { combineReducers } from 'redux';
import authHttpReducer from './AuthHttpReducer';
import filterMembersReducer from './filterMembersReducer';
import loginReducer from './loginReducer';
import mainTabsReducer from './mainTabsReducer';
import membersReducer from './membersReducer';
import middleMenuReducer from './middleMenuReducer';
import navigationControllerReducer from './navigationControllerReducer';
import orgInviteAdminReducer from './orgInviteAdminReducer';
import organizationsReducer from './organizationsReducer';
import organizationReducer from './organizationReducer';
import parishReducer from './parishReducer';
import passwordReducer from './passwordReducer';
import privacyPolicyReducer from './privacyPolicyReducer';
import rightDrawerReducer from './rightDrawerReducer';
import rightDrawerShortReducer from './rightDrawerShortReducer';
import settingsReducer from './settingsReducer';
import simpleModalReducer from './simpleModalReducer';
import snackBarReducer from './snackBarReducer';
import topDrawerReducer from './topDrawerReducer';
import userFormProfileReducer from './userFormProfileReducer';
import userProfileReducer from './userProfileReducer';
import superUserReducer from './SuperUserReducer';
import mainLoadingReducer from './mainLoadingReducer';
import loadingReducer from '../Reducers/loadingReducer';
import headerBarReducer from './headerBarReducer';
import keywordReducer from './keywordReducer';

// add reducers in alphabetical order
const rootReducer = combineReducers({
  authHttpReducer,
  filterMembersReducer,
  headerBarReducer,
  keywordReducer,
  loadingReducer,
  loginReducer,
  mainLoadingReducer,
  mainTabsReducer,
  membersReducer,
  middleMenuReducer,
  navigationControllerReducer,
  orgInviteAdminReducer,
  organizationsReducer,
  organizationReducer,
  parishReducer,
  passwordReducer,
  privacyPolicyReducer,
  rightDrawerReducer,
  rightDrawerShortReducer,
  settingsReducer,
  simpleModalReducer,
  snackBarReducer,
  superUserReducer,
  topDrawerReducer,
  userFormProfileReducer,
  userProfileReducer,
});

export default rootReducer;
