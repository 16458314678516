import { ACTIONS } from '../Actions/orgInviteAdminActions';

const initialState = {
  steps: [{ phone: '', email: '' }, {}, {}],
  currentStep: 0,
  members: [],
  permissionToAdd: false,
  sentOrgAdmins: false,
};

const orgInviteAdminReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.CREATE:
      return action.payload;
    case ACTIONS.CLEAR:
      return { ...initialState, steps: [{ phone: '', email: '' }, {}, {}] };
    case ACTIONS.UPDATE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export default orgInviteAdminReducer;
