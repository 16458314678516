import is from 'is_js';
import queryString from 'query-string';
import Organization from '../Api/Organization';
import snackBarStatus from './snackbarActions';
import { changeStatusLoading } from './loadingActions';
import findMessage from '../Helpers/utiliy';

import { EVA_FRONT_URL } from '../Config/env';

export const ACTIONS = {
  CLEAR_DISCOVER_SINGLE_SUGGESTION: 'organization/clear_discover_single_suggestion',
  CLEAR_DISCOVER_SUGGESTIONS: 'organization/clear_discover_suggestions',
  CLEAR_ORGANIZATION_FORM: 'organization/clear_form',
  CLEAR_SELECTED_ORG: 'organization/clear_selected_organization',
  CREATE_ORG: 'organization/create',
  ERROR: 'organization/error',
  GET: 'organization/get',
  GET_ORG_TYPES: 'organization/get_organization_types',
  HYDRATE_INPUTS_DISCOVER_ORGANIZATIONS: 'organization/hydrate_input_discover_organizations',
  DEHYDRATE_INPUTS_DISCOVER_ORGANIZATIONS: 'organization/dehydrate_input_discover_organizations',
  MAYBE_UPDATE_DISCOVER_ORGANIZATIONS: 'organization/maybe_update_discover_organizations',
  SEARCH_DISCOVER_ORGANIZATIONS: 'organization/search_discover_organizations',
  SET_DISCOVER_ORGANIZATIONS: 'organization/set_discover_organizations',
  CLEAR_DISCOVER_ORGANIZATIONS: 'organization/clear_discover_organizations',
  SEARCH_DISCOVER_UPDATE_VALUE: 'organization/search_discover_update_value',
  SEARCH_DISCOVER_UPDATE_VALUE_ID: 'organization/search_discover_update_value_id',
  STATUS_WINDOW_MESSAGE: 'organization/status_windows_message',
  SELECTED_ORG: 'organization/selected_org',
  SHOW_SUCCESS_RESPONSE: 'organization/show_success_response',
  UPDATE: 'organization/update',
  UPDATE_DATA: 'organization/update_data',
  UPDATE_TYPE: 'organization/update_type',
  UPDATE_ORG_CUSTOM_URL: 'organization/update_org_custom_url',
  UPLOAD_PICTURE: 'organization/upload_picture',
  UPLOAD_UPD_PICTURE: 'organization/upload_upd_picture',
  UPDATE_IS_DELETE: 'organization/update_is_delete',
  UPDATE_LINK_MY_PARISH_APP: 'organization/update_link_my_parish_app',
  UPDATE_SUSPENDED: 'organization/update_suspended_until',
  UPDATE_LITE_MODE: 'organization/update_lite_mode',
  OPEN_IN_EVANGELUS: 'organization/open_in_evangelus',
  REFRESH_ORG_KEYWORD: 'organization/refresh_org_keyword',
};

export function clearDiscoverSuggestions() {
  return dispatch => {
    dispatch({
      type: ACTIONS.CLEAR_DISCOVER_SUGGESTIONS,
    });
  };
}

export const clearOrganizationForm = () => ({
  type: ACTIONS.CLEAR_ORGANIZATION_FORM,
});

export function createOrganization(value) {
  const { admins } = value;
  /* eslint-disable */
  const memberStructure = admins.map(member => ({
    first_name: member.firstName || '',
    last_name: member.lastName || '',
    birth_date: member.birthday || '',
    public_id: member.publicId || '',
    user: {
      username: member.email || '',
      email: member.email || '',
      plain_password: '1234',
    },
    phones: member.phone
      ? [
        {
          description: 'main',
          country_code: '',
          phone_number: member.phone,
          is_default: true,
        },
      ]
      : [],
  }));
  /* eslint-enable */
  const organizationInfo = { ...value, members: memberStructure };
  return dispatch =>
    Organization.createOrganization({ ...organizationInfo })
      .then(response => {
        dispatch({
          type: ACTIONS.CREATE_ORG,
          payload: response.data.data,
        });
        if (response.data && response.data.code === 200) {
          dispatch({
            type: ACTIONS.CLEAR_ORGANIZATION_FORM,
          });
        }
        return response.data;
      })
      .catch(error => {
        dispatch({
          type: ACTIONS.ERROR,
          payload: findMessage(error),
        });
        snackBarStatus({
          payload: {
            title: error.message,
            type: 'error',
            enable: true,
          },
        })(dispatch);

        return error;
      });
}

export const clearSelectedOrganization = () => ({
  type: ACTIONS.CLEAR_SELECTED_ORG,
});

export function getOrganizationTypes() {
  return dispatch => {
    Organization.getTypeOrganization()
      .then(response => {
        dispatch({
          type: ACTIONS.GET_ORG_TYPES,
          payload: response.data.data,
        });
      })
      .catch(err => {
        if (err.response) {
          dispatch({
            type: ACTIONS.ERROR,
            payload: {
              error: err,
            },
          });
        }
      });
  };
}

export function hydrateInputsDiscover(selectedSuggestionOrg) {
  return dispatch => {
    dispatch({
      type: ACTIONS.HYDRATE_INPUTS_DISCOVER_ORGANIZATIONS,
      payload: { selectedOrg: selectedSuggestionOrg },
    });
  };
}

export const dehydrateInputsDiscover = () => ({
  type: ACTIONS.DEHYDRATE_INPUTS_DISCOVER_ORGANIZATIONS,
});

export function searchDiscoverOrganizations(searchTerm, organizationType, sortField, sortOrder) {
  /* eslint-disable */
  return dispatch =>
    Organization.getDiscoverOrganization(searchTerm, organizationType, sortField, sortOrder)
      .then(response => {
        dispatch({
          type: ACTIONS.SEARCH_DISCOVER_ORGANIZATIONS,
          payload: response.data.data.data,
        });
      })
      .catch(error => {
        snackBarStatus({
          payload: {
            title:
              error.response === 401
                ? 'invalid credentials'
                : error.response === 404
                  ? 'organization not found'
                  : findMessage(error),
            type: 'error',
            enable: true,
          },
        })(dispatch);
      });
  /* eslint-enable */
}

export function setDiscoverOrganizations(suggestions) {
  return dispatch =>
    dispatch({
      type: ACTIONS.SET_DISCOVER_ORGANIZATIONS,
      payload: { suggestions },
    });
}

export function getFullOrganization(publicId) {
  return async dispatch => {
    try {
      const {
        data: { data: orgObject },
      } = await Organization.getFullOrganization(publicId);

      const orgParent = is.object(orgObject.organizationParent) ? orgObject.organizationParent : {};
      const address = is.object(orgObject.address) ? orgObject.address : {};
      const value = {
        selectedOrg: orgObject,
        name: orgObject.name,
        disableWelcomeEmail: orgObject.disableWelcomeEmail,
        disableWelcomeText: orgObject.disableWelcomeText,
        bulletinSend: orgObject.bulletinSend,
        bulletinSendAt: orgObject.bulletinSendAt,
        shortName: orgObject.shortName,
        linkToMyParishApp: orgObject.linkToMyParishApp,
        linkToCamino: orgObject.linkToCamino,
        lite: orgObject.lite,
        description: orgObject.description,
        websiteUrl: orgObject.websiteUrl,
        keyword: orgObject.keyword,
        organizationType: orgObject.organizationType,
        phoneNumber: orgObject.phoneNumber,
        timeZone: orgObject.timeZone,
        tollFreeNumber: orgObject.tollFreeNumber,
        mainPictureUrl: orgObject.mainPictureUrl,
        addressPublicId: address.publicId,
        streetAddress: address.referenceAddress,
        secondStreetAddress: address.secondReferenceAddress,
        postalCode: address.postalCode,
        state: address.stateText,
        city: address.cityText,
        country: address.countryText,
        discoverOrganizationSuggestionValue: orgObject.name,
        discoverOrganizationSuggestionValueId: orgObject.ssoOrganizationId,
        parentOrganizationName: orgParent.name || '',
        parentOrganizationSsoId: orgParent.id || null,
        parentOrganizationEvaId: (orgParent.organizationApp
          && orgParent.organizationApp.evaId) ? orgParent.organizationApp.evaId : null,
        // organizationParent: (orgParent.length && orgParent.name) || '',
        status: orgObject.status || '',
        isDeleteArchivedMessageActivated: orgObject.isDeleteArchivedMessageActivated,
        isBilingualSpanish: orgObject.isBilingualSpanish,
        isSyncParish: orgObject.isSyncParish,
        isMemberPreferenceAvailable: orgObject.isMemberPreferenceAvailable,
      };

      dispatch({
        type: ACTIONS.SELECTED_ORG,
        payload: value,
      });

      return orgObject;
    } catch (err) {
      if (err.response) {
        dispatch({
          type: ACTIONS.ERROR,
          payload: {
            error: err,
          },
        });
      }
      return err;
    }
  };
}

export const statusWindowsMessage = values => dispatch => {
  dispatch({
    type: ACTIONS.STATUS_WINDOW_MESSAGE,
    payload: values,
  });
};

export const updateOrganizationAction = data => ({
  type: ACTIONS.UPDATE,
  payload: data,
});

export function updateData(data) {
  return dispatch => {
    dispatch({
      type: ACTIONS.UPDATE_DATA,
      payload: data
    });
  };
}

export const verifyKeywordForOrg = (keyword, orgPublicId) => async dispatch => {
  try {
    const response = await Organization.verifyKeywordForOrg(keyword, orgPublicId);
    snackBarStatus({
      payload: {
        title: 'Keyword available.',
        type: 'success',
        enable: true,
      },
    })(dispatch);
    return response;
  } catch (error) {
    if (error.response && error.response.status === 400) {
      snackBarStatus({
        payload: {
          title: 'Keyword not available.',
          type: 'error',
          enable: true,
        },
      })(dispatch);
    } else {
      snackBarStatus({
        payload: {
          title: error.message,
          type: 'error',
          enable: true,
        },
      })(dispatch);
    }
    return error;
  }
};

export const verifyKeyword = keyword => async dispatch => {
  try {
    const response = await Organization.verifyKeyword(keyword);
    snackBarStatus({
      payload: {
        title: 'Keyword not available.',
        type: 'error',
        enable: true,
      },
    })(dispatch);
    return response;
  } catch (error) {
    if (error.response && error.response.status === 404) {
      snackBarStatus({
        payload: {
          title: 'Keyword available.',
          type: 'success',
          enable: true,
        },
      })(dispatch);
    } else {
      snackBarStatus({
        payload: {
          title: error.message,
          type: 'error',
          enable: true,
        },
      })(dispatch);
    }
    return error;
  }
};

export const linkOrgToMyParishAppOnServer = data => async dispatch => {
  if (!data.orgPublicId || undefined === data.linkToMyParishApp) {
    console.log('Insufficient data to change the linkToMyParishApp property: ', data);
  } else {
    changeStatusLoading(true)(dispatch);
    return Organization.linkToMyParishApp(data.orgPublicId, data.linkToMyParishApp)
      .then(response => {
        // To avoid error of ES Lint when a variable is defined but never used
        if (response || true) {
          changeStatusLoading(false)(dispatch);
          snackBarStatus({
            payload: {
              title: response.data.message || response.message,
              type: 'success',
              enable: true,
            },
          })(dispatch);
        }
      })
      .catch(error => {
        if (error && error.response && error.response.status === 400) {
          snackBarStatus({
            payload: {
              title: error.response.data.message || error.message,
              type: 'error',
              enable: true,
            },
          })(dispatch);
        }
        updateData({ linkToMyParishApp: false })(dispatch);
        changeStatusLoading(false)(dispatch);
      });
  }
  return -1;
};

export const resendBulletin = orgId => async dispatch => {
  return Organization.resendBulletin(orgId)
    .then(response => {
      snackBarStatus({
        payload: {
          title: response.data.message || response.message,
          type: 'success',
          enable: true,
        },
      })(dispatch);
    })
    .catch(error => {
        snackBarStatus({
          payload: {
            title: error.response.data.message || error.message,
            type: 'error',
            enable: true,
          },
        })(dispatch);
    })
}

export const linkOrgToCaminoOnServer = data => async dispatch => {
  const { orgPublicId, linkToCamino } = data
  if (!orgPublicId || linkToCamino === undefined) {
    console.log('Insufficient data to change the linkToCamino property: ', data);
  } else {
    changeStatusLoading(true)(dispatch);
    return Organization.linkToCamino(orgPublicId, linkToCamino)
      .then(response => {
        // To avoid error of ES Lint when a variable is defined but never used
        if (response || true) {
          changeStatusLoading(false)(dispatch);
          snackBarStatus({
            payload: {
              title: response.data.message || response.message,
              type: 'success',
              enable: true,
            },
          })(dispatch);
        }
      })
      .catch(error => {
        if (error && error.response && error.response.status === 400) {
          snackBarStatus({
            payload: {
              title: error.response.data.message || error.message,
              type: 'error',
              enable: true,
            },
          })(dispatch);
        }
        updateData({ linkToMyParishApp: false })(dispatch);
        changeStatusLoading(false)(dispatch);
      });
  }
  return -1;
};

export function updateOrganization(value, orgId) {
  return () =>
    Organization.updateOrganization(value, orgId)
      .then(response => response)
      .catch(error => error);
}

export function updatePicture({ img }) {
  return dispatch => {
    dispatch({
      type: ACTIONS.UPLOAD_PICTURE,
      payload: {
        img,
      },
    });
  };
}

export function updateUpdPicture({ img }) {
  return dispatch => {
    dispatch({
      type: ACTIONS.UPLOAD_UPD_PICTURE,
      payload: {
        img,
      },
    });
  };
}

export const updateIsDeleteArchived = isDeleteArchivedMessageActivated => dispatch =>
  dispatch({
    type: ACTIONS.UPDATE_IS_DELETE,
    payload: isDeleteArchivedMessageActivated,
  });

export const updateLiteMode = bool => dispatch =>
  dispatch({
    type: ACTIONS.UPDATE_LITE_MODE,
    payload: bool,
  });

export const updateLinkMyParishApp = linkToMyParishApp => ({
  type: ACTIONS.UPDATE_LINK_MY_PARISH_APP,
  payload: linkToMyParishApp,
});

export function updateType(data) {
  return dispatch => {
    dispatch({
      type: ACTIONS.UPDATE_TYPE,
      payload: data,
    });
  };
}

export const updateSuspendedUntil = data => dispatch => {
  dispatch({ type: ACTIONS.UPDATE_SUSPENDED, ...data });
};

export const getTimeZone = () => async dispatch => {
  try {
    const response = await Organization.getTimeZone();
    let timeZoneResponse = [];
    if (response.status === 200) {
      timeZoneResponse = response;
      localStorage.setItem('timeZoneList', JSON.stringify(timeZoneResponse.data.data));
    }
    return timeZoneResponse;
  } catch (error) {
    snackBarStatus({
      payload: {
        title: error.message,
        type: 'error',
        enable: true,
      },
    })(dispatch);
    return error;
  }
};

export const openInEva = orgId => async dispatch => {
  if (!orgId) return;

  try {
    const params = {
      authToken: localStorage.getItem('sso_token'),
      returnTo: window.location.pathname,
    };


    params.redirect = `/organization/select-by-id/${orgId}`;

    const query = queryString.stringify(params);
    const url = `${EVA_FRONT_URL}/login-check?${query}`;

    dispatch({ type: ACTIONS.OPEN_IN_EVANGELUS, payload: { viewOrgInEVAUrl: url } });

    window.location.href = url;

    return orgId;
  } catch (error) {
    snackBarStatus({
      payload: {
        title: error.message,
        type: 'error',
        enable: true,
      },
    })(dispatch);
    return error;
  }
};

export function refreshOrgKeyword(keyword) {
  return dispatch => {
    dispatch({
      type: ACTIONS.REFRESH_ORG_KEYWORD,
      payload: {
        keyword,
      },
    });
  };
}
