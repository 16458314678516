import { ACTIONS } from '../Actions/SuperUserActions';

const initialState = {
  superUserList: [],
  order: 'asc',
  sortField: 'name',
  qty: 10,
  loading: false,
  grantingUserPrivileges: false,
  revokingUserPrivileges: false,
  paginator: [],
};

const userProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.GET_SUPER_USERS_LIST:
      return { ...state, ...action.payload };
    case ACTIONS.REVOKE_SUPER_USER_ACCESS:
    case ACTIONS.GRANT_SUPER_USER_PRIVILEGES:
    default:
      return state;
  }
};

export default userProfileReducer;
