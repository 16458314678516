import Keyword from '../Api/Keyword';
import snackBarStatus from './snackbarActions';
import findMessage from '../Helpers/utiliy';

export const ACTIONS = {
  GET_KEYWORDS: '[Keywords] Get list',
  GET_KEYWORD: '[Keyword] Get',
  ADD_KEYWORD: '[Keyword] Add',
  UPDATE_KEYWORD: '[Keyword] Update',
  LOADING_KEYWORD: '[Keyword] Loading',
};

const updateLoading = bool => dispatch => dispatch({ type: ACTIONS.LOADING_KEYWORD, payload: bool });

export const getKeywords = () => async dispatch => {
  const token = localStorage.getItem('token');

  updateLoading(true)(dispatch);
  try {
    const res = await Keyword.getKeywords(token);

    dispatch({
      type: ACTIONS.GET_KEYWORDS,
      payload: res.data.data.data,
    });

    updateLoading(false)(dispatch);

  } catch (error) {
    snackBarStatus({
      payload: {
        title: findMessage(error),
        type: 'error',
        enable: true,
      },
    })(dispatch);

    updateLoading(false)(dispatch);
    console.log('e:', error)
  }
};

export const createKeyword = (keyword, message) => async dispatch => {
  try {
    const res = await Keyword.createKeywords({ keyword, message });

    dispatch({
      type: ACTIONS.ADD_KEYWORD,
      payload: res.data.data,
    });

    snackBarStatus({
      payload: {
        // title: res.data.message,
        title: 'Short Code has been created successfully.',
        type: 'success',
        enable: true,
      },
    })(dispatch);
  } catch (error) {
    snackBarStatus({
      payload: {
        title: error.data.errors.keyword.join(', '),
        type: 'error',
        enable: true,
      },
    })(dispatch);
  }
}

export const updateKeyword = (publicId, keyword, message) => async (dispatch, getState) => {
  const { keywordReducer: { list } } = getState();

  const itemToChange = list.find(i => i.publicId === publicId);

  if (itemToChange.message === message && itemToChange.keyword === keyword) {
    return Promise.resolve();
  }

  try {
    const res = await Keyword.updateKeyword(publicId, { keyword, message });

    dispatch({
      type: ACTIONS.UPDATE_KEYWORD,
      payload: res.data.data,
    });

    snackBarStatus({
      payload: {
        // title: res.data.message,
        title: 'Short Code has been updated successfully.',
        type: 'success',
        enable: true,
      },
    })(dispatch);
  } catch (error) {
    snackBarStatus({
      payload: {
        // title: error,
        title: error.data.errors.keyword.join(', '),
        type: 'error',
        enable: true,
      },
    })(dispatch);
  }
}
