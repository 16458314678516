import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import style from './styles';
import DefaulTitlebar from './DefaultTitleBar';
import OrganizationBar from './OrganizationBar';

/**
 * Component that will be the header bar of the Admin Pannel.
 * Intended to satisfy the requirements of the header bar for
 * the admin panel's designs
 * @param {Object}          props
 * @param {React.Component} props.SwitchButton  Button to switch from EVA to Admin and viceversa
 * @param {string}          props.profilePicture URL with Admin's profile picture (if any)
 * @param {string}          props.firstName Name of admin
 * @param {string}          props.lastName Last name of admin
 * @param {Object}          props.images Placeholders when no profile picture is found
 * @param {React.Component} props.SettingsComponent
 * @param {React.Component} props.customTitle Left side of the custom title if any
 */
const HeaderBar = ({
  classes,
  profilePicture,
  firstName,
  lastName,
  images,
  SwitchButton = undefined,
  SettingsComponent = undefined,
  customTitle: CustomComponent,
  ...props
}) =>
  (
    <div className={classes.root}>
      <CustomComponent {...props} />
      <div className="right-options">
        <p className="logo-admin-mobile-v2">ADMIN</p>
        {SwitchButton || <div>&nbsp;</div>}
        <img
          src={profilePicture || images.User}
          alt={`${firstName} ${lastName}`}
          className="admin-photo"
        />
      </div>
      {SettingsComponent}
    </div>
  );

const mapStateToProps = ({ headerBarReducer: { customTitle } }) => ({ customTitle });

// Connected component
const ConnectedHeaderBar = connect(mapStateToProps)(withStyles(style)(HeaderBar));

// Exports
export default ConnectedHeaderBar
export { DefaulTitlebar, ConnectedHeaderBar as HeaderBar, OrganizationBar };
