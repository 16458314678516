const Color = {
  evaSoft: '#efe3f7',
  amethyst: '#9a66bf',
  purple: '#9a66bf',
  grey: '#a9a9a9',
  evaPrimaryWhite: '#d9d9d9',
  greyish: '#a9a9a9',
  graytext: '#4a4a4a',
  redTip: '#ff7d8c',
  yellowTip: '#ffc35f',
  purpleTip: '#be9cd6',
  redTipMobile: '#f14077',
  yellowTipMobile: '#f5a623',
  purpleTipMobile: '#9a66bf',
  purpleShade: '#efe3f7',
  whiteTwo: '#f3f3f3',
  pastelRed: '#ea5858',
  grayCheck: '#e3e3e3',
};

export default Color;
