import AXIOS from '../Config/AxiosConfig';
import { GENERAL } from '../Config/env';
import headers from './Helper';

// const baseURL = '/admin-panel/diocese';
const baseURL = '/admin-panel';
const Keyword = {
  // getParishes(parishId, token, currentPage, orderBy, order) {
  //   return AXIOS.get(
  //     `${baseURL}/${parishId}/parish/page=${currentPage}&limit=${
  //       GENERAL.paginator_limit
  //     }&order=${order}&order_by=${orderBy}`,
  //     {
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //         'Diocesan-Application': 'admin',
  //       },
  //     },
  //   );
  // },
  getKeywords() {
    return AXIOS.get(
      `${baseURL}/keywords?limit=40&offset=0`,
      {
        headers: headers(),
      },
    );
  },

  createKeywords(data) {
    return AXIOS.post(
      `${baseURL}/keyword`,
      data,
      {
        headers: headers(),
      },
    );
  },
  updateKeyword(publicId, data) {
    return AXIOS.put(
      `${baseURL}/keyword/${publicId}`,
      data,
      {
        headers: headers(),
      },
    );
  },
};
export default Keyword;
