import React, { PureComponent } from 'react';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import MobiscrollSelect from '../MobiscrollSelect';
import searchIcon from '../../Theme/Icons/search.png';
import snackBarStatus from '../../Actions/snackbarActions';
import {
  searchDiscoverOrganizations,
  hydrateInputsDiscover,
  setDiscoverOrganizations,
} from '../../Actions/organizationActions';

class SearchOrganization extends PureComponent {
  componentDidUpdate() {
    const {
      discoverOrganizationSuggestionValueId,
      discoverOrganizationsSuggestions,
      selectedOrg,
    } = this.props;

    // set selectedOrg as suggestion data to display it
    if (selectedOrg.ssoOrganizationId === discoverOrganizationSuggestionValueId
    && !discoverOrganizationsSuggestions.length) {
      const address =
        selectedOrg.organizationAddresses
        && selectedOrg.organizationAddresses.length ?
          selectedOrg.organizationAddresses[0] : {};

      const selectedOrgAsSuggestion = [
        {
          id: selectedOrg.ssoOrganizationId,
          name: selectedOrg.name,
          city: address.cityText || '',
          stateCode: address.stateText || '',
          zipCode: address.postalCode || '',
        },
      ];
      // save it to discoverOrganizationsSuggestions on organizationReducer
      // so it is displayed
      this.props.setDiscoverOrganizations(selectedOrgAsSuggestion);
    }
  }

  getPlaceholder = () => {
    if (this.props.organizationType
    && this.props.organizationType.name
    && this.props.organizationType.name === 'Parish') {
      return 'Add a parish';
    }
    return 'Search for organization';
  }

  openOrgAutocomplete = () => {
    // if empty object or empty string then throw a messsage
    if (JSON.stringify(this.props.organizationType) === JSON.stringify({})
    || (typeof this.props.organizationType === 'string'
    && !this.props.organizationType.length)) {
      this.props.snackBarStatus({
        payload: {
          enable: true,
          title: 'Please select a type first.',
          type: 'error',
        },
      });
      // return false to prevent open
      return false;
    }
    // else open search/select
    return true;
  }

  handleFilterChange = debounce(
    e => {
      this.props.searchDiscoverOrganizations(
        e.filterText,
        this.props.organizationType.name,
      );
      // return false to prevent mobiscroll filter, since the API is filtering the suggestions
      return false;
    },
    600,
  );

  handleSuggestionSelect = (e, inst) => {
    const { discoverOrganizationsSuggestions } = this.props;

    const selectedOrg =
      discoverOrganizationsSuggestions.find(suggestion => suggestion.id === inst.getVal());

    this.props.hydrateInputsDiscover(selectedOrg);
  };

  render() {
    const {
      discoverOrganizationSuggestionValueId,
      discoverOrganizationsSuggestions,
    } = this.props;

    let orgSuggestions = [];

    if (discoverOrganizationsSuggestions
    && Array.isArray(discoverOrganizationsSuggestions)) {
      orgSuggestions = discoverOrganizationsSuggestions.map(suggestion => ({
        value: suggestion.id,
        text: suggestion.name,
        // html classes are styled by Select styles
        html: `<div class="cstm-select-itm-m">${suggestion.name}</div><div class="cstm-select-itm-s">${suggestion.city}, ${suggestion.stateCode} ${suggestion.zipCode}</div>`,
      }));
    }

    return (
      <MobiscrollSelect
        responsive={{
          xsmall: {
            touchUi: true,
            display: 'center',
          },
          large: {
            touchUi: false,
            display: 'center',
          },
        }}
        filter
        disabled // this field is disabled temporarily
        multiline={2}
        height={65}
        placeholder={this.getPlaceholder()}
        filterPlaceholderText="Search"
        data={orgSuggestions}
        value={discoverOrganizationSuggestionValueId}
        iconLeft="none"
        iconRight={searchIcon}
        onFilter={this.handleFilterChange}
        onBeforeShow={this.openOrgAutocomplete}
        onSet={this.handleSuggestionSelect}
      />
    );
  }
}

SearchOrganization.propTypes = {
  organizationType: PropTypes.oneOfType([
    PropTypes.objectOf(PropTypes.string),
    PropTypes.string,
  ]).isRequired,
  discoverOrganizationsSuggestions: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedOrg: PropTypes.objectOf(PropTypes.string).isRequired,
  discoverOrganizationSuggestionValueId: PropTypes.number.isRequired,
  snackBarStatus: PropTypes.func.isRequired,
  searchDiscoverOrganizations: PropTypes.func.isRequired,
  setDiscoverOrganizations: PropTypes.func.isRequired,
  hydrateInputsDiscover: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  organizationType: state.organizationReducer.organizationType,
  discoverOrganizationsSuggestions:
    state.organizationReducer.discoverOrganizationsSuggestions,
  discoverOrganizationSuggestionValueId:
    state.organizationReducer.discoverOrganizationSuggestionValueId,
  // this object does not contain the form data, is only used
  // to check selectedOrg data
  selectedOrg: state.organizationReducer.selectedOrg,
});

const mapDispatchToProps = {
  snackBarStatus,
  searchDiscoverOrganizations,
  setDiscoverOrganizations,
  hydrateInputsDiscover,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SearchOrganization);
