import PropTypes from 'prop-types';

const MemberFoundPropType = {
  stepValues: PropTypes.instanceOf(Object).isRequired,
  nextStep: PropTypes.func.isRequired,
  tryAgain: PropTypes.func.isRequired,
  organization: PropTypes.string.isRequired,
  selectAdmin: PropTypes.instanceOf(Array).isRequired,
};

export default MemberFoundPropType;
