export default {
  root: {
    border: 'none',
    left: 0,
    position: 'fixed',
    top: 0,
    transform: 'translateX(-100%)',
    transition: 'transform .65s',
    width: 344,
    zIndex: 10000,
    '&.active': {
      transform: 'translateX(0%)',
    },
    '@media (max-width: 375px)': {
      width: 310,
    },
  },
};
