import React from 'react';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import EvaButton from '../../EvaButton';
import memberImg from '../../../Theme/Icons/member.svg';
import { MemberFoundStyle, checkBox, button } from './styles';
import MemberFoundPropType from '../../../PropTypes/MemberFoundPropType';
import getMemberInfo from '../../../Helpers/getMemberInfo';

const MemberFound = ({
  organization,
  tryAgain,
  stepValues: { members },
  nextStep,
  selectAdmin,
  selectedAdmin,
}) => (
  <Grid container justify="center" alignItems="center">
    <Grid item xs={12} style={MemberFoundStyle.row}>
      <p style={MemberFoundStyle.paragraph}>
        We found a match! <br /> Please select below.
      </p>
    </Grid>
    {members.map((member, index) => {
      return (
        <Grid item xs={10} md={4} style={MemberFoundStyle.row}>
          {member.profilePictureUrl ? (
            <React.Fragment>
              <Checkbox
                checked={selectedAdmin[index]}
                onChange={() => selectAdmin(index)}
                value={selectedAdmin[index]}
                style={checkBox.root}
              />
              <img
                src={member.profilePictureUrl}
                alt="member pic"
                style={MemberFoundStyle.img}
              />
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Checkbox
                checked={selectedAdmin[index]}
                onChange={() => selectAdmin(index)}
                value={selectedAdmin[index]}
                style={checkBox.root}
              />
              <img alt="default" src={memberImg} />
            </React.Fragment>
          )}
          <p style={{ ...MemberFoundStyle.memberProfile, marginTop: '20px' }}>
            {getMemberInfo(member).displayedFullName}
          </p>
        </Grid>
      )
    })}
    <Grid item xs={12} style={{ ...MemberFoundStyle.row, marginTop: '50px' }}>
      <p style={MemberFoundStyle.memberProfile}>
        Assign to <br /> <b>{organization}</b>
      </p>
    </Grid>
    <Grid item xs={12} style={{ ...MemberFoundStyle.row, marginTop: '50px' }}>
      <EvaButton
        style={{ ...MemberFoundStyle.row, marginTop: '50px' }}
        onClick={() => nextStep(members)}
        active={selectedAdmin[0] && selectAdmin[1]}
      >
        <span
          style={{
            color: selectedAdmin[0] || selectAdmin[1] ? '#9a66bf' : '#a9a9a9',
            fontFamily: button.fontFamily,
            textTransform: 'capitalize',
          }}
        >
          Add Org Admin
        </span>
      </EvaButton>
    </Grid>
    <Grid item xs={12} style={MemberFoundStyle.row}>
      <button onClick={() => tryAgain()} style={MemberFoundStyle.noMatch}>
        No match, try again
      </button>
    </Grid>
  </Grid>
);

MemberFound.propTypes = MemberFoundPropType;

export default MemberFound;
