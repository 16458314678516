import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars';
import PropTypes from 'prop-types';
import {
  getSuperAdmins,
  revokeSuperUserAccess,
  findCandidates,
  grantSuperUserPrivileges,
  sendInvitationToJoinSuperAdminTeam,
} from '../../Actions/SuperUserActions';
import { enableRightDrawer, disableRightDrawer } from '../../Actions/rightDrawerActions';
import { enableTopDrawer } from '../../Actions/topDrawerActions';
import './index.sass';
import DataTable from './DataTable';
import SuperAdminAdd from '../SuperAdminAdd';
import styles from './styles';

class SuperAdmins extends Component {
  componentDidMount() {
    // Get Super Admins
    this.props.getSuperAdmins();
  }

  handleClick = e => {
    e.preventDefault();
    this.props.enableRightDrawer({
      payload: {
        close: true,
        enable: true,
        element: (
          <SuperAdminAdd
            findAdmins={this.props.findCandidates}
            inviteAdmin={this.props.sendInvitationToJoinSuperAdminTeam}
            assignAdmins={this.props.grantSuperUserPrivileges}
            disableDrawer={this.props.disableRightDrawer}
            getSuperAdmins={this.props.getSuperAdmins}
          />
        ),
        title: 'Invite a Super Admin',
      },
    });
    /* eslint-disable */
    // this.props.clearDetailMembers();
    /* eslint-enable */
  };

  handleOnKeyPress = e => {
    e.preventDefault();
  };

  render() {
    const { classes,
      loading,
      paginator,
      superUserList,
      publicId,
      roles,
      order,
      qty,
      sortField,
    } = this.props;
    /* eslint-disable */
    const userRoles = roles ? roles : [];
    /* eslint-enable */
    const WindowHeight = window.innerHeight - 100;
    return (
      <Scrollbars autoHeight autoHide autoHeightMin={WindowHeight} autoHeightMax={WindowHeight}>
        <div className={classes.organizationRoot}>
          <Grid container spacing>
            <Grid item xs={12} className={classes.tableContainer}>
              <div className="super-admin-title-container">
                <h3 className="title-super-admins">Super Admins</h3>
                {userRoles.includes('ROLE_SUPER_ADMIN') && (
                  <div
                    className="display-drawer-container create-new-organization-button"
                    onClick={this.handleClick}
                    onKeyPress={this.handleOnKeyPress}
                    role="button"
                    tabIndex="0"
                  >
                    <i className="display-drawer" />
                  </div>
                )}
              </div>
            </Grid>
            {/* <Grid item xs={12} className={classes.tableContainer}>
              <div className="filters-container-super-admin">
                <div className="search-container">
                  <input
                    name="search"
                    type="search"
                    className="search"
                    hidden
                    disabled
                    placeholder="Search"
                    onChange={this.handleSearch}
                  />
                </div>
                <div className="filter-container">{ <i className="filter" /> }</div>
              </div>
            </Grid> */}
            <Grid item xs={12} className={classes.tableContainer}>
              <DataTable
                value={superUserList}
                loading={loading}
                paginator={paginator}
                enableTopDrawer={this.props.enableTopDrawer}
                getSuperAdmins={this.props.getSuperAdmins}
                revokeSuperUserAccess={this.props.revokeSuperUserAccess}
                user={publicId}
                order={order}
                qty={qty}
                sortField={sortField}
              />
            </Grid>
          </Grid>
        </div>
      </Scrollbars>
    );
  }
}

const mD = {
  enableRightDrawer,
  enableTopDrawer,
  getSuperAdmins,
  revokeSuperUserAccess,
  findCandidates,
  grantSuperUserPrivileges,
  disableRightDrawer,
  sendInvitationToJoinSuperAdminTeam,
};

const mS = ({
  superUserReducer: { loading, paginator, superUserList, order, qty, sortField },
  userProfileReducer: {
    member: { public_id: publicId, roles },
  },
}) => ({
  loading,
  paginator,
  superUserList,
  publicId,
  roles,
  order,
  qty,
  sortField,
});

SuperAdmins.propTypes = {
  classes: PropTypes.isRequired,
  enableRightDrawer: PropTypes.isRequired,
  enableTopDrawer: PropTypes.func.isRequired,
  disableRightDrawer: PropTypes.func.isRequired,
  getSuperAdmins: PropTypes.func.isRequired,
  revokeSuperUserAccess: PropTypes.func.isRequired,
  findCandidates: PropTypes.func.isRequired,
  grantSuperUserPrivileges: PropTypes.func.isRequired,
  sendInvitationToJoinSuperAdminTeam: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  paginator: PropTypes.instanceOf(Object).isRequired,
  publicId: PropTypes.string.isRequired,
  roles: PropTypes.instanceOf(Object).isRequired,
  superUserList: PropTypes.arrayOf(Object).isRequired,
  order: PropTypes.string.isRequired,
  qty: PropTypes.isRequired,
  sortField: PropTypes.string.isRequired,
};

export default connect(
  mS,
  mD,
)(withStyles(styles)(SuperAdmins));
