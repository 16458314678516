import { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { logout } from '../../Actions/loginActions';
import { navigationControllerRedirect } from '../../Actions/navigationControllerActions';

import LogoutPropTypes from '../../PropTypes/LogoutPropType';

class Logout extends Component {
  componentWillMount = () => {
    this.props.logout();
  };

  render = () => null;
}

const mapStateToProps = () => ({
});

const mapDispatchToProps = {
  logout,
  navigationControllerRedirect,
};

Logout.propTypes = LogoutPropTypes;

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Logout),
);
