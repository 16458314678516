export const ACTIONS = {
  REDIRECT: 'navigation-controller/redirect',
  STOP: 'navigation-controller/stop',
};

export const navigationControllerRedirect = url => ({ type: ACTIONS.REDIRECT, payload: url });

/* eslint-disable */
export const __NavigationControllerState = (url, flag) => ({ type: ACTIONS.STOP, payload: { url, flag }});
/* eslint-enable */
