import React, { Component } from 'react';
import { connect } from 'react-redux';
import ButtonStandard from '../Button';
import {
  updatePassword,
  clearPassword,
  requestPassword,
} from '../../Actions/passwordActions';
import Input from '../Input';
import ErrorMessage from '../ErrorMessage';
import ForgotPasswordPropType from '../../PropTypes/ForgotPasswordPropType';
import Helpers from '../../Helpers';
import './index.sass';

class ForgotPassword extends Component {
  state = {
    focus: '',
  };
  componentWillMount() {
    this.props.clearPassword();
  }
  submit = e => {
    e.preventDefault();
    const { username, history } = this.props;
    const validUsername = Helpers.ValidEmailPhone(username);
    const payload = { parameter: username, parameterType: validUsername };

    switch (validUsername) {
      case 'email':
        this.props.requestPassword(payload).then(response => {
          if (response) {
            history.push('/request-password-email');
          }
        });
        break;
      case 'sms':
        this.props.requestPassword(payload).then(response => {
          if (response) {
            this.props.updatePassword({ payload: { phone: username } });
            history.push('/reset-password');
          }
        });
        break;
      default:
        this.props.updatePassword({
          payload: {
            error: 'Invalid format, email or US Phone (555)5555555',
          },
        });
    }
  };

  handleFocus = () => {
    const focus = 'input-eva-focused';
    this.setState({ focus });
  };

  handleBlur = () => {
    const focus = '';
    if (this.props.username === '') {
      this.setState({ focus });
    }
  };

  render() {
    const { focus } = this.state;
    return (
      <div className="container-center forgot-password">
        <div className="login__title">
          <p className="logo-evangelus">EVANGELUS</p>
        </div>
        <h1 className="title-forgot-password">Forgot Password</h1>
        {this.props.error ? (
          <ErrorMessage
            error={this.props.error}
            hideMessage={this.props.clearPassword}
          />
        ) : null}
        <p className="content-forgot-password">
          Please enter your email or phone.
        </p>
        <div onFocus={this.handleFocus}>
          <form className="login__form">
            <Input
              name="phone-email"
              title="Email or Phone Number"
              icon="phone-email"
              placeholder="Email or Phone Number"
              focused={focus}
              value={this.props.username}
              onBlur={this.handleBlur}
              onChange={e => {
                this.props.updatePassword({
                  payload: { username: e.target.value },
                });
              }}
            />
            <ButtonStandard title="Request" onClick={this.submit} />
          </form>
        </div>
      </div>
    );
  }
}

ForgotPassword.propTypes = ForgotPasswordPropType;

const mS = state => ({
  username: state.passwordReducer.username,
  error: state.passwordReducer.error,
});

const mD = {
  updatePassword,
  clearPassword,
  requestPassword,
};

export default connect(
  mS,
  mD,
)(ForgotPassword);
