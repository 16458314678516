import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import 'react-infinite-calendar/styles.css';
import Calendar from '../Calendar';
import Helpers from '../../Helpers';
import InputPropType from '../../PropTypes/InputPropType';
import birthdayIcon from '../../Theme/Icons/birthday.svg';
import helpGrayIcon from '../../Theme/Icons/help-gray.svg';
import '../Input/index.sass';

// Custom styles declaration
const styles = {
  root: {
    borderBottom: '2px solid #d9d9d9',
    '& .react-datepicker-wrapper, & .react-datepicker__input-container, & .custom-input': {
      width: '100%',
      height: '55px',
      backgroundColor: 'transparent',
    },
    '& .custom-input': {
      backgroundImage: `url(${birthdayIcon})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '10px',
      backgroundSize: '25px',
      width: '100%',
      margin: '0',
      border: 'none',
      textAlign: 'center',
      paddingLeft: '20px',
      '&:focus': {
        outline: 'none',
      },
    },
  },
  icon2: {
    backgroundImage: `url(${helpGrayIcon})`,
    backgroundColor: 'white',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    borderBottom: 'none',
    height: '55px !important',
  },
  ageDisplay: {
    height: '50px',
    marginTop: '4px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: 'GothamSSm-Medium',
    fontSize: '16px',
    fontWeight: '400',
  },
};

class InputBirthday extends Component {
  constructor(props) {
    super(props);

    this.handlerClick = this.handlerClick.bind(this);
    this.handlerSelectDate = this.handlerSelectDate.bind(this);
  }

  state = {
    showCalendar: false,
  };

  handlerClick(event) {
    event.preventDefault();
    const { showCalendar } = this.state;
    this.setState({ showCalendar: !showCalendar });
  }

  handlerSelectDate(date) {
    this.setState({ showCalendar: false });
    this.props.onChangeBirthday(date);
  }

  render() {
    const {
      name,
      placeholder,
      value,
      onClick,
      classes,
    } = this.props;
    // const iconClass2 = icon2 ? `input-eva-${icon2}-icon` : '';
    return (
      <Grid container spacing={0} className={`${classes.root}`}>
        <Grid
          item
          xs={10}
          sm={10}
          md={11}
          id="custom-time-picker"
        >
          <Calendar
            handleCalendar={this.handlerSelectDate}
            date={value}
            placeholder={placeholder}
          />
        </Grid>
        <Grid item xs={2} sm={2} md={1} onChange={event => onClick(event, name)} >
          {Helpers.validDate(value) ? (
            <div className={classes.ageDisplay}>
              {Helpers.getYears(value)}y
            </div>
          ) : (
            <div className={`${classes.icon2} input-eva-border-bottom`} />
          )}
        </Grid>
      </Grid>
    );
  }
}
InputBirthday.propTypes = InputPropType;

export default withStyles(styles)(InputBirthday);
