const memberProfile = {
  fontSize: '16px',
  fontFamily: 'GothamSSm-Book',
  color: '#4a4a4a',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  margin: '0 auto',
  maxWidth: '100%',
};
export const checkBox = {
  root: {
    color: '#e3e3e3',
    '&$checked': {
      color: '#9a66bf',
    },
    position: 'absolute',
    marginLeft: '-7%',
    borderRadius: '5px',
  },
  checked: {},
};

export const button = {
  marginTop: '37px',
  fontSize: '16px',
  color: '#9a66bf',
  fontFamily: 'GothamSSm-Bold',
  background: 'none',
  border: 'none',
  padding: 0,
  cursor: 'pointer',
  outline: 'inherit',
  width: '100%',
  textTransform: 'capitalize',
};

const paragraph = {
  fontSize: '16px',
  color: '#a9a9a9',
  textAlign: 'center',
  fontFamily: 'GothamSSm-Book',
  marginBottom: '40px',
};

export const MemberFoundStyle = {
  paragraph: {
    fontSize: '16px',
    color: '#a9a9a9',
    textAlign: 'center',
    fontFamily: 'GothamSSm-Medium',
    fontWeight: '500',
  },
  memberProfile,
  button,
  img: {
    borderRadius: '50%',
    width: '100px',
    height: '100px',
  },
  row: {
    textAlign: 'center',
  },
  noMatch: {
    fontSize: '16px',
    marginTop: '37px',
    textAlign: 'center',
    fontFamily: 'GothamSSm-Book',
    color: '#9a66bf',
    background: 'none',
    border: 'none',
    padding: 0,
    cursor: 'pointer',
    outline: 'inherit',
    width: '100%',
  },
};

export const SearchMemberStyle = {
  paragraph,
  row: {
    textAlign: 'center',
    marginTop: '20px',
  },
};

export const RegisterMemberStyle = {
  paragraph,
  row: {
    textAlign: 'center',
    marginTop: '40px',
    textTransform: 'initial',
  },
  memberProfile,
};

export const phoneIndent = {
  phoneCustom: {
    '& input': {
      textIndent: '.9em',
      '@media only screen and (max-width: 425px)': {
        textIndent: '2.5em',
      },
    },
    '& input::placeholder': {
      textIndent: '.9em',
      '@media only screen and (max-width: 425px)': {
        textIndent: '2.5em',
      },
    },
  },
};
