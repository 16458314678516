import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import SettingsOrgPropType from '../../PropTypes/SettingsOrgPropType';
import memberImg from '../../Theme/Icons/member.svg';
import './index.sass';

const styles = {
  row: {
    display: 'flex',
    justifyContent: 'center',
  },
  avatar: {
    margin: 10,
  },
  bigAvatar: {
    width: 60,
    height: 60,
  },
};

const OrgAdminItem = ({ admin, removeRole, id }) => {
  const fullName = `${admin.firstName || ''} ${admin.lastName || ''}`;

  /* eslint-disable */
  let email = '';
  if (admin.user && admin.user.email) {
    email = admin.user.email;
  } else if (admin.email) {
    email = admin.email;
  }
  /* eslint-enable */

  return (
    <Grid container spacing={1} className="search-list-admins">
      <Grid item xs={3} sm={3}>
        <div className="container-icons">
          <div className="input-view-member input-view-member-delete-icon">
            <button onClick={() => removeRole(id)} />
          </div>
          <div className="img-org item-image">
            <Avatar
              alt="alt"
              src={
                admin.profilePictureUrl === null
                  ? memberImg
                  : admin.profilePictureUrl
              }
              className="avatar-image"
            />
          </div>
        </div>
      </Grid>
      <Grid item xs={9} sm={9}>
        <div className="name-org-admin">
          {fullName}
        </div>
        <div className="info-org">{email}</div>
      </Grid>
    </Grid>
  );
};

OrgAdminItem.propTypes = SettingsOrgPropType;
export default withStyles(styles)(OrgAdminItem);
