import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import EvaButton from '../EvaButton';
import memberImg from '../../Theme/Icons/member.svg';
import { MemberFoundStyle, checkBox, styles } from './styles';
import MemberFoundPropType from '../../PropTypes/MemberFoundPropType';

const MemberFound = ({ tryAgain, stepValues: { members }, nextStep, selectAdmin, classes }) => {
  const selectedMembers = members.some(elem => elem.selectedToBeSuperAdmin);
  return (
    <Grid container justify="center" alignItems="center" className={classes.matchMargin}>
      <Grid item xs={12} style={MemberFoundStyle.row}>
        <p style={MemberFoundStyle.paragraph}>
          We found a match! <br /> Please select below.
        </p>
      </Grid>
      {members.map(member => {
        let identifier = null;
        if (!member.firstName && !member.lastName) {
          identifier = `${member.phone || member.email}`;
        } else {
          identifier = (
            <React.Fragment>
              {member.firstName} <b>{member.lastName}</b>
            </React.Fragment>
          );
        }
        return (
          <Grid
            item
            xs={10}
            md={4}
            style={MemberFoundStyle.row}
            className={classes.textAboveResultsMargin}
          >
            {member.profilePictureUrl ? (
              <React.Fragment>
                <Checkbox
                  checked={member.selectedToBeSuperAdmin}
                  onChange={() => selectAdmin(member)}
                  value={member.selectedToBeSuperAdmin}
                  style={checkBox.root}
                />
                <img src={member.profilePictureUrl} alt="member pic" style={MemberFoundStyle.img} />
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Checkbox
                  checked={member.selectedToBeSuperAdmin}
                  onChange={() => selectAdmin(member)}
                  value={member.selectedToBeSuperAdmin}
                  style={checkBox.root}
                />
                <img alt="default" src={memberImg} />
              </React.Fragment>
            )}
            <p style={{ ...MemberFoundStyle.memberProfile, marginTop: '20px' }}>
              {identifier}
            </p>
          </Grid>
        )
      })}
      <Grid item xs={12} style={{ ...MemberFoundStyle.row, marginTop: '60px' }}>
        <EvaButton
          style={{ ...MemberFoundStyle.row, maxWidth: '220px', marginTop: '0px', width: '100%' }}
          onClick={() => nextStep()}
          active={selectedMembers}
          className={`${classes.buttonWidthClass} ${
            selectedMembers ? undefined : classes.matchButtonClassInactive
          }`}
        >
          <span className={classes.spanTextDisplay}>Add Super Admin</span>
        </EvaButton>
      </Grid>
      <Grid item xs={12} style={MemberFoundStyle.row}>
        <button onClick={() => tryAgain()} style={MemberFoundStyle.noMatch}>
          No match, try again
        </button>
      </Grid>
    </Grid>
  );
};

MemberFound.propTypes = MemberFoundPropType;

export default withStyles(styles)(MemberFound);
