import { ACTIONS } from '../Actions/rightDrawerShortActions';

const initialState = {
  enable: false,
  element: null,
  title: 'Drawer Title',
  cleaner: null,
  disableEnforceFocus: false,
};

const rightDrawerReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.ENABLE_SHORT:
      return {
        ...state,
        ...action.payload,
      };
    case ACTIONS.DISABLE:
      return {
        ...state,
        ...action.payload,
      };
    case ACTIONS.TOGGLE_ENFORCE_FOCUS: {
      const { enforceFocus } = action.payload;
      return {
        ...state,
        disableEnforceFocus: !enforceFocus,
      };
    }
    case ACTIONS.CHANGE_DRAWER_TITLE: {
      return { ...state, title: action.payload.title };
    }
    default:
      return state;
  }
};

export default rightDrawerReducer;
