/* eslint-disable no-underscore-dangle */

const propCheck = (prop) => !!(prop ? prop.trim() : prop);

const getMemberInfo = (member) => {
  const { lastName, firstName, email, mainPhoneNumber, phoneNumber } = member;
  const hasLastName = propCheck(lastName);
  const hasFirstName = propCheck(firstName);
  const hasEmail = propCheck(email);
  const hasMainPhoneNumber = propCheck(mainPhoneNumber);
  const hasPhoneNumber = propCheck(phoneNumber);

  let sortString;
  if (hasLastName) {
    sortString = lastName.trim();
  } else if (hasFirstName) {
    sortString = firstName.trim();
  } else if (hasEmail && !email.includes('eva.invalid')) {
    sortString = email.trim();
  } else if (hasMainPhoneNumber) {
    sortString = mainPhoneNumber.trim();
  } else if (hasPhoneNumber) {
    sortString = phoneNumber.trim();
  } else {
    sortString = 'Unknown';
  }

  return {
    hasFirstName,
    hasLastName,
    firstName: hasFirstName ? firstName : '',
    lastName: hasLastName ? lastName : '',
    displayedFullName: hasFirstName || hasLastName ?
      (`${hasFirstName ? firstName : ''} ${hasLastName ? lastName : ''}`).trim() : sortString,
    sortString: sortString.toLocaleLowerCase(),
  };
};

export default getMemberInfo;
