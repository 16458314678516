import camelcaseKeys from 'camelcase-keys';
import snackBarStatus from './snackbarActions';
import Members from '../Api/Members';
import findMessage from '../Helpers/utiliy';

export const ACTIONS_MEMBERS_ORG = {
  FILTER: 'membersOrg/filter',
  ADD: 'memberOrgs/add',
  SELECTED: 'membersOrg/selected',
  UPDATE_LIST: 'membersOrg/update',
  ERROR: 'membersOrg/error',
  CLEAR: 'membersOrg/clear',
  CLEAR_SELECTED: 'membersOrg/clear_selected',
  SET_LOADING: 'membersOrg/set_loading',
  GET_TOTAL_MEMBERS: 'membersOrg/get_total_members',
  GET_ORG_ADMINS: 'membersOrg/get_org_admins',
  CLEAR_DETAIL_MEMBERS: 'membersOrg/clear_detail_members',
};

export const updateOrgRoleMembers = (memberList, orgId, isTemporary = false) => async dispatch => {
  const token = localStorage.getItem('token');
  const data = {
    members: memberList,
  };

  if (isTemporary) {
    data.temporary = isTemporary;
  }

  try {
    const response = await Members.updateOrgRoleMembers(data, orgId, token);
    dispatch({
      type: ACTIONS_MEMBERS_ORG.ADD,
      payload: response.data,
    });

    return response;
  } catch (err) {
    snackBarStatus({
      payload: {
        title: findMessage(err),
        type: 'error',
        enable: true,
      },
    })(dispatch);

    if (err.response && err.response.data && err.response.data.code === 400) {
      dispatch({
        type: ACTIONS_MEMBERS_ORG.ERROR,
        payload: err.response.data,
      });
    }

    return err;
  }
};

export const membersSelected = values => ({ type: ACTIONS_MEMBERS_ORG.SELECTED, payload: values });

export const updateMembersList = values => ({
  type: ACTIONS_MEMBERS_ORG.UPDATE_LIST,
  payload: values,
});

export const clearSafeMembers = () => ({ type: ACTIONS_MEMBERS_ORG.CLEAR, payload: [] });

export const clearSelectedMembers = () => ({
  type: ACTIONS_MEMBERS_ORG.CLEAR_SELECTED,
  payload: [],
});

export const clearDetailMembers = () => ({
  type: ACTIONS_MEMBERS_ORG.CLEAR_DETAIL_MEMBERS,
  payload: [],
});

export const getOrgAdmins = orgId => async dispatch => {
  try {
    dispatch({
      type: ACTIONS_MEMBERS_ORG.SET_LOADING,
      payload: true,
    });
    const {
      status,
      data: { data },
    } = await Members.getOrgAdmins(orgId);
    if (status === 200) {
      const memberList = Object.values(data).map(({ member }) => camelcaseKeys(member));
      dispatch({
        type: ACTIONS_MEMBERS_ORG.GET_ORG_ADMINS,
        payload: memberList,
      });
      dispatch({
        type: ACTIONS_MEMBERS_ORG.SET_LOADING,
        payload: false,
      });
      dispatch({
        type: ACTIONS_MEMBERS_ORG.GET_TOTAL_MEMBERS,
        payload: memberList.length,
      });
    }
  } catch (error) {
    snackBarStatus({
      payload: {
        title: findMessage(error),
        type: 'error',
        enable: true,
      },
    })(dispatch);
  }
};

export function removeRoleOrgAdmin(orgId, member) {
  return () => Members.removeRoleOrgAdmin(orgId, member);
}

export const clearSeleted = () => ({ type: ACTIONS_MEMBERS_ORG.CLEAR_SELECTED, payload: [] });
