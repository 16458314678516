import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import EvaButton from '../../EvaButton';
import { CustomInput as InputStandard } from '../../Input';
import { CustomInputPhone as InputPhoneV1 } from '../../InputPhone';
import DatePicker from '../../DatePicker';
import { RegisterMemberStyle, button } from './styles';
import birthdayIcon from '../../../Theme/Icons/birthday.svg';
import helpIcon from '../../../Theme/Icons/help-gray.svg';
import colors from '../../../Theme/Colors';

const styles = {
  datePicker: {
    '& .datepicker-container': {
      borderColor: colors.evaPrimaryWhite,
    },
    '& input::placeholder': {
      textIndent: '.85em',
      '@media only screen and (max-width: 425px)': {
        textIndent: '2.5em',
      },
    },
    '& input': {
      textIndent: '.85em',
      '@media only screen and (max-width: 425px)': {
        textIndent: '2.5em',
      },
    },
  },
  customPhone: {
    '& input::placeholder': {
      textIndent: '.85em',
      '@media only screen and (max-width: 425px)': {
        textIndent: '2.5em',
      },
    },
    '& input': {
      textIndent: '.85em',
      '@media only screen and (max-width: 425px)': {
        textIndent: '2.5em',
      },
    },
  },
};

const RegisterMember = ({
  stepValues: { firstName, phone, email, lastName, birthday },
  inputChange,
  nextStep,
  organization,
  classes,
}) => (
  <Grid container justify="center" alignItems="center">
    <Grid item xs={10}>
      <p style={RegisterMemberStyle.paragraph}>
        Complete the information below <br />
        to invite the super admin
      </p>
    </Grid>
    <Grid item xs={10} className={classes.customPhone}>
      <InputPhoneV1
        name="phone"
        placeholder="Phone number"
        value={phone}
        onInputPhoneChange={phoneNumber =>
          inputChange({ target: { value: phoneNumber, name: 'phone' } })
        }
      />
    </Grid>
    <Grid item xs={10}>
      <InputStandard
        name="email"
        placeholder="Email Address"
        type="email"
        value={email}
        icon="email"
        color="#FAFAFA"
        onChange={inputChange}
      />
    </Grid>
    <Grid item xs={10}>
      <InputStandard
        name="firstName"
        placeholder="First Name"
        type="text"
        value={firstName}
        icon="firstName"
        onChange={inputChange}
        color="#FAFAFA"
      />
    </Grid>
    <Grid item xs={10}>
      <InputStandard
        name="lastName"
        placeholder="Last Name"
        type="text"
        value={lastName}
        icon="lastName"
        onChange={inputChange}
        color="#FAFAFA"
      />
    </Grid>
    <Grid item xs={10} className={classes.datePicker}>
      <DatePicker
        placeholder="Day of Birth"
        value={birthday}
        handleSelection={(e, inst) => {
          const value = moment(inst.getVal());
          inputChange({ target: { value, name: 'birthday' } });
        }}
        max={moment()}
        controls={['calendar']}
        headerText="{value}"
        dateFormat="dd/mm/yy"
        timeFormat="h:ii A"
        returnFormat="iso8601"
        iconLeft={birthdayIcon}
        iconRight={helpIcon}
        centerText
      />
    </Grid>
    <Grid item xs={12} style={{ ...RegisterMemberStyle.row, marginTop: '50px' }}>
      <p style={RegisterMemberStyle.memberProfile}>
        Assign to <br /> <b>{organization}</b>
      </p>
    </Grid>
    <Grid item xs={10} style={RegisterMemberStyle.row}>
      <EvaButton onClick={() => nextStep({ firstName, lastName, phone, email, birthday })}>
        <span style={{ fontFamily: button.fontFamily, textTransform: 'capitalize' }}>
          Add Org admin
        </span>
      </EvaButton>
    </Grid>
  </Grid>
);

RegisterMember.propTypes = {
  stepValues: PropTypes.instanceOf(Object).isRequired,
  inputChange: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
  organization: PropTypes.string.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default withStyles(styles)(RegisterMember);
