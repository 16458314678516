const token = localStorage.getItem('token');

const headers = {
  Authorization: `Bearer ${token}`,
  'Diocesan-Application': 'admin',
};

export default headers;

export const getAppHeaders = () => ({
  Authorization: `Bearer ${localStorage.getItem('token')}`,
  'Diocesan-Application': 'admin',
});
