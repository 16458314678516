import Parish from '../Api/Parish';
import snackBarStatus from './snackbarActions';
import findMessage from '../Helpers/utiliy';

export const ACTIONS = {
  GET: 'parish/get',
  SELECTED_PARISH: 'parish/selected_parish',
};

const getParishes = (parishID, page, orderBy, order) => async dispatch => {
  const token = localStorage.getItem('token');
  const currentPage = page || 1;
  const shortColumn = orderBy || 'name';
  const sort = order || 'asc';

  dispatch({
    type: ACTIONS.GET,
    payload: { loading: true },
  });
  try {
    const {
      data: {
        data: { pagerData, parishesData },
      },
    } = await Parish.getParishes(parishID, token, currentPage, shortColumn, sort);
    const paginator = pagerData;
    paginator.currentPage = currentPage;

    const data = {
      parishes: parishesData,
      paginator,
      loading: false,
    };

    dispatch({
      type: ACTIONS.GET,
      payload: data,
    });
  } catch (error) {
    snackBarStatus({
      payload: {
        title: findMessage(error),
        type: 'error',
        enable: true,
      },
    })(dispatch);
  }
};

export default getParishes;

export function selectedParish(data) {
  return dispatch => {
    dispatch({ type: ACTIONS.SELECTED_PARISH, payload: data });
  };
}
