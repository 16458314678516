import React from 'react';
import { connect } from 'react-redux';
import pathToRegexp from 'path-to-regexp';
import { Redirect, withRouter } from 'react-router-dom';
import { logout } from '../../Actions/loginActions';
import { navigationControllerRedirect } from '../../Actions/navigationControllerActions';
import { EVA_FRONT_URL } from '../../Config/env';
import ExternalRedirect from './ExternalRedirect';

const ROLE_USER = 'ROLE_USER';
const ROLE_MEMBER = 'ROLE_MEMBER';
const ROLE_EVA_MEMBER = 'ROLE_EVA_MEMBER';
const ROLE_EVA_GROUP_ADMIN = 'ROLE_EVA_GROUP_ADMIN';
const ROLE_ADMIN = 'ROLE_ADMIN';
const ROLE_ORGANIZATION_ADMIN = 'ROLE_ORGANIZATION_ADMIN';
const ROLE_SUPER_ADMIN = 'ROLE_SUPER_ADMIN';

const PermissionsValidator = ({
  location,
  token,
  expiration,
  roles,
  logoutSession,
}) => {
  const route = location.pathname;
  // verify token and roles existance
  if (token && expiration && new Date(expiration).getTime() > Date.now()
  && roles && roles.length) {
    // define 'defaultRedirect' depending on role hierarchy
    let redirectRequired = false;
    let shouldClearSessionData = false;
    let defaultRedirect = null;

    if (roles.includes(ROLE_USER)
    || roles.includes(ROLE_MEMBER)
    || roles.includes(ROLE_EVA_MEMBER)
    || roles.includes(ROLE_EVA_GROUP_ADMIN)
    || roles.includes(ROLE_ORGANIZATION_ADMIN)) {
      defaultRedirect = (
        <div>
          {/* ExternalRedirect triggers a location.replace() */}
          {/* /unauthorized is displayed while the redirection */}
          {/* is completed */}
          <ExternalRedirect to={EVA_FRONT_URL} />;
          <Redirect to="/unauthorized" />;
        </div>
      );
    }
    if (roles.includes(ROLE_ADMIN)) {
      defaultRedirect = <Redirect to="/main" />;
    }
    if (roles.includes(ROLE_SUPER_ADMIN)) {
      defaultRedirect = <Redirect to="/main" />;
    }

    // check per route if any of the current roles match
    // any of the permitted roles, if not redirect to the default route
    if (pathToRegexp('/').test(route)) {
      redirectRequired = !roles.includes(ROLE_SUPER_ADMIN);
      shouldClearSessionData = redirectRequired;
      //
    } else if (pathToRegexp('/main').test(route)) {
      redirectRequired = !roles.includes(ROLE_SUPER_ADMIN);
      shouldClearSessionData = redirectRequired;
      //
    } else if (pathToRegexp('/organizations').test(route)) {
      redirectRequired = !roles.includes(ROLE_SUPER_ADMIN);
      shouldClearSessionData = redirectRequired;
      //
    } else if (pathToRegexp('/super-admins').test(route)) {
      redirectRequired = !roles.includes(ROLE_SUPER_ADMIN);
      shouldClearSessionData = redirectRequired;
      //
    } else if (pathToRegexp('/organizations/:orgId').test(route)) {
      redirectRequired = !roles.includes(ROLE_SUPER_ADMIN);
      shouldClearSessionData = redirectRequired;
    }

    if (redirectRequired) {
      if (process.env.NODE_ENV === 'development') {
        console.log('<PermissionValidator />: Access Denied');
      }
      if (shouldClearSessionData) {
        localStorage.removeItem('token');
        localStorage.removeItem('expiration');
        localStorage.removeItem('roles');
        localStorage.removeItem('sso_token');
      }
      return defaultRedirect;
    }
    return null;
  }
  // if you are entering to any of these routes without token
  // or roles then logout and redirect to login
  if (
    pathToRegexp('/').test(route) ||
    pathToRegexp('/main').test(route) ||
    pathToRegexp('/organizations').test(route) ||
    pathToRegexp('/super-admins').test(route) ||
    pathToRegexp('/organizations/:orgId').test(route)
  ) {
    if (process.env.NODE_ENV === 'development') {
      console.log('<PermissionValidator />: Access Denied');
    }
    // logout session redirects to /login
    logoutSession();
    return null;
  }
  return null;
};

const mapStateToProps = ({ loginReducer }) => ({
  token: loginReducer.token,
  expiration: loginReducer.expiration,
  roles: loginReducer.roles,
});

const mapDispatchToProps = {
  logoutSession: logout,
  navigationControllerRedirect,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(PermissionsValidator),
);
