import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import DataTable from './DataTable';
import { clearOrganizationForm } from '../../Actions/organizationActions';
import { getOrganizationsList } from '../../Actions/organizationsActions';
import NewOrgForm from '../../Components/NewOrganizationForm';
import { enableRightDrawer } from '../../Actions/rightDrawerShortActions';
import { clearDetailMembers } from '../../Actions/membersActionsOrg';
import { enableTopDrawer } from '../../Actions/topDrawerActions';
import InputForSearch from '../InputForSearch';
import './index.sass';

const styles = theme => ({
  organizationRoot: {
    flexGrow: 1,
    maxWidth: 860,
    margin: '0 auto',
    marginBottom: '50px',
  },
  [theme.breakpoints.only('xs')]: {
    organizationRoot: {
      width: 320,
    },
    tableContainer: {
      width: 320,
    },
  },
  [theme.breakpoints.only('sm')]: {
    organizationRoot: {
      width: 704,
    },
    tableContainer: {
      width: 704,
    },
  },
  tableContainer: {},
  paper: {
    padding: theme.spacing.unit * 2,
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
});

class Organizations extends Component {
  componentDidMount() {
    this.props.getOrganizationsList({ page: 1, sort: 'name', sortOrder: 'asc' });
  }

  handleOrganizationSelect = (id) => {
    this.props.enableTopDrawer(true);
    this.props.history.push(`/organizations/${id}`)
  };

  handleClick = e => {
    e.preventDefault();
    this.props.enableRightDrawer({
      payload: {
        close: true,
        enable: true,
        element: <NewOrgForm/>,
        title: 'New Organization',
      },
    });
    /* eslint-disable */
    this.props.clearDetailMembers();
    this.props.clearOrganizationForm();
    /* eslint-enable */
  };

  handleOnKeyPress = e => {
    e.preventDefault();
  };

  handleSearch = term => {
    const { params } = this.props;
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.props.getOrganizationsList({ ...params, term });
    }, 500);
  };

  handleItemsPerPageChange = limit => {
    const { params } = this.props;
    this.props.getOrganizationsList({
      ...params,
      page: 1,
      limit,
    });
  };

  handleSortChange = newSort => {
    const { isLoading, params, params: { sort, sortOrder } } = this.props;

    if (isLoading) return;

    const newOrder = newSort === sort ?
      (sortOrder === 'asc' ? 'desc' : 'asc')
      : 'asc';

    this.props.getOrganizationsList({
      ...params,
      page: 1,
      sort: newSort,
      sortOrder: newOrder,
    });
  };

  handlePageChange = (page) => {
    const { params } = this.props;
    this.props.getOrganizationsList({
      ...params,
      page,
    });
  };

  render() {
    /* eslint-disable no-shadow */
    const {
      organizations, classes, isLoading, params, pagination, user, params: { sort, sortOrder }, currentMember,
    } = this.props;

    const userRoles = user.member && user.member.roles ? user.member.roles : [];
    // const WindowHeight = window.innerHeight - 100;
    return (
      <div className={classes.organizationRoot}>
        <Grid container spacing>
          <Grid item xs={12} className={classes.tableContainer}>
            <div className="filters-container-org">
              <InputForSearch
                onChange={this.handleSearch}
                type="text"
                placeholder="Search"
                name="search"
              />
              <div className="filter-container">{/* <i className="filter" /> */}</div>
              {userRoles.includes('ROLE_SUPER_ADMIN') && (
                <div
                  className="display-drawer-container create-new-organization-button"
                  onClick={this.handleClick}
                  onKeyPress={this.handleOnKeyPress}
                  role="button"
                  tabIndex="0"
                >
                  <i className="display-drawer"/>
                </div>
              )}
            </div>
          </Grid>
          <Grid item xs={12} className={classes.tableContainer}>
            <DataTable
              sort={sort}
              sortOrder={sortOrder}
              organizations={organizations}
              loading={isLoading}
              pagination={pagination}
              currentPage={params.page}
              limit={params.limit}
              memberId={currentMember.public_id}
              onSortChange={this.handleSortChange}
              onPageChange={this.handlePageChange}
              onSelect={this.handleOrganizationSelect}
              onItemsPerPageChange={this.handleItemsPerPageChange}
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mD = {
  enableRightDrawer,
  clearDetailMembers,
  clearOrganizationForm,
  enableTopDrawer,
  getOrganizationsList,
};

const mS = state => ({
  organizations: state.organizationsReducer.organizations,
  params: state.organizationsReducer.params,
  pagination: state.organizationsReducer.pagination,
  isLoading: state.organizationsReducer.isLoading,
  page: state.organizationsReducer.page,
  user: state.userProfileReducer,
  currentMember: state.userProfileReducer.member,
});

Organizations.propTypes = {
  getOrganizationsList: PropTypes.func.isRequired,
  organizations: PropTypes.isRequired,
  enableRightDrawer: PropTypes.isRequired,
  clearDetailMembers: PropTypes.func.isRequired,
  clearOrganizationForm: PropTypes.func.isRequired,
  enableTopDrawer: PropTypes.func.isRequired,
  user: PropTypes.func.isRequired,
};

export default connect(
  mS,
  mD,
)(withRouter(withStyles(styles)(Organizations)));
