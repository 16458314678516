import React, { Component } from 'react';
import Drawer from '@material-ui/core/Drawer';
import { Scrollbars } from 'react-custom-scrollbars';
import IconButton from '@material-ui/core/IconButton';
import Hidden from '@material-ui/core/Hidden';
import ClearIcon from '@material-ui/icons/Clear';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { enableRightDrawer } from '../../Actions/rightDrawerShortActions';
import RightDrawerPropType from '../../PropTypes/RightDrawerPropType';
import './index.sass';

const drawerWidth = window.innerWidth > 768 ? 480 : window.innerWidth;

const styles = theme => ({
  drawerPaper: {
    position: 'absolute',
    width: drawerWidth,
    minHeight: 100,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-left',
    padding: '0 8px',
    ...theme.mixins.toolbar,
    minHeight: 40,
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing.unit * 3,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  'content-left': {
    marginLeft: -drawerWidth,
  },
  'content-right': {
    marginRight: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  'contentShift-left': {
    marginLeft: 0,
  },
  'contentShift-right': {
    marginRight: 0,
  },
});

class RightDrawerShort extends Component {
  state = {
    side: 'right',
  };

  handleDrawerClose = () => {
    if (this.props.cleaner) {
      this.props.cleaner();
    }
    this.props.enableRightDrawer({ payload: { close: false, enable: false } });
    document.querySelector('.right-drawer-edit-member > .drawer-md-up').style.display = 'none';
  };

  handleChangeAnchor = event => {
    this.setState({
      side: event.target.value,
    });
  };

  render() {
    const { classes, element, status, title, disableEnforceFocus } = this.props;
    const { side } = this.state;
    const WindowHeight = window.innerHeight;
    return (
      <div className="right-drawer-edit-member">
        <Hidden mdUp>
          <Drawer
            variant="temporary"
            anchor={side}
            open={status}
            classes={{
              paper: classes.drawerPaper,
            }}
            disableEnforceFocus={disableEnforceFocus}
          >
            <Scrollbars
              autoHeight
              autoHide
              autoHeightMin={WindowHeight}
              autoHeightMax={WindowHeight}
            >
              <div className={classes.drawerHeader}>
                <IconButton onClick={this.handleDrawerClose}>
                  <ClearIcon />
                </IconButton>
                <div className="drawer-title">{title}</div>
              </div>
              <div>{element}</div>
            </Scrollbars>
          </Drawer>
        </Hidden>
        <Hidden smDown>
          <div className="drawer-md-up">
            <Drawer
              anchor={side}
              open={status}
              classes={{
                paper: classes.drawerPaper,
              }}
              disableEnforceFocus={disableEnforceFocus}
            >
              <Scrollbars
                autoHeight
                autoHide
                autoHeightMin={WindowHeight}
                autoHeightMax={WindowHeight}
              >
                <div className={classes.drawerHeader}>
                  <IconButton onClick={this.handleDrawerClose}>
                    <ClearIcon />
                  </IconButton>
                  <div className="drawer-title">{title}</div>
                </div>
                <div>{element}</div>
              </Scrollbars>
            </Drawer>
          </div>
        </Hidden>
      </div>
    );
  }
}

const mS = state => ({
  status: state.rightDrawerShortReducer.enable,
  element: state.rightDrawerShortReducer.element,
  title: state.rightDrawerShortReducer.title,
  cleaner: state.rightDrawerShortReducer.cleaner,
  disableEnforceFocus: state.rightDrawerShortReducer.disableEnforceFocus,
});

const mD = {
  enableRightDrawer,
};

RightDrawerShort.propTypes = RightDrawerPropType;

export default connect(
  mS,
  mD,
)(withStyles(styles, { withTheme: true })(RightDrawerShort));
