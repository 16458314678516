import { ACTIONS } from '../Actions/loginActions';

const initialState = {
  username: '',
  password: '',
  error: '',
  message: '',
  token: null,
  ssoToken: null,
  roles: [],
  expiration: '',
};

const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.UPDATE:
      return { ...state, ...action.payload };

    case ACTIONS.CLEAR:
      return initialState;

    case ACTIONS.LOG_IN: {
      const { expiration, token } = action.payload.data;
      localStorage.setItem('token', token);
      localStorage.setItem('expiration', expiration);
      return { ...state, ...action.payload.data };
    }

    case ACTIONS.SSO_LOG_IN: {
      return { ...state, ...action.payload };
    }

    case ACTIONS.LOG_OUT:
      return { ...initialState };

    case ACTIONS.ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export default loginReducer;
