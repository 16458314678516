import React from 'react';
import InputPropType from '../../PropTypes/InputPropType';
import clearSearch from '../../Theme/Icons/cancel.svg';
import './index.sass';

const InputForSearch = ({
  name,
  type,
  placeholder,
  onChange,
  maxLength,
  onBlur,
  onClear,
  clear = true,
}) => {
  const handleKeyPress = event => {
    if (event.which === 13 /* Enter */) {
      event.preventDefault();
    }
  };
  const InputChange = val => {
    onChange(val, name);
  };

  return (
    <div className="input-group">
      <form onReset={() => InputChange('')}>
        {
          clear &&
          <button
            onClick={onClear}
            className="button-icon-search"
            type="reset"
          >
            <img alt="Clear search" className="close-search-img" src={clearSearch} />
          </button>
        }
        <input
          name={name}
          type={type}
          placeholder={placeholder}
          onChange={event => InputChange(event.target.value)}
          onBlur={onBlur}
          className="input-search-box input-eva search-box"
          maxLength={maxLength}
          autoComplete="off"
          onKeyPress={handleKeyPress}
        />
      </form>
    </div>
  );
};

InputForSearch.propTypes = InputPropType;

export default InputForSearch;
