import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import purple from '@material-ui/core/colors/purple';
import { debounce } from 'lodash';
import { ENV } from '../../Config/env';
import Input from '../Input';
import SearchOrganization from './SearchOrganization';
import ButtonStandard from '../Button';
import AddedMembers from '../../Components/AddAdminTabs/AddedMembers';
import OrgInviteAdmin from '../../Containers/OrgInviteAdmin';
import SimpleSelect from '../SimpleSelect';
import addPictureOrgIcon from '../../Theme/Icons/add-picture-org.svg';
import {
  enableRightDrawer as enableRightDrawer1,
  setTitle,
} from '../../Actions/rightDrawerShortActions';
import { enableRightDrawer } from '../../Actions/rightDrawerActions';
import {
  getOrganizationTypes,
  updateData,
  updateType,
  createOrganization,
  dehydrateInputsDiscover,
  clearOrganizationForm,
  updateOrganizationAction,
  verifyKeyword,
  refreshOrgKeyword,
} from '../../Actions/organizationActions';
import { getOrganizationsList } from '../../Actions/organizationsActions';
import {
  membersSelected,
  updateMembersList,
  clearSafeMembers,
  clearSelectedMembers,
  clearDetailMembers,
} from '../../Actions/membersActionsOrg';
import snackBarStatus from '../../Actions/snackbarActions';
import './index.sass';
import searchIcon from '../../Theme/Icons/search.png';
import errorIcon from '../../Theme/Icons/error.svg';
import checkmarkIcon from '../../Theme/Icons/check-green.svg';
import Helpers from '../../Helpers';
import EditPictureOrg from '../EditPictureOrg';

const styles = theme => ({
  keyword: {
    display: 'flex',
    height: '39px',
    width: '100%',
    borderBottom: '2px solid #d9d9d9',
    alignItems: 'center',
    background: 'transparent !important',
    '&.error': {
      borderColor: '#ff7284',
    },
    '&>input': {
      flexGrow: '1',
      height: '100%',
      border: 'none',
      background: 'transparent',
      fontFamily: 'GothamSSm-Medium',
      fontSize: '16px',
      color: '#4a4a4a',
      outline: 'none',
      textAlign: 'none',
    },
    '&>.keyword-icon': {
      height: '20px',
      width: '20px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      '&>img': {
        heigth: '100%',
      },
    },
  },
  searchOrganization: {
    '& input.oneIcon': {
      width: 'calc(100% - 30px)',
    },
    '& .right-icon': {
      width: '30px',
      textAlign: 'right',
      '&>img': {
        height: '42%',
      },
    },
  },
  '@media (max-width: 767px)': {
    searchOrganization: {
      marginTop: '-24px',
    },
  },
  progress: {
    margin: theme.spacing.unit * 2,
    textAlign: 'center',
  },
  loaderMessage: {
    fontFamily: 'GothamSSm-Medium',
    fontSize: '16px',
    color: '#4a4a4a',
    outline: 'none',
    textAlign: 'center',
  },
});
class NewOrgForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hiddenPlaceholder: '',
      showAssociatedField: false,
      placeholderValue: '',
      keywordStatus: 'none',
      uploadPic: false,
      submitting: false,
    };
    this.customUrlInputRef = React.createRef();
  }

  componentWillMount() {
    if (this.props.organizationType === '') {
      this.setState({ hiddenPlaceholder: 'hidden-placeholder' });
    }
  }

  componentDidMount() {
    this.props.refreshOrgKeyword('');
    this.props.getOrganizationTypes();
  }

  parseToValue = organizationTypes =>
    organizationTypes.map(organizationType => ({
      value: organizationType.public_id,
      label: organizationType.name,
    }));
  handleSelectChange = e => {
    this.props.dehydrateInputsDiscover();
    const selectedType = this.props.organizationTypes.find(x => x.public_id === e.target.value);

    const { placeholderValue } = this.state;
    if (selectedType) {
      this.setState({ hiddenPlaceholder: 'hidden-placeholder' });
    } else {
      this.setState({ hiddenPlaceholder: '' });
      this.props.updateType(placeholderValue);
      return;
    }
    this.props.updateType(selectedType);
    if (selectedType.name === 'Parish') {
      this.setState({ showAssociatedField: true });
    } else {
      this.setState({ showAssociatedField: false });
    }
  };

  validateKeyword = keyword => {
    // A-Z, a-z, 0-9 and hypens (-)
    // max length 10 min length 3
    const validPattern = /^([a-z]|[0-9]|-){3,10}$/gi;
    const invalidHyphenPattern = /-{2,}/g;
    const invalidHyphenEndingPattern = /.*-$/g;
    const returnValue = { pass: false, reason: '' };
    if (!keyword) {
      returnValue.reason = 'Organization keyword is required';
    } else if (keyword.length < 3) {
      returnValue.reason = 'Organization keyword must be at least 3 caracters long';
    } else if (keyword.length > 10) {
      returnValue.reason = 'Organization keyword must not be more than 10 characters long';
    } else if (!validPattern.test(keyword)) {
      returnValue.reason = 'Organization keyword can only use numbers, letters and hypens';
    } else if (invalidHyphenPattern.test(keyword)) {
      returnValue.reason = 'Organization keyword must not have two or more hyphens together';
    } else if (invalidHyphenEndingPattern.test(keyword)) {
      returnValue.reason = 'Organization keyword must not end with a hyphen';
    } else {
      returnValue.pass = true;
      returnValue.reason = 'All checks passed';
    }
    return returnValue;
  };

  handleKeywordChange = e => {
    const keyword = (e.target.value) ? e.target.value.toString().toLowerCase() : e.target.value;
    this.props.refreshOrgKeyword(keyword);
    this.setState({ keywordStatus: 'loading' });
    this.handleChange(e, 'keyword');
    if (keyword && keyword.length >= 3) {
      this.verifyKeywordAvailability(keyword);
    }
  };

  verifyKeywordAvailability = debounce(keyword => {
    const validation = this.validateKeyword(keyword);
    if (!validation.pass) {
      this.setState({ keywordStatus: 'error' });
      this.props.snackBarStatus({
        payload: {
          enable: true,
          title: validation.reason,
          type: 'error',
        },
      });
    } else {
      this.props
        .verifyKeyword(keyword)
        // this is because how the endpoint work
        .then(res => {
          if (res.response && res.response.status === 404) {
            this.setState({ keywordStatus: 'success' });
          } else {
            this.setState({ keywordStatus: 'error' });
          }
        });
    }
  }, 1500);

  selectKeywordIcon = () => {
    switch (this.state.keywordStatus) {
      case 'success':
        return checkmarkIcon;
      case 'error':
        return errorIcon;
      default:
        return searchIcon;
    }
  };

  copyUrl = () => {
    Helpers.copyToClipboard(this.customUrlInputRef.current);
  };

  handleChange = (e, attr) => {
    const payload = {};
    payload[`${attr}`] = e.target.value;
    if (e.target.name === 'keyword') {
      payload[`${attr}`] = (e.target.value) ? e.target.value.toString().toLowerCase() : e.target.value;
    }
    this.props.updateData(payload);
  };

  handleOnKeyPress = e => {
    e.preventDefault();
  };

  addAdminOrg = e => {
    e.preventDefault();
    this.props.enableRightDrawer({
      payload: {
        enable: true,
        close: true,
        element: <OrgInviteAdmin createOrg />,
        title: 'Invite an Org Admin',
      },
    });
  };

  showAssociatedField = value => {
    this.setState({ showAssociatedField: value });
  };
  addPictureOrg = e => {
    e.preventDefault();
    this.props.setTitle('Select a profile picture');
    this.setState({ uploadPic: true });
  };
  submit = async e => {
    e.preventDefault();
    const {
      name,
      shortName,
      description,
      street,
      street2,
      postalCode,
      state,
      city,
      country,
      pictureData,
      organizationType,
      discoverOrganizationSuggestionValue,
      parentOrganizationSsoId,
      parentOrganizationEvaId,
      admins,
      keyword,
      discoverOrganizationSuggestionValueId,
      mpaOrganizationSuggestionId,
    } = this.props;
    /* eslint-disable */
    let organization_type = organizationType ? { name: organizationType } : '';
    const keywordValidation = this.validateKeyword(keyword);
    if (!organization_type) {
      this.props.snackBarStatus({
        payload: {
          enable: true,
          title: 'Organization Type is Required',
          type: 'error',
        },
      });
    } else if (
      !discoverOrganizationSuggestionValueId ||
      discoverOrganizationSuggestionValueId === ''
    ) {
      this.props.snackBarStatus({
        payload: {
          enable: true,
          title: 'Organization is Required',
          type: 'error',
        },
      });
    } else if (!shortName) {
      this.props.snackBarStatus({
        payload: {
          enable: true,
          title: 'You must provide an organization short name',
          type: 'error',
        },
      });
    } else if (!name) {
      this.props.snackBarStatus({
        payload: {
          enable: true,
          title: 'You must provide an organization name',
          type: 'error',
        },
      });
    } else if (!keywordValidation.pass) {
      this.props.snackBarStatus({
        payload: {
          enable: true,
          title: keywordValidation.reason,
          type: 'error',
        },
      });
    } else if (!state || state.length < 2) {
      this.props.snackBarStatus({
        payload: {
          enable: true,
          title: 'You must provide the State of this organization',
          type: 'error',
        },
      });
    } else if (!city || city.length < 2) {
      this.props.snackBarStatus({
        payload: {
          enable: true,
          title: 'You must provide the City of this organization',
          type: 'error',
        },
      });
    } else if (!postalCode || postalCode.length < 2) {
      this.props.snackBarStatus({
        payload: {
          enable: true,
          title: 'You must provide the Postal Code of this organization',
          type: 'error',
        },
      });
    } else if (!country || country.length < 2) {
      this.props.snackBarStatus({
        payload: {
          enable: true,
          title: 'You must provide the Country of this organization',
          type: 'error',
        },
      });
    } else if (admins.length <= 0) {
      this.props.snackBarStatus({
        payload: {
          enable: true,
          title: 'You must assign at least one administrator',
          type: 'error',
        },
      });
    } else {
      /* eslint-enable */
      const organizationAddresses = [];
      const organizationAddress = {
        description,
        reference_address: street,
        state_text: state,
        city_text: city,
        postal_code: postalCode,
        country_text: country,
        second_reference_address: street2,
      };
      organizationAddresses.push(organizationAddress);
      this.setState({
        submitting: true,
      });
      try {
        const response = await this.props.createOrganization({
          name,
          short_name: shortName,
          img: pictureData.img || null,
          organization_type,
          ssoMasterName: discoverOrganizationSuggestionValue,
          description,
          parent_organization: {
            ssoId: parentOrganizationSsoId || null,
            evaId: parentOrganizationEvaId || null,
          },
          organization_addresses: organizationAddresses,
          status: 'active',
          keyword,
          admins,
          sso_organization_id: discoverOrganizationSuggestionValueId || null,
          mpaOrganizationId: mpaOrganizationSuggestionId,
        });
        if (response) {
          if (response.code === 200) {
            this.props.getOrganizationsList();
            this.props.clearSelectedMembers();
            this.props.clearDetailMembers();
            this.props.clearOrganizationForm();
            this.props.snackBarStatus({
              payload: {
                enable: true,
                title: 'Organization created successfully',
                type: 'success',
              },
            });
            this.props.enableRightDrawer1({
              payload: { close: false, enable: false },
            });
            this.props.enableRightDrawer({
              payload: {
                close: true,
                enable: false,
                deviceCustom: false,
                element: <div />,
              },
            });
          } else {
            const {
              code,
              message,
            } = response;
            if (code === 404 || code === 400 || code === 403 || code === 401) {
              this.props.snackBarStatus({
                payload: {
                  enable: true,
                  title: message,
                  type: 'error',
                },
              });
            } else {
              this.props.snackBarStatus({
                payload: {
                  enable: true,
                  title: 'Server responded with an error.',
                  type: 'error',
                },
              });
              // console.log(message);
              // console.log(details);
            }
          }
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.setState({
          submitting: false,
        });
      }
    }
  };

  handleRemoveCandidate = candidateIndex => {
    const { admins } = this.props;
    const candidateList = admins.slice(0, candidateIndex).concat(admins.slice(candidateIndex + 1));
    this.props.updateOrganizationAction({ admins: candidateList });
  };

  closeImagePicker() {
    this.props.setTitle('New Organization');
    this.setState({ uploadPic: false });
  }

  renderProgress = () => {
    const { classes } = this.props;
    return (
      <Grid container alignItems="center" justify="center">
        <Grid item xs={8}>
          <p className={classes.loaderMessage}>
            Creating new organization, this process may take a few minutes.
          </p>
        </Grid>
        <Grid item xs={5} className="void-box" />
        <Grid item xs={2}>
          <CircularProgress
            className={classes.progress}
            style={{ color: purple[500] }}
            thickness={7}
          />
        </Grid>
        <Grid item xs={5} className="void-box" />
      </Grid>
    );
  };

  render() {
    const {
      hiddenPlaceholder,
      showAssociatedField,
      keywordStatus,
      submitting,
      uploadPic,
    } = this.state;
    const { keyword, classes, organizationType } = this.props;
    const customURL = this.props.keyword ? `${ENV.SHORTENER_URL}${this.props.keyword}` : '';
    const { organizationTypes, organizationTypeSelected, pictureData, admins } = this.props;

    if (submitting) {
      return this.renderProgress();
    }

    if (uploadPic) {
      return <EditPictureOrg onClose={() => this.closeImagePicker()} />;
    }

    return (
      <div className="new-organization-form">
        <Grid container spacing={0} className="form-create-org">
          <Grid item xs={12}>
            <div className="profile-image">
              <button className="button-photo-edit" onClick={this.addPictureOrg}>
                {pictureData ? (
                  <div className="photo-edit-profile-member">
                    <img src={pictureData.img} alt="profile" />
                  </div>
                ) : (
                  <div className="photo-edit-profile-member">
                    <img src={addPictureOrgIcon} alt="..." />
                  </div>
                )}
                <div className="input-edit-member-name">
                  <p className="text-member-name">
                    Click or tap <br />
                    to add a photo
                  </p>
                </div>
              </button>
            </div>
          </Grid>
          <Grid item xs={12} className="search-org-input">
            <InputLabel
              className={`placeholder-select ${
                organizationType === '' && hiddenPlaceholder !== '' ? '' : hiddenPlaceholder
              }`}
            >
              Type
            </InputLabel>
            <SimpleSelect
              value={organizationTypeSelected ? organizationTypeSelected.public_id : ''}
              options={this.parseToValue(organizationTypes)}
              handleChange={this.handleSelectChange}
              name="Type"
              inputProps={{
                name: 'organizationType',
                id: 'type-org',
              }}
              className="select select-desk-org-creation"
            />
          </Grid>
          <Grid item xs={12} className={classes.searchOrganization}>
            <SearchOrganization />
          </Grid>
          {showAssociatedField && (
            <Grid item xs={12} sm={6} md={12} className="inputs-create-org">
              <Input
                readOnly
                name="parentPrg"
                type="text"
                placeholder="Parent Organization"
                value={this.props.parentOrganizationName}
                className="input-eva-border-bottom"
              />
            </Grid>
            // <Grid item xs={12} className={classes.searchOrganization}>
            //   <SearchParentOrganization />
            // </Grid>
          )}
          <Grid item xs={12} className="inputs-create-org">
            <Input
              type="text"
              name="shortName"
              placeholder="Short name"
              value={this.props.shortName}
              onChange={this.handleChange}
              maxLength={20}
              className="normal-input-create-org"
            />
          </Grid>
          <Grid item xs={12} className="bottom-inputs inputs-create-org">
            <Input
              type="text"
              name="name"
              placeholder="Name"
              value={this.props.name}
              onChange={this.handleChange}
              className="normal-input-create-org"
            />
          </Grid>
          <Grid item xs={12} className="inputs-create-org input-search-icon">
            <div className={`${classes.keyword} ${this.state.keywordStatus}`}>
              <input
                type="text"
                name="keyword"
                maxLength={10}
                placeholder="Keyword"
                value={keyword}
                onChange={this.handleKeywordChange}
              />
              <div className="keyword-icon">
                <img src={this.selectKeywordIcon()} alt={this.state.keywordStatus} />
              </div>
            </div>
          </Grid>
          <Grid item xs={12} className="inputs-create-org">
            <Grid container spacing={0} alignItems="center">
              <Grid xs={8} className="input-custom-url">
                {keywordStatus === 'loading' ? (
                  <div style={{ paddingTop: '15px', textAlign: 'center' }}>
                    <CircularProgress style={{ color: purple[500], width: 30, height: 30 }} />
                  </div>
                ) : (
                  <Input
                    ref={this.customUrlInputRef}
                    name="short_url"
                    type="text"
                    value={customURL}
                    readOnly
                  />
                )}
              </Grid>
              <Grid xs={4} className="title-url-container">
                <Button className="title-url" onClick={this.copyUrl}>
                  Copy URL
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={12} className="inputs-create-org">
            <Input
              readOnly
              name="streetAddress"
              type="text"
              placeholder="Address 1"
              value={this.props.street}
              onChange={this.handleChange}
              className="input-eva-border-bottom"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={12} className="inputs-create-org">
            <Input
              readOnly
              name="secondStreetAddress"
              type="text"
              placeholder="Address 2"
              value={this.props.street2}
              onChange={this.handleChange}
              className="input-eva-border-bottom"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={12} className="inputs-create-org">
            <Input
              readOnly
              name="city"
              type="text"
              placeholder="City"
              value={this.props.city}
              onChange={this.handleChange}
              className="input-eva-border-bottom"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={12} className="inputs-create-org">
            <Input
              readOnly
              name="state"
              type="text"
              placeholder="State"
              value={this.props.state}
              onChange={this.handleChange}
              className="input-eva-border-bottom"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={12} className="inputs-create-org">
            <Input
              readOnly
              name="postalCode"
              type="text"
              placeholder="Postal Code"
              value={this.props.postalCode}
              onChange={this.handleChange}
              className="input-eva-border-bottom"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={12} className="inputs-create-org">
            <Input
              readOnly
              name="country"
              type="text"
              placeholder="Country"
              value={this.props.country}
              onChange={this.handleChange}
              className="input-eva-border-bottom"
            />
          </Grid>
          <Grid item xs={12} className="add-admin-block">
            <h3 className="title-eva">Org Admin(s)</h3>
            <Grid container spacing={0}>
              <Grid item xs={12} sm={12} md={12}>
                <button
                  className="add-org-admin-button"
                  onClick={this.addAdminOrg}
                >
                  Add org admin
                </button>
              </Grid>
            </Grid>
            <Grid container spacing={0} className="admin-list">
              {/* <OrgAdminList /> */}
              <AddedMembers members={admins} removeCandidate={this.handleRemoveCandidate} />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <ButtonStandard title="Create" onClick={this.submit} />
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mD = {
  clearDetailMembers,
  clearOrganizationForm,
  clearSafeMembers,
  clearSelectedMembers,
  createOrganization,
  enableRightDrawer,
  getOrganizationTypes,
  getOrganizationsList,
  membersSelected,
  dehydrateInputsDiscover,
  snackBarStatus,
  updateData,
  verifyKeyword,
  updateMembersList,
  updateType,
  updateOrganizationAction,
  enableRightDrawer1,
  setTitle,
  refreshOrgKeyword,
};

const mS = state => ({
  organizationTypes: state.organizationReducer.organizationTypes,
  organizationType: state.organizationReducer.organizationType
    ? state.organizationReducer.organizationType.name
    : '',
  organizationTypeSelected: state.organizationReducer.organizationType,
  shortName: state.organizationReducer.shortName,
  name: state.organizationReducer.name,
  keyword: state.organizationReducer.keyword,
  street: state.organizationReducer.streetAddress,
  street2: state.organizationReducer.secondStreetAddress,
  postalCode: state.organizationReducer.postalCode,
  state: state.organizationReducer.state,
  city: state.organizationReducer.city,
  country: state.organizationReducer.country,
  pictureData: state.organizationReducer.pictureData,
  // organizationsSuggestions: state.organizationReducer.organizationsSuggestions,
  // organizationSuggestionValue: state.organizationReducer.organizationSuggestionValue,
  discoverOrganizationSuggestionValue: state.organizationReducer.discoverOrganizationSuggestionValue
    ? state.organizationReducer.discoverOrganizationSuggestionValue
    : '',
  parentOrganizationName: state.organizationReducer.parentOrganizationName,
  parentOrganizationSsoId: state.organizationReducer.parentOrganizationSsoId,
  parentOrganizationEvaId: state.organizationReducer.parentOrganizationEvaId,
  members: state.orgInviteAdminReducer.members,
  admins: state.organizationReducer.admins,
  discoverOrganizationSuggestionValueId:
    state.organizationReducer.discoverOrganizationSuggestionValueId,
  mpaOrganizationSuggestionId: state.organizationReducer.mpaOrganizationSuggestionId,
});

export default withStyles(styles)(
  connect(
    mS,
    mD,
  )(NewOrgForm),
);
