import { ACTIONS } from '../Actions/settingsActions';

const InitialState = {
  joinRequestsNotifications: [],
  memberJoinedGroupNotifications: [],
  replyMessageNotifications: [],
  joinRequestAmount: '',
  memberJoinedGroupAmount: '',
  replyMessageAmount: '',
  dismissNotifications: {},
};

const settingsReducer = (state = InitialState, action) => {
  switch (action.type) {
    case ACTIONS.GET_NOTIFICATIONS:
      return { ...action.payload };
    case ACTIONS.DISMISS_NOTIFICATIONS:
      return { ...state, dismissNotifications: action.payload };
    case ACTIONS.ACCEPT_JOIN_REQUEST:
      return { ...state };
    default:
      return { ...state };
  }
};

export default settingsReducer;
