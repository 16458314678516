import Organization from '../Api/Organization';
import snackBarStatus from './snackbarActions';
import findMessage from '../Helpers/utiliy';

export const ACTIONS = {
  LOAD: 'organizations/load',
  LIMIT_UPDATE: 'organizations/limit_update',
  PAGE_UPDATE: 'organizations/page_update',
  LOADING: 'organizations/loading',
};

const changeLoading = state => dispatch => {
  dispatch({ type: ACTIONS.LOADING, payload: state });
};

export const getOrganizationsList = (params = {}) => async dispatch => {
  const defaultParams = {
    page: 1,
    sort: 'name',
    sortOrder: 'asc',
    term: '',
    limit: 50,
  };

  const reqParams = {
    ...defaultParams,
    ...params,
  };

  // 'Fake' update to reflect user's click in paginator instantly
  if (params.limit) {
    dispatch({
      type: ACTIONS.LIMIT_UPDATE,
      payload: params.limit,
    });
  }

  // 'Fake' update to reflect user's click in paginator instantly
  if (params.page) {
    dispatch({
      type: ACTIONS.PAGE_UPDATE,
      payload: params.page,
    });
  }

  try {
    changeLoading(true)(dispatch);

    const {
      data: { data: obj },
    } = await Organization.getOrganizationsShort(reqParams);

    const { organizations, nextPage, previousPage, totalPages, currentPage, total } = obj;

    dispatch({
      type: ACTIONS.LOAD,
      payload: {
        params: reqParams,
        organizations,
        pagination: {
          nextPage,
          previousPage,
          totalPages,
          currentPage,
          total,
        },
      },
    });

    changeLoading(false)(dispatch);
  } catch (error) {
    snackBarStatus({
      payload: {
        title: findMessage(error),
        type: 'error',
        enable: true,
      },
    })(dispatch);
  }
};
